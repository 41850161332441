/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Categories
 *
 * Most styles should be in relevant stylesheet i.e.
 * buttons.scss. This stylesheet is slightly more
 * speciic than the default theme to override CSS.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.blog-home-index,
.blog-search-result {
	.page-title-wrapper {
		padding: $small-gutter;

		@include min-screen($screen__m) {
			border-bottom: 0;
			display: grid;
			grid-template-columns: auto rem(280);
			justify-content: space-between;
			gap: $gutter;
			padding: $gutter;
		}

		@include min-screen($screen__cms-width) {
			padding-left: 0;
			padding-right: 0;
		}

		.blog-search {
			margin: 0;
			align-self: flex-end;
			position: relative;
			height: 100%;
	
			@include min-screen($screen__m) {
				padding: 0;
			}
	
			.input-text {
				@include input-style-two($color: $ebony-clay,);
				padding-left: $forty-gutter;
			}
	
			.search-button {
				position: absolute;
				left: 0;
				right: auto;
				padding: 0 $ten-gutter;
	
				&:before {
					@include svg-background(
						$name: search,
						$size: contain
					);
					width: rem(18);
				}
			}
		}
	}

	.page-title .base {
		@extend %trailing-full-stop;
		@include type__18;
		display: inline;

		@include min-screen($screen__m) {
			@include type__40(
				$line-height: rem(56),
				$font-weight: $font-weight-semibold
			);
		}
	}

	.columns {
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			padding: 0 $gutter;
		}
	}

	.main {
		max-width: $cms-container-width; /* Account for button padding */
		margin: 0 auto;
		padding: 0;

		.blog-post-list {
			display: grid;
			gap: $small-gutter;
			margin: 0;
			padding: 0;
	
			@include min-screen($screen__m) {
				gap: $gutter;
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
	
			@include min-screen($screen__ll) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
	
			~ .toolbar {
				border: 0;
				margin: 0;
				justify-content: center;
			}
	
			.blog-post-item {
				padding: 0;
				border: 0;
		
				&:before {
					display: none; /* not wanted on this theme */
				}
		
				// have a horizontal grid line
				&:after {
					border-bottom: 1px solid $gallery;
					padding-bottom: $small-gutter;
					content: '';
		
					@include min-screen($screen__m) {
						// allow line span accross gap
						margin-left: - $gutter;
						margin-right: - $gutter;
						padding-bottom: $gutter;
					}
				}
		
				.post-image {
					aspect-ratio: 16 / 9;
					object-fit: cover;
					object-position: center;
					min-height: 100%;
					min-width: 100%;
				}
		
				.post-title {
					a {
						line-height: rem(28);
					}
				}
		
				.post-read-more {
					@include button__journey;
					display: block;
				}
			}
		}
	}
}
