/* -------------------------------------------------
 * -------------------------------------------------
 * PDF Download variables
 *
 * -------------------------------------------------
 * @author Fisheye
 */

$download-container-colour: $light-tuna;
$download-container-border: 0;
$download-container-border-radius: $border-radius-s;
$download-container-spacing: $small-gutter $medium-gutter;
$download-container-link-color: $medium-tuna;
$download-container-icon-colour: $medium-tuna;
$download-container-text-color: $ebony-clay;
$download-container-margin: 0 0 $gutter 0;

$download-attachment-table-border: 1px solid $mercury;
$download-attachment-table-border-radius: $border-radius-m;
$download-attachment-table-color: $silver;
$download-attachment-icon-color: $keppel;
$download-attachment-cell-spacing: rem(8) 0 rem(8) $small-gutter;
$download-attachment-container-spacing: 0;
$download-attachment-type-size: rem(32);
$download-attachment-icon-size: rem(18);
