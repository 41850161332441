/* -----------------------------------------------
 * -----------------------------------------------
 * Mobile menu
 * This is the styling for the mobile menu content
 * -----------------------------------------------
 * @author Fisheye
 */

.page-header {
	// Mobile nav button open
	.navigation-toggle {
		@include min-screen($screen__s) {
			grid-column: span 2;
		}

		&.nav-open {
			// Centrally align logo when nav open
			& ~ .logo {
				grid-column: span 3;
				margin-right: rem(58);

				@include min-screen($screen__s) {
					grid-column: span 1;
					margin-right: 0;
				}
			}

			& ~ .block-search {
				border-top: 1px solid $mercury;
			}

			& ~ .icons-block {
				display: none; // Hide icons when nav open
			}
		}
	}

	.nav-sections {
		.nav-sections-item-content {
			// Nesting required to override default TPM theme
			.navigation {
				background-color: $mega-menu-background-color;

				.mobile-menu {
					padding: 0;

					.fisheye-mobile-menu-header {
						min-height: rem(40);

						.fisheye-back-button {
							margin-left: $medium-gutter;
						}

						.fisheye-back-button-link {
							display: flex;
							align-items: center;
						}
					}

					.mobile-menu-item {
						margin-bottom: 0;

						.mobile-menu-link-wrapper,
						.mobile-menu-wrapper {
							border-top: 1px solid $mercury;
							margin-bottom: 0;
							overflow: auto;

							.mobile-menu-image {
								display: none; // Remove images from first level category
							}

							.mobile-submenu-image {
								width: 56px;
								height: 56px;
								margin: $five-gutter 0 $five-gutter $medium-gutter;
							}

							.mobile-menu-item {
								@include type__16(
									$color: $ebony-clay,
									$line-height: rem(28),
								);
								margin: $ten-gutter 0 $ten-gutter $medium-gutter;
							}
						}

						// Link arrows
						.mobile-menu-link-wrapper:not(.display-children):after,
						.mobile-menu-wrapper:not(.display-children):after,
						&.a-z-container .child-item:after {
							content: '';
							@include svg-background('arrow-right-black');
							display: inline-block;
							background-size: contain;
							height: 10px;
							width: 6px;
							margin-right: $medium-gutter;
						}

						&.a-z-container {
							> .mobile-menu-wrapper {
								> .mobile-menu-item {
									@include type__20(
										$color: $keppel,
									);
									margin: rem(12) auto;
									text-align: center;
								}
							}

							.child-item {
								position: relative;

								&:after {
									position: absolute;
									right: 0;
									top: 9px;
								}
							}
						}
					}
				}
			}
		}
	}

	.mobile-nav-footer-logout {
		display: none; // hide untill mobile nav bar is open and to stop unintended showing on destop
	}

	&.mobile-nav-open {
		.mobile-nav-footer {
			display: block;
			padding: rem(24) 0;

			.icons-block {
				align-items: center;
				justify-content: center;

				.header.items {
					.customer-help-center,
					.customer-welcome {
						display: flex;
					}
				}
			}

			.icons-block,
			.header.items {
				display: flex;
				gap: rem(74);

				.header.items {
					> li {
						margin: 0;
					}
				}

				/* Account specific */
				.customer-welcome {
					order: 1;
				}

				/* Help center specific */
				.customer-help-center {
					order: 2;
				}

				.customer-help-center,
				.customer-welcome {
					display: flex;
				}

				svg {
					min-width: rem(30);
					height: rem(26);
				}

				.header-link-button,
				.customer-name,
				.showcart {
					gap: rem(5);
				}

				.header-link-title,
				.customer-title,
				.minicart-title {
					@include type__10(
						$line-height: 1
					);
					position: static;
					height: auto;
					width: auto;
					margin: 0;
					padding: 0;
				}

				/* Cart drawer toggle
				* -------------------------------------
				*/
				.minicart-wrapper {
					margin: 0;
					padding: 0;
					position: relative;

					// counter
					.qty {
						top: -5px; /* var minicart-count-bubble-top was override on theme var */
						right: $minicart-count-bubble-right;

						.counter-number {
							color: $minicart-count-color;
							font-size: 8px; // no point making a new type mixin as its only used once
							line-height: rem(9);
							padding-left: 2px;
							padding-right: 1px;
							min-width: $minicart-count-bubble-width; // Minimum badge width (i.e. 2 or less digits)
							width: auto; // Allow badge to display 3+ digit quantities
						}
					}
				}
			}

			.basket-close {
				display: none; /* shouldn't be displaying */
			}
		}

		.mobile-nav-footer-logout {
			display: block;

			.authorization-link {
				border-bottom: 1px solid $grey-light;
				padding: rem(16) rem(25);
				text-align: center;

				a {
					align-items: center;
					display: flex;
					gap: $ten-gutter;

					&[data-post] {
						justify-content: flex-start;

						&:before {
							@include svg-background(
								'sign-out',
								contain
							);
							content: '';
							display: block;
							height: 15px;
							width: 17px;
						}
					}
				}
			}
		}

		.mobile-cms-menu-links {
			display: block;
		}
	}

	.mobile-cms-menu-links {
		border-bottom: 1px solid $mercury;
		display: none; /* Cms block only visible when mobile menu is active */

		li {
			width: 100%;
		}

		.pagebuilder-button-link {
			align-items: center;
			border-top: 1px solid $mercury;
			display: flex;
			justify-content: space-between;
			padding: $ten-gutter $medium-gutter;
			text-decoration: none;

			&:after {
				@include svg-background(
					$name: 'arrow-right-black',
					$size: contain
				);
				content: '';
				display: inline-block;
				height: rem(10);
				width: rem(6);
			}
		}
	}
}
