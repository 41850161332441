/* -------------------------------------------------
 * -------------------------------------------------
 * Address pages - bit of a catch all
 *
 * -------------------------------------------------
 * @author Fisheye
 */
.account {
	&.customer-address-index {
		.block-addresses-default {
			.block-content {
				display: grid;
				gap: $small-gutter;
				grid-template-columns: repeat(1, minmax(0, 1fr));

				@include min-screen($screen__l) {
					grid-template-columns: repeat(2, minmax(0, 1fr));
				}

				.box {
					padding: 0;
				}
			}
		}
	}
}
