/* -------------------------------------------------
 * -------------------------------------------------
 * B2b Create Account changes
 *
 * Majority of styling is already done in Customer Account
 * This file is additions and amendments for when b2b
 * is enabled
 * -------------------------------------------------
 * @author Fisheye
 */

.company-account-create,
.customer-account-create {
	&[aria-busy="true"] {
		.form {
			.fieldset {
				.field.visuallyhidden {
					width: 1px; /* reset visually hidden width style when page is loading */
				}
			}
		}
	}

	.page-wrapper {
		.page-main {
			padding: rem(45) $small-gutter $gutter $small-gutter;

			.columns {
				padding: 0;
			}

			.main {
				padding: 0;
			}
		}
	}

	.page-title-wrapper {
		padding: 0 0 rem(21) 0;
		max-width: $container-narrow;
		margin: 0 auto;

		.page-title {
			.base {
				@include type__24(
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__m) {
					@include type__36(
						$font-weight: $font-weight-semibold,
						$line-height: rem(52)
					);
				}

				&:after {
					content: '';
				}
			}
		}
	}

	.form-create-account {
		fieldset.fieldset.info,
		fieldset.fieldset {
			max-width: $container-narrow;
			border: 0;

			.field {
				display: block;

				&.street label {
					display: flex !important; //overriding display none
				}

				&.visuallyhidden {
					margin: -1px; /* reset visually hidden width for specificity reasons */
					width: 1px;
				}
			}

			.field.country_id {
				select {
					@include select-style-one;
				}
			}
		}

		.actions-toolbar {
			.primary {
				button {
					@include button__journey;

					&:hover,
					&:focus {
						border: 2px solid $keppel;
					}
				}
			}
		}

		fieldset,
		.fieldset {
			legend,
			.block-title {
				@extend %cancel-visuallyhidden;
				margin-bottom: rem(17);
				width: 100%;

				span,
				strong {
					@include type__18(
						$color: $account-top-link-title-color,
						$font-weight: $font-weight-semibold,
						$line-height: rem(34)
					);
					text-transform: none;
				}
			}
		}

		.actions-toolbar {
			max-width: $container-narrow;
			margin: 0 auto;
		}

		.postcode {
			.control {
				display: flex;
				flex-wrap: wrap;

				input { // no class or id
					flex: 1 1 55%;

					@include min-screen($screen__ml) {
						flex: 1 1 60%;
					}
				}

				.postcode-lookup-button {
					@include button__default;
					@include type__14;
					color: $keppel;
					display: flex;
					flex: auto;
					justify-content: center;
					margin-left: $checkout-delivery-address-button-spacing;
					order: 2;
					width: auto;
				}

				.postcode-lookup-results {
					margin-top: $twenty-gutter;
					order: 3;
				}

				.manual-postcode-input {
					order: 4;
					width: 100%;

					label[for="postcode-lookup-manual-entry"] {
						margin-bottom: 0;
						margin-top: $fifteen-gutter;
						width: 100%;

						> span {
							@include link__underline(
								$border-bottom: solid 1px $keppel,
								$color: $keppel,
							);
							@include type__13(
								$color: $keppel,
							);

							cursor: pointer;
							text-transform: capitalize;
							width: auto;
						}

						&:before {
							display: none; //hide checkbox
						}
					}
				}
			}
		}
	}
}

.company-account-index {
	[id='maincontent'].page-main {
		.columns {
			padding-top: rem(46);
			padding-bottom: rem(90);

			@include min-screen($screen__m) {
				padding-bottom: rem(166);
			}

			.column.main {
				margin: 0 auto;
				max-width: $container-narrow;
				width: 100%;
			}
		}
	}

	.block-confirmation {
		.block-title {
			margin-bottom: $gutter;

			@include min-screen($screen__m) {
				display: flex;
				align-items: center;
				gap: $medium-gutter;
			}
		}

		svg {
			width: rem(80);
			height: rem(80);
		}

		h2 {
			@include type__20(
				$line-height: rem(34),
				$font-weight: $font-weight-semibold
			);
			padding-top: $gutter;

			@include min-screen($screen__m) {
				@include type__24(
					$line-height: rem(34),
					$font-weight: $font-weight-semibold
				);
				padding-top: 0;
			}
		}

		p {
			@include type__16(
				$line-height: rem(28)
			);
			padding-bottom: rem(46);
		}

		.primary {
			@include button__journey;
			display: block;
			text-align: center;
		}
	}
}
