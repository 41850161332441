/* -------------------------------------------------
 * -------------------------------------------------
 * Layout and general styles for checkout
 *
 * TODO: Check this against Rokit code
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	.authentication-wrapper {
		display: none;
	}

	@include min-screen($screen__m) {
		.opc-estimated-wrapper {
			display: none;
		}
	}

	.opc-wrapper {
		width: 100%;
	}

	.opc-sidebar {
		width: 100%;
		left: auto;
		padding: 0;

		@include min-screen($screen__m) {
			position: relative !important;
			max-width: 29.41176em;
		}

		div[id="opc-sidebar"] {
			display: none;
			padding-top: $twenty-gutter;

			&.sidebar-open {
				display: block;
				padding-top: 0;
			}

			@include min-screen($screen__m) {
				display: block;
				padding-top: 0;
			}
		}
	}

	// -------------------------------------------------------------
	// base fallbacks using floats
	// -------------------------------------------------------------
	@include min-screen($screen__m) {
		.opc-sidebar {
			padding-left: $page-padding;
			width: $checkout-sidebar-width;
			max-width: $checkout-sidebar-width;
			float: right;

			.modal-content {
				div[id="opc-sidebar"] {
					padding: 0;
				}
			}
		}

		.opc-wrapper {
			width: $checkout-main-width;
			float: left;
			border-right: $checkout-methods-border;
			padding-right: $page-padding;
		}
	}

	@supports (display: flex) {
		// -------------------------------------------------------------
		// flexbox options
		// -------------------------------------------------------------
		@include min-screen($screen__m) {

			.opc-wrapper {
				flex: 0 1 $checkout-main-width;
				order: 1;
				width: $checkout-main-width;
				max-width: 100%
			}

			.opc-sidebar {
				flex: 0 1 $checkout-sidebar-width;
				order: 2;
				width: $checkout-sidebar-width;
				max-width: 100%;
			}
		}
	}
}

//
// ordered items list styling (success page)
//-----------------
.checkout-success-order-items-wrapper {
	.order-title {
		display: none;

		@include min-screen($screen__m) {
			display: block;
		}

		span {
			// color: $count-color;
			color: $order-success-count-color;
		}
	}

	.table-wrapper {
		.table-order-items {
			tr {
				vertical-align: top;
				@include min-screen($screen__m) {
					vertical-align: middle;
				}
			}

			.photo {
				width: em(128);
				padding: 0;
				display: table-cell;

				img {
					width: em(128);
				}
			}
			.name,
			.price,
			.qty,
			.subtotal {
				display: block;
				padding: 0;
				text-align: left;
			}

			.col {
				&.qty {
					// more specific to override other stylng
					text-align: left;
				}
			}

			.subtotal {
				.title {
					@extend %visuallyhidden;
				}
			}
			.name {
				.item-options {
					@include min-screen($screen__m) {
						dd {
							margin-top: $twenty-gutter;
						}
					}
				}

				dt {
					// usually a title saying "color" or "size"
					// if i use lib-visibility-hidden(); it still creates a margin which pushes content out of vertical aligment
					display: none;
				}
			}
		}
	}
}
