/* new */
.checkout-footer {
	.store-information-wrapper,
	.card-icons-wrapper	{
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		flex-wrap: wrap;
	}

	// -------------------------------------------------
	// Need Some Help? Block
	// -------------------------------------------------
	.help-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		border: 0;
		margin: 0;
		background-color: $wild-sand;
		padding: $gutter;
		
		@include min-screen($screen__m) {
			padding: $fifty-gutter;
		}

		h2 {
			width: 100%;
			
			@include min-screen($screen__m) {
				width: auto;
			}
		}

		h2,
		span,
		a { // no class - Need Help? title
			text-transform: uppercase;
			font-style: normal;
			@include type__16(
				$font-weight: $font-weight-extrabold,
				$color: $dusty-gray
			);

			@include min-screen($screen__m) {
				@include type__21(
					$font-weight: $font-weight-extrabold,
					$color: $dusty-gray
				);
			}
		}

		a {
			cursor: pointer;
			color: $charcoal-grey;
		}

		> span {
			margin: 0 $five-gutter;

			a {
				margin: 0 0 0 $five-gutter;
			}
		}

		.store-email {
			margin: 0;
		}
	}

	.card-icons-wrapper	 {
		padding: $checkout-footer-row-payment-mobile-padding;
		display: flex;
		justify-content: center;
		background-color: $ebony-clay;

		span {
			margin-right: $checkout-footer-trust-icon-spacing;

			&:last-of-type {
				margin-right: 0;
			}
			
			img {
				width: $checkout-footer-trust-icon-width;
				height: $checkout-footer-trust-icon-height;
			}
		}
	}
}
