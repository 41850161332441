/* -------------------------------------------------
 * -------------------------------------------------
 * Default Product Labels
 * -------------------------------------------------
 * @author Fisheye
 */

.cataloglabel-placeholder {
	left: 0;
	right: auto;

	@include min-screen($screen__m) {
		top: rem(7);
		right: rem(20);
		left: rem(20);
	}
}
