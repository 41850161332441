/* -------------------------------------------------
 * -------------------------------------------------
 * Main area for product details. Add to basket form
 *
 * Options/swatches are in _product-options.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-product-view {
	/* Page title
	 * -------------------------------------------------
	 */
	.page-title-wrapper {
		padding: 0 $small-gutter $ten-gutter $small-gutter;

		@include min-screen($screen__m) {
			padding: 0;
		}

		.page-title {
			&:before,
			&:after {
				display: none; /* Remove the line height crop from tpm theme */
			}

			.base {
				@include type__24(
					$color: $product-title-color,
					$font-weight: $product-title-weight,
					$line-height: rem(34)
				);

				@include min-screen($screen__m) {
					@include type__36(
						$font-weight: $product-title-weight,
						$line-height: rem(52)
					);
				}
			}
		}
	}

	.product-info-main {
		display: flex;
		flex-direction: column;

		.overview.attribute {
			border-top: 1px solid $mercury;
			padding: $product-tabs-padding-mobile;
			margin: 0;
			order: 2;

			@include min-screen($screen__m) {
				border: 0;
				order: 1;
				padding: $five-gutter 0 rem(18) 0;
			}

			h4 {
				@include type__20(
					$font-weight: $font-weight-semibold,
					$line-height: rem(34)
				);
				@extend %trailing-full-stop;
				padding-bottom: rem(17);

				@include min-screen($screen__m) {
					margin: -1px;
					padding: 0;
					width: 1px;
					height: 1px;
					overflow: hidden;
					clip: rect(0 0 0 0);
					clip: rect(0, 0, 0, 0);
					position: absolute;
				}
			}

			p,
			li {
				@include type__16(
					$line-height: rem(28)
				);

				@include min-screen($screen__m) {
					@include type__14(
						$line-height: rem(26)
					)
				}
			}

			li {
				align-items: center;
				display: flex;
				gap: $ten-gutter;
				padding-top: $five-gutter;

				&:before {
					@include svg-background(
						$name: 'green-tick',
						$size: contain
					);
					content: '';
					display: block;
					height: rem(13);
					width: rem(17);
				}
			}
		}

		.product-options-bottom {
			display: flex;
			flex-direction: column;
			padding-bottom: $medium-gutter;

			@include min-screen($screen__m) {
				border: 1px solid $mercury;
				border-radius: $border-radius-l;
			}
		}

		.product-info-addto {
			background-color: $white;
			display: block;
			padding: 0;
			margin: 0;
			order: 1;

			@include min-screen($screen__m) {
				order: 2;
			}

			.product-add-form {
				width: 100%;

				.stock-leadtime-wrapper {
					display: flex;
					flex-direction: column-reverse;
					justify-content: flex-end;

					.lead-time-message {
						@include type__14(
							$color: $tuna,
							$font-weight: $font-weight-normal,
							$line-height: rem(24)
						);
					}
				}
			}

			.configurable-stock-wrapper,
			.stock-status-wrapper {
				.stock {
					@include type__14(
						$color: $tuna,
						$font-weight: $font-weight-semibold,
						$line-height: rem(24)
					);
					align-items: center;
					background-color: transparent;
					display: flex;
					gap: $five-gutter;
					padding: 0 0 rem(8) 0;

					&:before {
						content: '';
						display: block;
						height: rem(14);
						width: rem(14);
					}

					&.available:before {
						@include svg-background(
							$name: success-icon,
							$size: contain
						);
					}

					&.unavailable {
						color: $edward;

						&:before {
							@include svg-background(
								$name: info-icon,
								$size: contain
							);
						}
					}
				}
			}
		}
	}

	/* Price and Sku
 	 * -------------------------------------------------
 	 */
	.product-info-price {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0;
		order: 2;
		padding: rem(18) $small-gutter $small-gutter $small-gutter;
		position: relative;

		@include min-screen($screen__m) {
			padding: rem(17) $medium-gutter $small-gutter $medium-gutter;
		}

		.price-including-tax-container {
			display: none; /* We have to use styles here both vat prices are required elsewhere so it cant be disabled in the admin */
		}

		.price-excluding-tax-container {
			.price-display-label {
				@extend %visuallyhidden; /* hidden as the template is used elsewhere */
			}
		}

		.price-label p {
			@include type__12(
				$color: $edward,
				$line-height: rem(24)
			);

			span {
				text-transform: uppercase;
			}
		}
	}

	.product-info-stock-sku {
		align-items: flex-end;
		display: flex;
		flex-direction: column;

		.sku {
			display: flex;
			gap: rem(2);
			margin: 0;
			width: auto;

			.type:after {
				content: ':';
			}

			.type,
			.value {
				@include type__12;
			}
		}

		.stock {
			&.available {
				&:before {
					@include svg-background(
						$name: zoom-in,
						$size: contain
					);
					background-color: red;
					margin: 0;
				}

				span {
					@include type__14(
						$color: $tuna,
						$font-weight: $font-weight-normal,
						$line-height: rem(24)
					);
					background-color: transparent;
					gap: $five-gutter;
					padding: 0;
				}
			}
		}
	}

	/* Tier Pricing
 	 * -------------------------------------------------
 	 */
	.tier-price-container {
		display: block;
		flex: 1 1 100%;
		max-width: 100%;
		order: 3;
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			padding: 0 $medium-gutter;
		}

		.price-box {
			display: block;
			padding-bottom: $twenty-gutter;
		}

		.slick-track {
			display: flex !important;
		}

		.slick-slide {
			padding: 0 rem(2);
			height: auto;

			> div {
				height: 100%;
			}
		}

		.title {
			@include type__14(
				$font-weight: $font-weight-semibold
			);
			padding-bottom: rem(11);
		}

		.tier-price-block {
			width: 100%;

		}

		.prices-tier {
			&:not(.slick-initialized) {
				display: flex;
				gap: rem(8);
				overflow: auto;

				.item {
					height: auto;
				}
			}

			.item {
				@include type__12(
					$font-weight: $font-weight-semibold
				);
				border: 1px solid $mercury;
				border-radius: $border-radius-s;
				display: flex !important; /* override inline styles */
				flex-direction: column;
				height: 100%;
				min-width: rem(110);
				padding: rem(8);
			}

			.purchase-amount {
				@include type__12(
					$font-weight: $font-weight-semibold,
					$line-height: rem(24)
				);
			}

			.price {
				@include type__12(
					$color: $keppel,
					$font-weight: $font-weight-semibold,
					$line-height: rem(24)
				);
			}

			.benefit {
				@include type__10(
					$color: $edward,
					$font-weight: $font-weight-normal,
					$line-height: rem(20)
				);
			}
		}
	}

	/* Add to basket btn
	 * -------------------------------------------------
	 */
	.box-tocart {
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			padding: 0 $medium-gutter;
		}

		&.unavailable {
			.actions {
				display: none; // Disable/hide add to basket actions when selected option OOS
			}
		}

		.actions {
			display: flex;

			.qty {
				display: block;
				flex: 0 1 rem(105);
				margin: 0;

				@include min-screen($screen__ll) {
					flex: 0 1 rem(136);
				}

				label {
					@extend %visuallyhidden; /* not required in designs but useful for screen readers */
				}

				.control {
					border: 1px solid $keppel;
					border-top-left-radius: $border-radius-s;
					border-bottom-left-radius: $border-radius-s;
					display: grid;
					grid-template-columns: rem(28) auto rem(28);
					height: 100%;

					@include min-screen($screen__ll) {
						grid-template-columns: rem(44) rem(92) rem(44);
					}

					input {
						@include input-style-one(
							$padding: 0
						);
						border: 0;
						color: $ebony-clay;
						flex: 1 1 auto;
						min-width: 0;
						width: auto;
					}

					button {
						background-color: $white;
						border: 0;
						border-radius: 0;
						color: $ebony-clay;
						height: 100%;
						width: 100%;
						font-size: 23px; /* px used here to reduce blur */
						padding: 0;
					}
				}
			}

			.tocart {
				@include button__journey(
					$padding: rem(13) rem(18)
				);
				@include svg-background(
					$name: basket-icon-white,
					$size: rem(24) rem(26)
				);
				align-items: center;
				background-position: calc(100% - #{rem(16)}) center;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				display: flex;
				flex: 1 1 auto;
				justify-content: space-between;
				line-height: rem(28);

				&:hover,
				&:focus {
					@include svg-background(
						$name: basket-icon-accent,
						$size: rem(24) rem(26)
					);
					background-position: calc(100% - #{rem(16)}) center;
				}

				@include min-screen($screen__m) {
					padding: rem(13) rem(24)
				}
			}

		}

		.block-requisition-list {
			position: relative;
			padding-left: rem(8);
		}

		.requisition-list-button {
			@include button__default($padding: 0);
			@include svg-background(
				$name: bookmark,
				$size: rem(15) rem(20)
			);
			height: rem(50);
			width: rem(50);

			&:hover,
			&:focus {
				@include svg-background(
					$name: bookmark-filled,
					$size: rem(15) rem(20)
				);
				background-color: transparent;
			}

			span {
				@extend %visuallyhidden; /* Text not required in design but useful for accessibility */
			}
		}

		[data-target="dropdown"] {
			display: none; /* hide dropdown by default */

			&[aria-hidden="false"] {
				background-color: $white;
				box-shadow: 0 1px 16px rgba(23, 27, 63, 0.24);
				display: block;
				min-width: rem(300);
				padding: 0 $ten-gutter;
				position: absolute;
				left: auto;
				right: 0;
				z-index: 1;
			}

			.item {
				padding: $ten-gutter 0;
				border-bottom: 1px solid $mercury;

				&:last-child {
					border-bottom: 0;
				}
			}

			span {
				cursor: pointer;

				&:hover,
				&:focus {

				}
			}
		}
	}

	/* Mobile fixed button
 	 * -------------------------------------------------
 	 */
	.select-options-button-wrapper {
		background-color: $white;
		box-shadow: 0 1px 16px rgba(23, 27, 63, 0.24);
		inset: auto 0 0 0;
		padding: $medium-gutter $small-gutter;
		position: sticky;
		z-index: 100;

		@include min-screen($screen__m) {
			display: none; /* Hide for smaller devices */
		}

		.select-options-button {
			@include button__journey;
			width: 100%;
		}
	}

	/* Related category slider
	 * -------------------------------------------------
	 */
	.product-related-categories {
		border-top: 1px solid $mercury;
		padding: rem(48) $small-gutter $gutter $small-gutter;

		@include min-screen($screen__m) {
			padding-top: rem(68) $gutter $small-gutter $gutter;
		}

		@include min-screen($screen__cms-width) {
			padding-left: 0;
			padding-right: 0;
		}

		.content:not(.slick-initialized ) {
			display: flex;
			overflow: hidden;
		}

		h3 {
			@include type__20;
			@extend %trailing-full-stop;
			padding-bottom: $twenty-gutter;

			@include min-screen($screen__m) {
				@include type__24;
				padding-bottom: rem(35);
			}
		}

		.related-category {
			padding-right: $small-gutter;
			text-align: left;
		}

		.img-container {
			border: 1px solid $mercury;
			border-radius: $border-radius-s;
			padding: $small-gutter;

			img {
				display: block;
				width: 100%;
			}
		}

		.category-name {
			padding-top: rem(13);
		}

		span {
			@include type__16(
				$font-weight: $font-weight-semibold
			);
		}
	}
}
