/* -------------------------------------------------
 * -------------------------------------------------
 * Button Styles
 * -------------------------------------------------
 * @author Fisheye
 */

/* Default button
 * -------------------------------------------------
 */

/* Default button
 * -------------------------------------------------
 * transparent background with orange text
 */

@mixin button__default(
	$padding: $button-default-padding,
	$background-color: $button-default-background,
	$border: $button-default-border,
	$border-radius: $button-default-radius,
	$color: $button-default-color,
	$font-weight: $button-default-weight
) {
	@include type__16(
		$color: $color,
		$font-weight: $font-weight
	);
	padding: $padding;
	background-color: $background-color;
	border: $border;
	border-radius: $border-radius;
	text-transform: none;
	text-align: center;

	&:hover,
	&:focus {
		background-color: $button-default-background-hover;
		border: $button-default-border-hover;
		opacity: 1;
		color: $button-default-color-hover;

		span {
			color: $button-default-color-hover;
		}
	}

	span {
		@include type__16(
			$color: inherit,
			$font-weight: $font-weight-semibold
		);
	}
}

/* Journey Button
 * -------------------------------------------------
 * Orange color background with white text / opposite on hover
 */

@mixin button__journey(
	$padding: $button-journey-padding,
	$background-color: $button-journey-background,
	$border-radius: $button-journey-radius,
	$font-weight: $button-journey-weight,
	$color: $button-journey-color
) {
	padding: $padding;
	background-color: $background-color;
	border-radius: $border-radius;
	border: $button-journey-border;
	text-align: center;

	@include type__16(
		$color: $color,
		$font-weight: $font-weight-semibold
	);

	&:hover,
	&:focus {
		color: $keppel;
		background-color: $white;
		opacity: 1;

		span {
			color: $keppel;
		}
	}

	span {
		@include type__16(
			$color: inherit,
			$font-weight: $font-weight-semibold
		);
	}
}

/* Pagination Button
 * -------------------------------------------------
 * Pagination buttons used on category pages
 */

@mixin button__pagination(
	$background-color: $button-pagination-background,
	$padding: $button-pagination-padding,
	$color: $button-pagination-color,
) {
	display: block;
	background-color: $background-color;
	padding: $padding;
	height: $button-pagination-height; // added to stop button height varying moving as they scale
	margin: 0 1px; // the spacing between pagination buttons which don't neeed to be scaled
	width: $button-pagination-width;
	text-align: center;
	border-radius: $button-pagination-radius;

	&.next,
	&.previous {
		background-color: $button-pagination-arrow-background;
		border-top-right-radius: 100px;
		border-bottom-right-radius: 100px;
		@include icon__arrow-white(
			$width: $button-pagination-width,
			$height: $button-pagination-height
		);
	}

	&.previous {
		transform: rotate(180deg);
	}

	span {
		@include type__14(
			$color: $color
		)
	}
}

%icon-button {
	background-color: $white;
	border: 1px solid $mercury;
	border-radius: $border-radius-s;
	padding: 0;
	height: rem(48);
	opacity: 1;
	width: rem(48);

	&:hover,
	&:focus {
		border: 1px solid $edward;
	}
}
