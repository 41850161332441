/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to Category page
 *
 * Most styles should be in relevant stylesheet i.e. product-listing.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-category-view,
.catalogsearch-result-index {
	.toolbar-top,
	.category-header {
		@include min-screen($screen__m) {
			.pages {
				/* Shouldn't be shown on desktop sized screens */
				display: none;
			}

			.filter-content {
				display: flex;
				align-items: center;
			}

			.filter-options {
				flex: 1;
				padding-top: 0;
			}

			.toolbar-sorter {
				position: relative;
				padding: 0;
				margin: 0;
				order: 2;
		
				label {
					@include type__14($color: $grey-medium);
					align-items: center;
					bottom: 0;
					display: flex;
					padding-left: $ten-gutter;
					position: absolute;
					height: 100%;
					display: flex;
					align-self: center;
					padding-bottom: 0;
					left: 0;
				}
		
				select {
					@include input-style-one($padding: rem(9) $twenty-gutter rem(9) rem(70));
					min-height: rem(44);
					font-weight: $font-weight-semibold;
					color: $ebony-clay;
					font-size: rem(14);
				}
			}
		}
	}

	.toolbar-top {
		/* Don't show until page loaded */
		display: none;
	}

	&[aria-busy="false"] {
		.toolbar-top {
			display: flex;
		}
	}
	
	.page-main {
		@include min-screen($screen__m) {
			padding: 0 $gutter;
		}

		.page.messages,
		.columns {
			max-width: $cms-container-width;
			margin: 0 auto;
		}

		.js-search-wrapper {
			margin: 0;
			padding: $small-gutter;
			border-top: 1px solid $mercury;

			@include min-screen($screen__m) {
				display: none; /* Hidden on desktop */
			}

			.js-search {
				@include input-style-one;
				padding-left: rem(35);
				width: 100%;

				@include min-screen($screen__m) {
					width: rem(280);
				}
			}
		}
	}

	.page-title-wrapper {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		gap: $gutter;
		padding: $medium-gutter $small-gutter;
		position: relative;
		width: 100%;

		@include min-screen($screen__m) {
			border-bottom: 0;
			flex: 1;
			padding: 0;
		}

		.page-title {
			padding: 0;
			text-align: center;

			@include min-screen($screen__m) {
				border-bottom: 0;
				text-align: left;
			}

			span {
				@include type__18;

				@include min-screen($screen__m) {
					@include type__40(
						$line-height: rem(56),
						$font-weight: $font-weight-semibold
					);

					&:after {
						content: '.'; // unable to use secret class here as they cant be nested in breakpoints
						color: $keppel;
					}
				}
			}
		}

		.category-product-count {
			display: none;

			@include min-screen($screen__m) {
				background-color: $grey-light;
				color: $dusty-gray;
				padding: .5rem;
				border-radius: 4px;
				font-weight: 400;
				font-size: 14px;
			}
		}
	}

	.product-listing-container {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		padding: 0;

		@include min-screen($screen__s) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			column-gap: $small-gutter;
			row-gap: rem(53);
		}

		@include min-screen($screen__m) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@include min-screen($screen__xl) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.category-list-wrapper {
		background-color: $white;
		display: none; /* hidden on mobile  styles continued below as they are global for other areas e.g top level */
		padding: $category-page-padding-mobile;
		grid-column: span 2 / span 2;
		margin: 0 auto;
		max-width: $cms-container-width;
		order: 2;

		@include min-screen($screen__m) {
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			column-gap: rem(16);
			row-gap: rem(20);
		}

		@include min-screen($screen__l) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		@include min-screen($screen__cms-width) {
			grid-template-columns: repeat(7, minmax(0, 1fr));
		}

		.category-list-item {
			@include min-screen($screen__m) {
				border: 0;
			}

			&:first-child {
				border-top: 1px solid $mercury;

				@include min-screen($screen__m) {
					border-top: 0;
				}
			}

			a {
				padding: $category-sub-category-padding-mobile;

				@include min-screen($screen__m) {
					padding: $category-sub-category-padding-desktop;
				}

				.category-image {
					@include min-screen($screen__m) {
						margin: 0;
						border: 1px solid $mercury;
						border-radius: $border-radius-s;
					}

					img {
						height: rem(56);

						@include min-screen($screen__m) {
							aspect-ratio: 1 / 1;
							height: auto;
							object-fit: contain;
							object-position: center;
							padding: $ten-gutter;
							width: 100%;
						}
					}
				}
			}

			.category-list-item-title {
				@include type__16(
					$line-height: rem(28)
				);
				text-transform: lowercase;

				&::first-letter {
					text-transform: uppercase;
				}

				@include min-screen($screen__m) {
					@include type__16(
						$line-height: rem(22),
						$font-weight: $font-weight-semibold
					);
					padding-top: rem(14);
					text-align: left;
				}

				&:after {
					height: rem(11);
					width: rem(6);
				}
			}
		}
	}

	.toolbar-bottom {
		border: 0;

		.toolbar-products {
			border: 0;
		}

		.field.limiter {
			@include min-screen($screen__m) {
				display: flex;
			}
		}

		.toolbar-sorter {
			display: none; // hidden on bottom toolbar
		}
	}


	.category-cms {
		padding-top: rem(68);

		&:last-child {
			padding-bottom: rem(74);

			@include min-screen($screen__m) {
				padding-bottom: rem(127);
			}
		}

		h3 {
			@include type__24;
			@extend %trailing-full-stop;
			padding: 0 $small-gutter $twenty-gutter $small-gutter;
			text-transform: none;

			@include min-screen($screen__m) {
				padding-left: 0;
			}
		}

		.block-products-list {
			border-radius: 0;
			padding-left: $small-gutter;
			padding-right: $small-gutter;

			@include min-screen($screen__m) {
				padding-left: 0;
				padding-right: 0;
			}

			.product-items {
				display: grid;
				gap: $small-gutter;
				grid-template-columns: repeat(2, minmax(0, 1fr));

				@include min-screen($screen__m) {
					grid-template-columns: repeat(3, minmax(0, 1fr));
				}

				@include min-screen($screen__l) {
					grid-template-columns: repeat(4, minmax(0, 1fr));
				}
			}

			.product-item {
				padding: 0;
			}
		}

		.product-item-photo {
			padding: 0;
		}

		[data-content-type='products'] {
			border: 0;
		}
	}

	.category-cms-bottom,
	.category-cms-additional {
		padding: $ten-gutter 0;

		.scroll-navigation-wrapper {
			.pagebuilder-buttons {
				.pagebuilder-button-primary {
					background-color: $white;
					border: none;

					> span {
						color: $ebony-clay;
					}
				}
			}
		}
	}
}

/*  Top Level Category
 * -------------------------------------------------
 */

.page-layout-2columns-landing {
	.columns {
		display: block;
	}

	.category-header {
		display: flex;
		flex-direction: column;

		@include min-screen($screen__m) {
			border-bottom: 0;
			display: grid;
			grid-template-columns: auto rem(280);
			justify-content: space-between;
			gap: $gutter;
			padding: $gutter 0;
		}

		.js-search-wrapper {
			margin: 0;
			padding: $small-gutter;
			order: 0;

			@include min-screen($screen__m) {
				display: block;
				align-self: flex-end;
				padding: 0;
				border: 0;
				order: 1;
			}
		}

		.toolbar-products {
			display: none; /* Not wanted on top level categories */
		}
	}

	.subcategories-cms-column {
		&:empty {
			background-color: red;
		}

		h2 {
			display: none; /* Not required on top level */
		}
	}

	.category-list-wrapper {
		display: flex;
		order: 4;

		@include min-screen($screen__m) {
			display: grid;
			column-gap: rem(16);
			row-gap: rem(20);
		}

		@include min-screen($screen__cms-width) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
	}
}

/*  Bottom Level Category
 * -------------------------------------------------
 */

.catalog-category-view:not(.page-layout-2columns-landing),
.catalogsearch-result-index {
	/* Bottom level category layout changes
	 * unable to do this in layout as its
	 * desktop specific
	 */
	.columns {
		@include min-screen($screen__m) {
			display: flex;
			flex-direction: column;
		}

		.category-list-wrapper {
			@include min-screen($screen__m) {
				padding-bottom: rem(49);
			}
		}

		.related-categories-cms-column {
			order: 5;
		}

		.category-header {
			align-items: center;
			display: grid;
			grid-template-columns: 100px auto 100px;
			max-width: none;
			order: 2;
			padding-bottom: $small-gutter;
			position: relative;

			@include min-screen($screen__m) {
				align-items: center;
				gap: $small-gutter;
				grid-template-columns: auto rem(280);
				padding: 0 0 $gutter 0;
			}

			.page-title-wrapper {
				order: 1;
				grid-column-start: 2;
				justify-content: center;
				margin: 0;

				@include min-screen($screen__m) {
					grid-column-start: 1;
					justify-content: flex-start;
				}
			}

			.filter {
				grid-column-start: 3;
				order: 2;

				@include min-screen($screen__m) {
					display: block;
					grid-column: span 2 / span 2;
					grid-row-start: 3;
					grid-column-start: 1;
					order: 4;
				}
			}

			.js-search-wrapper {
				order: 3;
				grid-column: span 3 / span 3;
				width: 100vw;
				padding-bottom: 0;
			}

			.toolbar-products {
				display: none; /* Hide element on mobile as its emptied with js */

				@include min-screen($screen__m) {
					display: flex;
					justify-content: flex-end;
					gap: 0;
					order: 3;
					padding: $fifteen-gutter 0 0;
					grid-row-start: 3;
					grid-column-start: 2;
				}
			}
		}

		.main {
			order: 3;

			@include min-screen($screen__s) {
				padding: 0 $small-gutter;
			}

			@include min-screen($screen__m) {
				padding: 0;
			}
		}
	}

	.subcategories-cms-column,
	.related-categories-cms-column {
		display: none; /* hidden on mobile  styles continued below as they are global for other areas e.g top level */

		@include min-screen($screen__m) {
			display: block;
			padding-top: $twenty-gutter;
		}

		h2 {
			@include type__24;
			padding-bottom: rem(19);
		}
	}

	.category-list-wrapper {
		h2 {
			@include type__24;
			padding-bottom: rem(19);
		}
	}

	.products-wrapper {
		border-top: 1px solid $mercury;

		@include min-screen($screen__s) {
			border: 0;
		}
	}
}
