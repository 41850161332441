/* -------------------------------------------------
 * -------------------------------------------------
 * Global form styles
 * When you make changes test the account area forms
 * as well as what you are changing incase
 * breaking changes are introduced
 * -------------------------------------------------
 * @author Fisheye
 */

form,
.form {
	fieldset,
	.fieldset {
		.field {
			margin: 0 0 rem(16) 0;

			input.mage-error {
				font-size: initial; // reverts tpm styles
				font-weight: initial;
				color: initial;
			}

			.field-error,
			.mage-error:not(input),
			.hosted-error {
				@include type__16(
					$color: $error-color,
					$font-weight: $font-weight-semibold,
					$line-height: rem(28)
				);
				margin-top: $item-gutter;
				white-space: normal;
				max-width: 100%;
				width: auto;
			}

			.label,
			label {
				@include type__14(
					$font-weight: $font-weight-semibold,
					$line-height: rem(28)
				);

				span {
					font-width: inherit;
					font-size: inherit;
				}
			}
		}
	}
}
