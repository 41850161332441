/* -------------------------------------------------
 * -------------------------------------------------
 * Product options. Swatches & Dropdown only
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product-info-addto,
.bundle-info {
	// this should be styled in _product-info-main.scss
	// configurable options - size / color swatches styling
	.product-options-wrapper {
		flex-basis: 100%;
		background-color: $gallery;
		padding: $twenty-gutter $fifteen-gutter;
		margin-bottom: rem(27);

		@include min-screen($screen__m) {
			margin-bottom: $gutter;
		}

		&.active {
			flex: 0 1 auto;
			width: 100%;
		}

		@include min-screen($screen__m) {
			padding: $gutter;
		}

		h4 {
			text-transform: uppercase;
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;

			@include type__13(
				$color: $keppel,
				$font-weight: $font-weight-semibold
			);

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				@include svg-background('expand-arrow-green');
				height: 10px;
				width: 21px;
				right: 0;
			}

			@include min-screen($screen__m) {
				@include type__15(
					$color: $keppel,
					$font-weight: $font-weight-semibold
				);
			}
		}

		&.active {
			h4 {
				&:after {
					@include svg-background('collapse-arrow-green');
				}
			}
		}

		.fieldset {
			> div:first-of-type {
				margin-top: 0;
				border-top: 0;
			}

			> div:last-of-type {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: 0;
			}

			.custom-option,
			.file {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				.field-wrapper {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					flex: 0 1 100%;
					margin-left: $fifteen-gutter;

					@include min-screen($screen__m) {
						margin-left: $twenty-gutter;
					}

					.control {
						flex: 0 1 100%;
					}
				}

				&:before,
				.selected-file {
					width: $product-custom-options-sample-image-width;
					height: $product-custom-options-sample-image-height;
					flex: 0 0 $product-custom-options-sample-image-width;

					@include min-screen($screen__m) {
						width: $product-custom-options-sample-image-width-desktop;
						height: $product-custom-options-sample-image-height-desktop;
						flex: 0 0 $product-custom-options-sample-image-width-desktop;
					}
				}

				&.centre:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/centre.jpg');
				}

				&.ribbon:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/ribbon.jpg');
				}

				&.engraving:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/trophy-engraving.jpg');
				}

				&.colour:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/colour.jpg');
				}

				&.glass-engraving:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/glass-engraving.jpg');
				}

				&.presentation-case:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/presentation-case.jpg');
				}

				&.self-assembly:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/self-assembly.jpg');
				}

				&.wording:before {
					content: "";
					display: inline-block;
					background-size: contain;
					background-image: url('../images/custom-options/wording.jpg');
				}

				&.file,
				&.select-modal,
				&.engraving {
					.extention {
						flex: 1 0 100%;
					}

					&:before {
						display: none;
					}

					.file-button,
					.engraving-button {
						flex: 0 1 auto;
						padding-top: 0;
						margin-bottom: 0;
					}

					.note {
						margin-top: $five-gutter;

						&:first-of-type {
							margin-top: $ten-gutter;

							@include min-screen($screen__xl) {
								margin-top: 0;
							}
						}
					}

					.actions {
						width: auto;
					}

					.control {
						flex: 0 1 100%;

						@include min-screen($screen__l) {
							flex: 1;
						}

						&.engraving-text {
							flex: 0 1 100%;
							order: 3;

							&:nth-of-type(2) {
								order: 2;
							}
						}
					}
				}

				&.engraving {
					.field-wrapper {
						margin-left: 0;
					}
				}
			}
		}

		.field,
		.file-container {
			display: block;
			border-top: 1px solid $wild-sand;
			padding-top: rem(17);
			margin-top: rem(23);
			margin-bottom: 0;
			flex: 0 1 auto;

			&:first-child {
				margin-top: 0;
				padding-top: 0;
			}

			@include min-screen($screen__m) {
				padding-top: $twenty-gutter;
				margin-top: $twenty-gutter;
			}

			.selected-option {
				.title {
					strong {
						@include type__14;
					}

					.label {
						@include type__14($font-weight: $font-weight-normal)
					}
				}
			}

			.label {
				flex: 0 1 100%;

				@include min-screen($screen__m) {
					padding-top: rem(4);
				}

				+ .same-engraving-container {
					margin-top: $ten-gutter;
				}
			}

			.field {
				border-top: 0;
				padding-top: 0;
				margin-top: 0;
			}

			&.color {
				.sp-replacer {
					@extend %visuallyhidden;
				}
			}

			.note {
				@include type__13;
				font-style: italic;
			}
		}

		.hidden__inline {
			display: none;
		}

		.configurable {
			display: flex;
			flex-wrap: wrap;
			width: 100%; // size options or options that have a dropdown

			.label {
				@include type-setting(-1);
				flex: 1 100%;
				align-self: flex-end;

				@include min-screen($screen__ll) {
					flex: 1 27%;
					padding-right: $item-gutter;
				}
			}

			.control {
				position: relative;
				flex: 1 100%;

				@include min-screen($screen__l) {
					flex: 1 73%;
				}

				.super-attribute-select,
				.swatch-select select {
					// this removed inner box shadow on android devices only appears on this specific select for some reason...
					border-color: $white !important; //override input.scss
					@include type-setting(-1);
					@include input-style-one;
					@include svg-background("arrow-down");
					background-position: calc(100% - #{$twenty-gutter * 2}) 50%;
				}
			}
		}

		.product-info-addto {
			order: 2;
		}

		.product-add-form {
			order: 3;
		}

		.product-custom-option {
			width: 100%;

			@include min-screen($screen__m) {
				max-width: rem(378);
			}
		}

		// browse btn
		// ------------------
		.field,
		.selected-option {
			.button,
			.select-new,
			&.file label.label:before,
			&.engraving label.engraving-button:before {
				cursor: pointer;
				@include button__default(
					$background-color: $charcoal-grey,
					$padding: rem(8) rem(20)
				);
				text-transform: none;
				display: inline-block;
				margin-right: $twenty-gutter;
			}

			.select-new,
			.choose-option {
				margin-top: $ten-gutter;
			}
		}

		// selected option
		// -----------------
		.selected-option {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			img {
				width: $product-custom-options-sample-image-width;
				height: $product-custom-options-sample-image-height;
				display: block;

				@include min-screen($screen__m) {
					width: $product-custom-options-sample-image-width-desktop;
					height: $product-custom-options-sample-image-height-desktop;
				}
			}

			> .label {
				margin-bottom: 0;
			}

			.title {
				flex: 0 1 100%;
				margin-top: $ten-gutter;

				@include min-screen($screen__s) {
					flex: 0 1 auto;
					margin-right: $twenty-gutter;
				}

				@include min-screen($screen__m) {
					flex: 0 1 100%;
				}

				@include min-screen($screen__xl) {
					flex: 0 1 auto;
				}

				strong {
					&:after {
						content: ':';
					}
				}

				.label {
					display: inline;

					&:after {
						display: none;
					}
				}

				.price {
					@include type__13;
				}
			}
		}

		// Engraving form
		// ------------------------
		.engraving {
			.control {
				flex-flow: row wrap;
				flex: 0 1 auto;
				position: relative;
				margin-right: 0;

				@include min-screen($screen__m) {
					display: flex;
				}

				+ .control:last-child {
					.custom-engraving {
						margin-bottom: 0;
					}
				}

				.label {
					order: 0;
					flex: 1 0 100%;

					.product-custom-option {
						flex: 0 1 auto;
					}
				}

				.custom-engraving { // textarea
					min-height: em(130);
					text-align: center;

					@include min-screen($screen__l) {
						min-height: auto;
						max-height: em(89);
					}

					+ .note {
						margin-top: 0;
					}
				}

				.note { // X amount remaining
					flex: 1;
					padding: 0;
					position: static;
					font-style: italic;
					margin-top: $ten-gutter;
					margin-bottom: $ten-gutter;
					@include type__12(
						$font-weight: $font-weight-normal
					);

					@include min-screen($screen__m) {
						margin-top: 0;
						margin-bottom: 0;
					}

					a {
						text-decoration: underline;
					}
				}

				.character-counter-note {
					position: absolute;
					bottom: $fifteen-gutter;
					right: $ten-gutter;
				}

				.character-counter {
					@include type__11(
						$color: $dusty-gray,
						$font-weight: $font-weight-normal
					);
				}
			}

			.choice {
				.label {
					span {
						@include type__13(
							$font-weight: $font-weight-normal
						);
					}
				}
			}

			.field {
				order: 3;
				flex: 0 1 auto;

				.label {
					width: 100%;
					padding-top: $five-gutter;
					margin-bottom: 0;
					@include type__11(
						$color: $dusty-gray,
						$font-weight: $font-weight-normal
					);

					&.same-engraving {
						@extend %checkbox-unchecked;
						padding-top: 0;
						display: flex;
						align-items: center;
						width: 100%;
					}
				}

				input[type="checkbox"]:checked + label {
					@extend %checkbox-checked;
				}
			}
		}

		// File upload
		// -------------------
		.file,
		.engraving {
			label.label {
				&:before {
					content: attr(data-content);
				}

				&:after {
					display: none;
				}
			}

			.control {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				flex: 1 1 auto;

				@include min-screen($screen__xl) {
					flex: 1;
				}

				.copy-text {
					@include type__13(
						$color: $ebony-clay,
						$font-weight: $font-weight-normal
					);
					@include link__underline;
					margin-top: $twenty-gutter;
					margin-bottom: $ten-gutter;
					cursor: pointer;
				}
			}

			// bit hacky but its the only way to styles inputs types of file
			.product-custom-option {
				@extend %visuallyhidden;
			}
		}
	}

	.file-container {
		.note {
			@include type__13($font-weight: $font-weight-bold);
			font-style: italic;
			padding: 0;
			flex: 1 1 100%;
			margin-top: $twenty-gutter;

			strong {
				@include type__13($font-weight: $font-weight-normal);
				font-style: italic;
			}

			&.file-extension {
				color: $dusty-gray;
				font-weight: $font-weight-normal;

				strong {
					color: inherit;
				}
			}
		}
	}

	// colour options
	.swatch-opt {
		margin: 0;
		width: 100%;
	}

	.swatch-attribute {
		display: flex;
		flex-wrap: wrap;
		gap: $product-swatch-spacing;
		margin-bottom: $twenty-gutter;
		padding-top: $product-swatch-option-padding-top; // padding added if product has multiple config options

		&:first-child {
			padding-top: 0;
		}

		.super-attribute-select {
			@extend %visuallyhidden;
		}

		.mage-error {
			flex: 1 1 100%;
		}

		.swatch-option {
			@include type__14;
			background-color: $product-swatch-text-background-color;
			padding: $product-swatch-text-padding;
			border: $product-swatch-text-border;
			cursor: pointer;

			&.color {
				height: $product-swatch-colour-height;
				min-width: $product-swatch-colour-min-width;
			}

			&.image {
				height: $product-swatch-colour-height !important; // importants used to override inline styles specific to img uploads
				min-width: $product-swatch-colour-min-width !important;
				background-size: cover !important;
			}

			&.selected {
				background: $product-swatch-text-active-background-color;
				color: $product-swatch-text-active-color;
				border: $product-swatch-text-active-border;
			}

			&.disabled,
			&[disabled] {
				cursor: not-allowed;
				opacity: 0.7;
				pointer-events: none;
				position: relative;
				text-decoration: $line-through;
				text-decoration-thickness: 2px;

				&.color,
				&.image {
					&::before {
						border-top: 2px solid $ebony-clay;
						left: 0;
						right: 0;
						top: 50%;
						position: absolute;
						transform: rotate(-30deg);
						-webkit-transform: rotate(-30deg);
						-moz-transform: rotate(-30deg);
						-ms-transform: rotate(-30deg);
						-o-transform: rotate(-30deg);
						content: '';
					}
				}
			}

			// OOS configurable options - still selectable
			&.unavailable {
				opacity: 0.7;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					// Display X / cross in the background
					background-image: linear-gradient(
						to top left,
						transparent 0%,
						transparent calc(50% - 1px),
						$gainsboro 50%,
						transparent calc(50% + 1px),
						transparent 100%
					),
					linear-gradient(
						to top right,
						transparent 0%,
						transparent calc(50% - 1px),
						$gainsboro 50%,
						transparent calc(50% + 1px),
						transparent 100%
					);
				}
			}
		}
	}

	.swatch-attribute-label {
		@include type__13;
		text-transform: uppercase;

		@include min-screen($screen__m) {
			@include type__15($font-weight: $font-weight-normal);
		}

		&:after {
			content: ':'; // used css here as its only the colour labels that have this
		}
	}

	.swatch-attribute-selected-option {
		@include type__13($font-weight: $font-weight-semibold);
		text-transform: uppercase;

		@include min-screen($screen__m) {
			@include type__15($font-weight: $font-weight-semibold);
		}
	}

	.swatch-attribute-selected-option {
		font-weight: $font-weight-bold;
		padding-left: 0;
	}

	.swatch-attribute-options {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		flex: 1 0 100%;
		gap: $product-swatch-spacing;
		margin-top: 0;

		select {
			// is there are multiple config options and colour is one all others get nested in the swatch containers
			// this removed inner box shadow on android devices only appears on this specific select for some reason...
			border-color: $white !important; //override input.scss
			@include type-setting(-1);
			@include input-style-one;
			@include svg-background("arrow-down");
			background-position: calc(100% - #{$twenty-gutter * 2}) 50%;
		}
	}
}

// personalisation popup
// ----------------------
[class*="option"] {
	&.modal-popup {
		padding-top: $forty-gutter;

		input[type="radio"]:checked ~ label {
			.option_images_gallery {
				border: 2px solid $radical-red;
			}
		}

		&.modal-slide {
			.modal-inner-wrap {
				overflow: visible;
				max-width: rem(600);
				margin: auto;
				position: relative;
				top: rem(20);

				@include min-screen($screen__xl) {
					top: rem(60);
				}

				.modal-content {
					padding: $twenty-gutter $twenty-gutter $gutter $twenty-gutter;
					background-color: $gainsboro;
				}
			}
		}

		.modal-header {
			position: relative;
			padding: 0;

			.modal-title {
				@extend %visuallyhidden;
			}

			.action-close {
				@include action-w-icon__cross-white;
				position: absolute;
				top: em(-60);
				right: 0;

				> span {
					@include type__13($color: $white);
				}
			}
		}

		.product-option-modal-wrapper {
			.options-view {
				&.hidden__inline {
					display: block;
				}

				.options-container {
					position: relative;
					display: flex;
					justify-content: center;
					max-height: 55vh;

					button {
						background-color: $ebony-clay;
						background-position: center;
						background-size: 25px 15px;
						background-repeat: no-repeat;
						border-radius: 100px;
						border: none;
						position: absolute;
						z-index: 1;
						height: 47px;
						width: 47px;
						padding: 0;

						span {
							@extend %visuallyhidden; /* For screen readers */
						}

						&.top {
							@include svg-background('arrow-up-green');
							top: 0;
						}

						&.bottom  {
							@include svg-background('arrow-down-green');
							bottom: 0;
						}
					}
				}

				.options-list {
					display: flex;
					flex-wrap: wrap;

					&:not(.nested) {
						max-height: 55vh;
						overflow-y: auto;
						overflow-x: hidden;
						padding-right: $fifteen-gutter;
					}
				}

				/* The -webkit-scrollbar used here is needed for users that do not have a mouse plugged into their computer,
				 * and rely on the touchpad to scroll. Without this here the scrollbar only appears when you start scrolling.
				 * The scrollbar is required to be shown all of the time.
				 */
				.options-list::-webkit-scrollbar {
					max-width: 8px;
					border-radius: 8px;
					margin: 1px;
					position: relative;
					background-color: $ebony-clay;
				}

				.options-list::-webkit-scrollbar-thumb {
					border-radius: 8px;
					position: absolute;
					background-color: $keppel;
					border: 2px solid $ebony-clay;
				}

				.choice {
					width: calc(33.33% - 13px);
					margin-right: $twenty-gutter;

					@include max-screen($screen__m) {
						&:nth-child(3n) {
							margin-right: 0;
						}
					}

					@include min-screen($screen__m + 1) {
						width: calc(20% - 17px);

						&:nth-child(5n) {
							margin-right: 0;
						}
					}

					> span {
						padding-top: rem(8);
						min-height: rem(50);
						padding-bottom: $ten-gutter;
					}
				}

				.label {
					display: flex;
					flex-direction: column;
					margin-bottom: $gutter;

					&:before {
						display: none;
					}
				}

				.option_images_gallery {
					order: 1;
					flex: 1 1 auto;
					margin-bottom: rem(6);

					img {
						width: 100%;
					}
				}

				span {
					order: 2;
					flex: 0 1 auto;
					@include type__13;
					text-align: center;
				}

				.mageworx-optionswatches-option-gallery {
					margin: 0;
				}
			}
		}

		.current-selection {
			display: flex;
			position: relative;
			padding-top: $gutter;

			&:before {
				content: '';
				background: linear-gradient(180deg, hsla(0, 0%, 95%, 0) 0, $gainsboro);
				position: absolute;
				left: 0;
				top: em(-80);
				height: em(80);
				width: calc(100% - #{$ten-gutter});
			}

			.image-wrapper {
				height: auto;
				width: rem(112);
			}

			img {
				width: 100%;
			}

			.content-wrapper {
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;
				padding-left: $twenty-gutter;
			}

			.header {
				display: flex;
				flex: 0 1 auto;
				flex-wrap: wrap;
				margin-bottom: $fifteen-gutter;

				@include min-screen($screen__m) {
					flex-wrap: nowrap;
				}

				strong,
				strong span {
					padding: 0;
					@include type__14($font-weight: $font-weight-semibold);
				}

				.title {
					flex: 1;
					margin-bottom: $five-gutter;

					@include min-screen($screen__m) {
						margin-bottom: 0;
					}

					strong {
						span {
							&:after {
								content: ':';
							}
						}
					}

					.label {
						display: inline;
						padding: 0;
						@include type__14($font-weight: $font-weight-normal);
					}
				}

				.remove {
					padding: 0;
					cursor: pointer;
					text-align: left;
					flex: 1 1 100%;

					@include min-screen($screen__m) {
						flex: 1;
						text-align: right;
					}

					> span {
						padding: 0;
						font-weight: $font-weight-normal;
						@include link__underline;
					}
				}
			}

			.actions {
				flex: 0 1 auto;
			}

			.primary {
				display: flex;
				justify-content: center;
				width: 100%;
				@include button__journey(
					$padding: $ten-gutter
				);

				@include min-screen($screen__m) {
					@include button__journey;
				}

				&[disabled],
				&[disabled]:hover {
					opacity: 0.3;
					background-color: $keppel;
				}

				span {
					padding: 0;
					@include type__14($color: $white);

					@include min-screen($screen__m) {
						@include type__21($color: $white);
					}
				}
			}
		}
	}
}

.qtip .qtip-content {
	@include type__13;
	padding: $fifteen-gutter;
}

.swatch-option-tooltip {
	display: none; // Initially hides the tooltip, will show when swatches are hovered
	background-color: $wild-sand;
	border: 1px solid $gallery;
	padding: $product-swatch-tooltip-padding;
	position: absolute;
	text-align: center;

	.image {
		border: 1px solid $gallery;
		min-height: $product-swatch-tooltip-image-height;
		min-width: $product-swatch-tooltip-image-width;
	}
}
