/* -------------------------------------------------
 * -------------------------------------------------
 * Cart Promotion & help area
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	// -------------------------------------------------
	// Basket discount, giftcards, gift messages
	// -------------------------------------------------

	.block.discount,
	.block.giftcard,
	.gift-item-block {
		margin-bottom: $small-gutter;

		&.active {
			.title:after {
				@include svg-background(
					$name: arrow-down-green
				);
				transform: rotate(180deg);
			}
		}

		.title {
			padding: $small-gutter 0;

			&:after {
				@include svg-background(
					$name: arrow-down-green
				);
			}

			strong {
				@include type__14(
					$color: $tuna,
					$line-height: 1.7
				);
				text-transform: none;
			}
		}

		.input-text {
			@include input-style-two(
				$padding: $ten-gutter,
			);
		}

		.fieldset.coupon {
			.actions-toolbar {
				width: auto;

				.primary {
					&:after {
						display: none; /* icon not reqiuired on this theme */
					}

					span {
						@extend %cancel-visuallyhidden;
						@include type__14(
							$color: $tuna,
							$font-weight: $font-weight-semibold
						);
						padding: $ten-gutter;
					}
				}
			}
		}
	}
}
