/* -------------------------------------------------
 * -------------------------------------------------
 * Table of contents
 * 1. Base
 * 2. Colours
 *	- 2a. Product Colours
 * 3. Responsive Type Settings
 * 4. Grid Settings
 * 5. Header Styles
 * 6. Menu Styles
 * 7. Toolbar Styles
 * 8. Sidebar Styles
 * 9. Footer Styles
 * 10. Basket Styles
 * 11. Category Styles
 * 12. Product Styles
 * 13. Gallery Settings
 * 14. Forms Settings
 * 15. Account Styles
 * 16. Breakpoints
 * 17. Hotspot Settings
 * 18. Message Settings
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Base
// -------------------------------------------------

// Common colours - PLEASE DONT CHANGE THESE THEY ARE GLOBAL

$gray: #B4BDC5;// Updated for this theme - do not change
$grey-lightest: #FAFAFA; // Updated for this theme - do not change
$grey-light: #f2f2f2; // Updated for this theme - do not change
$grey-medium: #6C757D; // Updated for this theme - do not change
$grey-dark: #303539; // Updated for this theme - do not change

// -----------

// glenway colours. Same var names as trophies used with updated values
// as it makes the new theme build less painful
// these will likely all be updated by the end of the build
$vulcan: #141621;
$ebony-clay: #222222; // Updated for this theme - do not change
$keppel: #F56B0F; // Updated for this theme - do not change
$keppel-light: #F56B0F; // Updated for this theme - do not change
$charcoal-grey: #4B555E; // Updated for this theme - do not change
$mercury: #E4E7EC; // Updated for this theme - do not change
$radical-red: #F21D77; // Updated for this theme - do not change
$carnation: #fb4a67;
$dusty-gray: #5B656F; // Updated for this theme - do not change
$edward: #8A939A; // Updated for this theme - do not change
$silver: #F7F7F7; // Updated for this theme - do not change
$wild-sand: #f6f6f6; // Updated for this theme - do not change
$gallery: #eaeaea;
$alto: #d6d6d6;
$gainsboro: #dcdcdc;
$light-tuna: rgba(100, 190, 179, 0.16);
$medium-tuna: #64BEB3;
$tuna: #0B7E6D; // Updated for this theme - do not change
$heading-font-color: $ebony-clay;
$error-color: #C03131;
$warning-color: #FFF3EB; // Updated for this theme - do not change
$brand-accent: $keppel;

$container-narrow: rem(568);

$cms-container-width: rem(1264); // used for all cms pages as they have bigger guttering
$medium-gutter: rem(24); // Used a lot in this theme

$border-radius-s: 2px; // Updated for this theme - do not change
$border-radius-m: 4px; // Updated for this theme - do not change
$border-radius-l: 8px; // Updated for this theme - do not change

$form-required-input-icon-color: $keppel;

$sidebar-width: rem(260);
$sidebar-max-width: rem(260);

$focus-color-default: $brand-accent;

// -------------------------------------------------
// 7. Toolbar Styles
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 8. Sidebar Styles
// -------------------------------------------------

$sidebar-background-color: $silver;
$sidebar-background-color-alt: $white;
$sidebar-price-slide-color: $brand-accent;
$sidebar-count-color: $body-font-color;

// -------------------------------------------------
// 9. Footer Styles
// -------------------------------------------------

$seo-description-bg: $wild-sand;
$seo-description-border: solid 1px $white;
$seo-description-container-width: em(1235);

$footer-background-color: $grey-lightest; // default bg color in case no rows as such, rows then load over it

$footer-row-company-bg: $wild-sand;
$footer-row-one-bg: $grey-dark;
$footer-row-two-bg: $ebony-clay;
$footer-icon-color: $white;
$footer-icon-hover-color: $white;
$footer-icon-font-size: 2.1875rem; // 35px when base is 16. 35 % 16
$footer-icon-font-line-height: 1.875rem; // 30px when base is 16. 30 % 16

$footer-title-color: $white;
$footer-link-color: $white;

// -------------------------------------------------
// 11. Category Styles
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 12. Product Page Styles
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 13. Product Gallery Styles
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 15. Account Styles
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 16. Breakpoints
// -------------------------------------------------

$screen__cms-width: 1264px !default; // the same as the cms max width

// -------------------------------------------------
// 17. Hotspot Settings
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 18. Message Settings
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 19. Layout Settings
// -------------------------------------------------

// tbc here for organisation purposes
