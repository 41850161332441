//
//  Lib -> Components -> Modals
//  _____________________________________________

/**
 Nesting is required to match the specificity of the third party injected styles, and therefore override
 */
[id="cookiescript_injected"] {
    [id="cookiescript_wrapper"] {
		[id="cookiescript_save"],
		[id="cookiescript_accept"] {
			background-color: $keppel-light !important; // Override inline colour for accept/save btn
		}
	}
}

[id="cookiescript_injected_fsd"] {
    [id="cookiescript_fsd_wrapper"] {
		[id="cookiescript_save"] {
			background-color: $keppel-light !important; // Override inline colour for accept/save btn
			border: 1px solid $keppel-light !important; // Override inline border colour for accept/save btn
		}
	}
}

[id="cookiescript_manage"] {
	&:hover {
		[id="cookiescript_manageicon"] {
			.cookiescript_gear {
				fill: $keppel-light !important; // Override inline colour for settings icon
			}
		}
	}
}

.cookiescript_fsd_cookies::-webkit-scrollbar-thumb,
.cookiescript_fsd_tabs_content::-webkit-scrollbar-thumb {
	background-color: $keppel-light !important; // Override inline colour for scrollbar
}

[id="cookiescript_readmore"]:hover,
[id="cookiescript_reportlink"]:hover,
[id="cookiescript_cookiescriptlink"]:hover {
	color: $keppel-light !important; // Override inline colour
}
