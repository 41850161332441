/* -------------------------------------------------
 * Forms for checkout only
 * Most of this is should be definied in forms and inputs etc already
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-container,
.page-layout-checkout._has-modal {
	form {
		.fieldset {
			div[name="shippingAddress.postcode"],
			div[name="billingAddressbraintree.postcode"] { // no unique class or id
				.control {
					.find-address {
						@include button__default;
					}
				}
			}
		}
	}
}

.checkout-container {
	.methods-shipping,
	.payment-method-content {
		.actions-toolbar {
			background-color: $white;
			box-shadow: 0 -1px 16px $black;
			border-radius: 4px 4px 0 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin-top: 0;
			padding: rem(16);
			position: fixed;
			width: 100%;
			z-index: 10;

			@include min-screen($screen__l) {
				box-shadow: none;
				border-radius: none;
				padding: 0;
				position: relative;
			}

			&[id="shipping-method-buttons-container"] {
				@include min-screen($screen__l) {
					margin-top: rem(48);
				}
			}
			.button-wrapper {
				display: flex;
				flex-direction: column;

				.checkout-return-cart {
					@include button__default;

					margin-top: rem(8);
					width: 100%;

					@include min-screen($screen__l) {
						margin-top: rem(16);
					}
				}
			}
		}
	}

	.braintree-apple-pay-button {
		margin: 0;
		padding: rem(22) 0 ;
		width: 100%;

		@include min-screen($screen__l) {
			margin-top: rem(20);
		}
	}
}
