/* -------------------------------------------------
 * -------------------------------------------------
 * Checkout cart layout grid
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.page-main {
		position: relative;
	}

	.loading-mask {
		background-color: rgba(255, 255, 255, 0.8);
		display: block;
	}

	.cart-container {
		padding: 0;
	}

	.page-title-wrapper {
		@include min-screen($screen__m) {
			padding: $forty-gutter $fifteen-gutter $forty-gutter;
		}

		.page-title {
			padding-bottom: $ten-gutter;

			@include min-screen($screen__m) {
				padding-bottom: 0;
			}
		}

		a {
			text-transform: capitalize;
			font-weight: $font-weight-normal;
			@include type__13($color: $white);
			@include link__underline(
				$color: $white,
				$border-bottom: $basket-title-border
			);

			@include min-screen($screen__m) {
				display: inline-block;
			}
		}
	}

	.cart-checkout-top {
		width: 100%;
		position: relative;

		.checkout-methods-items {
			@include min-screen($screen__m) {
				display: none;
			}

			.item {
				.paypal {
					display: none;
				}
			}

			.primary {
				margin: $twenty-gutter 0 0;
			}

			.braintree-apple-pay-button {
				-webkit-appearance: -apple-pay-button;
				-apple-pay-button-type: plain;
				display: block;
				width: 200px;
				min-height: 40px;
				border: 1px solid black;
				background-image: -webkit-named-image(apple-pay-logo-black);
				background-size: 100% calc(62%);
				background-repeat: no-repeat;
				background-color: black;
				background-position: 50% 50%;
				border-radius: 5px;
				padding: 2px;
				margin: auto;
				transition: background-color 0.15s;
				cursor: pointer;
			}
		}

		.basket-delivery-upsell {
			position: static;
			width: 100%; // Always going to be 100% on mobile
			right: 0;
			top: $basket-promo-message-top;
			margin-top: $basket-promo-message-outer-spacing;

			@include min-screen($screen__m) {
				position: absolute;
				margin-top: 0;
				width: $basket-col-two-width; // Want this to be the same width as the summary panel
			}
		}
	}

	.checkout-cart-content {
		@include min-screen($screen__m) {
			display: flex; // So border is the full height of the content
		}
	}

	.columns .column.main {
		padding-top: 0;

		@include min-screen($screen__m) {
			padding-top: $page-padding;
		}
	}

	.column {
		&.cart-column-one {
			display: block;

			@include min-screen($screen__m) {
				padding-right: $basket-col-gutter;
			}

			.form-cart {
				// actual cart contents
				width: 100%;

				.main.actions {
					padding: 0;
				}

				.action.clear {
					display: flex;
					align-items: center;
					border: 0;
					padding: 0;
					@include type__13($font-weight: $font-weight-bold);
					margin: $twenty-gutter 0;
					background: 0;

					@include min-screen($screen__m) {
						margin-bottom: 0;
					}

					&:before {
						content: '';
						display: inline-block;
						width: 27px;
						height: 24px;
						@include svg-background('empty-basket');
						background-size: contain;
						margin-right: $ten-gutter;
					}

					&[name="update_cart_action_temp"] {// only apply when name changes after button click
						&:after {
							content: '';
							background-color: rgba(255, 255, 255, 0.8);
							@extend %element-loader;
							width: auto;
							height: auto;
							background-size: 60px; // match size on checkout
							display: block;
							z-index: 1;
						}
					}
				}
			}

			.cart__promo__container {
				//gift cards etc underneath
				width: 100%;
				clear: both;
				display: block;
			}

			.field.qty {
				input.qty {
					@include input-style-one(
						$border: 1px solid $dusty-gray
					);
					width: rem(90);
					@include type__13($font-weight: $font-weight-semibold);

					@include min-screen($screen__m) {
						@include type__15($font-weight: $font-weight-semibold);
					}
				}

				.control.qty {
					display: flex;
				}

				.qty-button {
					width: auto;
					height: rem(43);
					@include button__grey(
						$padding: 0,
						$background-color: $dusty-gray
					);
					padding: 0 rem(17);
					border: 0;
					align-items: center;
					@include type__24(
						$color: $white,
						$font-weight: $font-weight-normal
					);
				}

				.qty-minus {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				.qty-plus {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}

		&.cart-column-two {
			padding-left: 0;

			@include min-screen($screen__m) {
				padding-left: $basket-col-gutter;
			}
		}

		/* Default fallback
		 * -------------------------------------------------
 		 */
		@include min-screen($screen__m) {
			&.cart-column-one {
				float: left;
				width: $basket-col-one-width;
				border-right: $basket-border;
			}

			&.cart-column-two {
				float: right;
				width: $basket-col-two-width;
			}
		}
	} // closes column
} // closes .checkout-cart-index
