/* -------------------------------------------------
 * -------------------------------------------------
 * Quotes
 *
 * 'My Quotes', found in the customers account
 * -------------------------------------------------
 * @author Fisheye
 */

.account.negotiable_quote-quote-view {
	.quote-heading {
		@include type__18($font-weight: $font-weight-semibold);

		@include min-screen($screen__m) {
			@include type__24($font-weight: $font-weight-semibold);
		}
	}

	.page-main {
		.page-title-wrapper {
			.message {
				padding-bottom: $twenty-gutter;
			}
	
			.quote-status {
				padding-bottom: $twenty-gutter;

				* {
					@include type__18;
	
					@include min-screen($screen__m) {
						@include type__24;
					}
				}
	
				span {
					font-weight: $font-weight-normal;
				}
			}
	
			.quote-details {
				* {
					@include type__14;
				}
	
				strong {
					font-weight: $font-weight-semibold;
				}
			}
		}

		.quote-table {
			border-top: 1px solid $mercury;
			padding-top: $twenty-gutter;
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				padding-top: $gutter;
				margin-top: $gutter;
			}
		}

		/* Quote Product Items
		 * -----------------------------------------------------
		 */
		.quote-table-items {
			margin-top: $ten-gutter;

				thead th,
				tbody td {
					padding: $five-gutter 0;

					@include min-screen($screen__ml) {
						padding: $ten-gutter $twenty-gutter;
					}

					&.item,
					&.delete {
						padding-right: 0;
						padding-left: 0;
					}

					&.item {
						@include min-screen($screen__ml) {
							width: 45%;
						}
					}
				}

				thead {
					* {
						@include type__14($font-weight: $font-weight-semibold);
					}
				}

				tbody {
					td {
						* {
							@include type__14;
						}

						&:before {
							@include type__14($font-weight: $font-weight-semibold);
						}

						.action-delete {
							span {
								color: $keppel;
							}
						}
					}

					.item-options {
						* {
							@include type__12;
						}

						dt {
							padding-top: $five-gutter;
						}

						dd,
						dd * {
							font-weight: $font-weight-semibold;
						}
					}

					.qty {
						.input-text {
							background: transparent;
							border-radius: 2px;
							border: 1px solid $mercury;
							min-width: 3.25rem;
						}
					}

					.actions-toolbar {
						margin: 0;
					}
				}

				.item-actions {
					td {
						display: flex;
						justify-content: flex-start;
						margin-top: $fifteen-gutter;
					}

					.actions-toolbar {
						margin-top: 0;
					}

					td {
						padding: 0;

						.action {
							@include button__journey($padding: $ten-gutter $fifteen-gutter);

							&._disabled {
								opacity: 0.4;
							}
						}
					}
				}
			}

			.quote-view-buttons {
				.action {
					@include button__journey;
					min-height: 3.0625rem;

					&:not(:first-child) {
						margin-left: $twenty-gutter;
					}
				}
			}

			/* Quote Totals
			 * -----------------------------------------------------
			 */
			.quote-table-totals-wrap {
				table {
					margin: 0;

					&.quote-table-totals {
						.catalog_price_table {
							> td {
								padding: 0;
							}
						}
					}

					* {
						@include type__14;
					}

					tr {
						.subtotal > th,
						.subtotalTax > th,
						.discount > th,
						.tax > th,
						.catalog_price > th {
							/* Hidden as psuedo elements are used on mobile to rearrange the table headings to
							 * improve UX.
							 */
							display: none;

							@include min-screen($screen__ml) {
								display: table-cell;
							}
						}

						td {
							display: block;

							@include min-screen($screen__ml) {
								display: table-cell;
							}

							&:before {
								text-align: right;
							}
						}
					}

					th,
					td {
						padding: 0 0 $ten-gutter;
						width: 100%;
						text-align: right;
					}

					td {
						padding-left: $gutter;
						justify-content: flex-end;
					}

					.grand_total {
						* {
							font-weight: $font-weight-semibold;
						}
					}
				}
			}
		}

		/* Quote History Log
		 * -----------------------------------------------------
		 */
		.quote-actions-toolbar {
			.actions.quote-view-links {
				a {
					span {
						@include type__14;
					}

					&.action {
						@include link__underline;
						display: block;
						max-width: max-content;
						margin: 0 0 $ten-gutter;
			
						&:empty {
							margin: 0;
						}
					}
				}
			}
		}

		.quote-details-items {
			.quote-history-log-block {
				border-top: 1px solid $mercury;
				padding-top: $twenty-gutter;
				margin-top: $twenty-gutter;
		
				@include min-screen($screen__m) {
					padding-top: $gutter;
					margin-top: $gutter;
				}
			}

			.history-log-block-list {
				margin-top: $fifteen-gutter;

				@include min-screen($screen__m) {
					margin-top: $twenty-gutter;
				}
			}

			.history-log-block-item {
				&:not(:last-child) {
					padding-bottom: $twenty-gutter;

					@include min-screen($screen__m) {
						padding-bottom: $gutter;
					}
				}

				* {
					@include type__14;
				}

				b {
					font-weight: $font-weight-semibold;
				}
			}

			.history-log-block-item-create-update-quote,
			.history-log-block-item-action-describe {
				&:not(:last-child) {
					padding-bottom: $ten-gutter;
				}
			}

			.comments-history-links {
				.data {
					margin: 0 0 $ten-gutter;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.history-log-block-item-create-update-quote,
			.history-log-block-item-action-describe,
			.comments-history-links {
				display: flex;
				flex-direction: column;
				column-gap: $five-gutter;

				.data {
					@include link__underline;
					@include type__14;
					display: block;
					max-width: max-content;
		
					&:empty {
						margin: 0;
					}
				}
			}
		}

		/* Quote Comments
		 * -----------------------------------------------------
		 */
		.quote-comments-block {
			border-top: 1px solid $mercury;
			padding-top: $twenty-gutter;
			margin-top: $twenty-gutter;
	
			@include min-screen($screen__m) {
				padding-top: $gutter;
				margin-top: $gutter;
			}

			.comments-block-list {
				margin-top: $fifteen-gutter;

				@include min-screen($screen__m) {
					margin-top: $twenty-gutter;
				}

				.comments-block-item {
					&:not(:last-child) {
						padding-bottom: $twenty-gutter;
	
						@include min-screen($screen__m) {
							padding-bottom: $gutter;
						}
					}
				}
	
				.comments-block-item-date-time,
				.comments-block-item-comment {
					@include type__14;
				}
	
				.comments-block-item-date-time {
					font-weight: $font-weight-semibold;
				}
			}
		 }

		/* Quote Delivery Information
		 * -----------------------------------------------------
		 */
		.quote-shipping-info {
			border-top: 1px solid $mercury;
			padding-top: $twenty-gutter;
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				padding-top: $gutter;
				margin-top: $gutter;
			}

			.block-content {
				.box {
					margin: 0;
				}

				.box-shipping-address,
				.box-order-shipping-method {
					margin: 0;
	
					&:not(:last-child) {
						padding-bottom: $twenty-gutter;
					}
	
					* {
						@include type__14;
					}

					.box-title {
						span {
							@include type__16($font-weight: $font-weight-semibold);
						}
					}
	
					.action {
						@include link__underline;
						display: inline-block;
						margin-top: $ten-gutter;
					}
				}
			}
		}

		/* Quote Add Comment
		 * -----------------------------------------------------
		 */
		 .block-comments {
			border-top: 1px solid $mercury;
			padding-top: $twenty-gutter;
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				padding-top: $gutter;
				margin-top: $gutter;
			}

			.field-attachment {
				margin-top: $ten-gutter;

				label {
					display: flex;
					flex-direction: column;

					input {
						order: 5;
					}
				}
			}
		 }
	}
