/* -------------------------------------------------
 * -------------------------------------------------
 * JS Minisearch
 * -------------------------------------------------
 * @author Fisheye
 */


/* Styles based off of the homepage design
 * If they are not global please wrap these in
 * [id="homepage-js-search"] apply global styles
 * above this code block.
 */

.navigation-main-ul .js-search-wrapper {
	background-color: $white;
	padding-bottom: 0;

	.modal-overlay {
		height: 200vh;
	}

	@include min-screen($screen__m) {
		padding: $gutter rem(19) 0 $gutter;
	}
}

.js-search-container {
	width: 100%;
	max-width: $cms-container-width;
	margin: 0 auto;

	.input-container {
		position: relative;
		width: 100%;
	}

	.js-search-results {
		margin: 0;
		right: 0;
		left: auto;
		top: 100%;
		border-radius: $border-radius-m;
		max-width: none;
		width: 100%;
		box-shadow: $dropdown-shadow;

		@include min-screen($screen__m) {
			max-width: rem(320);
			left: auto;
		}
	}

	.js-search {
		@include input-style-two(
			$color: $ebony-clay,
		);
		@include svg-background(
			$name: search,
			$size: rem(20)
		);
		background-position: rem(10);
		padding-left: rem(35);
	}
}

/* Homepage Specific
 * -------------------------------------
 */

[id="homepage-js-search"] {
	padding-top: rem(33);

	.js-search-container {
		@include min-screen($screen__m) {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			align-items: flex-end;
		}
	}

	.label {
		@extend %cancel-visuallyhidden;
		width: 100%;
		padding: 0;

		span {
			@include type__28;
			text-align: left;
			padding-bottom: $small-gutter;
			display: block;

			@include min-screen($screen__m) {
				@include type__40;
				@include text-crop(
					$top-adjustment: 0,
					$bottom-adjustment: 2px);
				padding: 0;
				overflow: hidden;
			}

			&:before,
			&:after {
				display: none; /* Remove text crop */
			}
		}

		strong {
			@extend %trailing-full-stop;
			@include type__28(
				$font-weight: $font-weight-semibold
			);

			@include min-screen($screen__m) {
				@include type__40(
					$font-weight: $font-weight-semibold
				);
				display: block;
			}
		}
	}

	.input-container {
		.js-search { // Search field when not active (no overlay)
			z-index: 70;
		}

		.active { // Active overlay when search results are shown
			z-index: 115;
		}

		.set-overlay { // Search field when overlay is active (should appear above with results)
			z-index: 120;
		}

		@include min-screen($screen__m) {
			max-width: rem(320);
			margin-left: auto;
		}
	}

	.js-search-results {
		z-index: 120;
	}
}

/* MegaMenu Specific
 * -------------------------------------
 */
.navigation-main-ul {
	.js-search-container {
		max-width: rem(1365);

		.input-container {
			.js-search {
				@include input-style-two(
					$background-color: $white,
				);
				padding-left: $forty-gutter;
			}
		}
	}
}
