/* -------------------------------------------------
 * -------------------------------------------------
 * Product Page
 *
 * Specific layout settings not covered in
 * _flex-grid.scss or _grid.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-product-view {
	.main {
		padding-bottom: 0; // this is not needed as correct padding is being added to each upsell section
	}

	.product-main-container {
		@extend %row-container;
		background-color: $product-page-main-background-color;
		margin-bottom: 0;
		padding: 0;

		@include min-screen($screen__m) {
			border-bottom: 1px solid $gallery;
			margin-bottom: $gutter;
			padding-bottom: $gutter;
		}
	}

	.product-additional-container {
		padding: $gutter 0 rem(33);
		clear: both;

		@include min-screen($screen__m) {
			padding: rem(49) 0 rem(19);
		}

		.product-info-detailed {
			display: flow-root;
			@extend %flex__item;
		}
	}

	.product-info-main {
		@include min-screen($screen__m) {
			padding-left: $gutter;
		}
	}

	.product.info {
		h3 {
			@extend %visuallyhidden;
		}
	}
}
