/* -------------------------------------------------
 * -------------------------------------------------
 * General layout of main check out steps
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	// main list of steps in checkout
	.opc,
	.checkout-onepage-success {
		>li {
			.step-title {
				@include type__18();
				text-transform: none;
				padding-top: 0;
				padding-bottom: rem(16);
			}
		}
	}

	.opc-progress-bar {
		.progress-bar {
			display: flex;
			padding-bottom: rem(8);

			.progress-bar-item {
				&:not(:last-child) {
					&::after {
						content: '>';
						color: $gray;
						margin: 0 rem(10);
					}
				}

				&._active {
					span {
						color: $ebony-clay;
					}
				}

				span {
					@include type__12(
						$color: $edward,
					);

					vertical-align: bottom;
				}
			}
		}

		.progress-title {
			padding-bottom: rem(24);

			// This an extra page title which only display when at the active page
			.progress-title-item {
				display: none;

				&._active {
					display: block;
				}

				span {
					@extend %trailing-full-stop;

					@include type__20(
						$color: $ebony-clay,
						$font-weight: $font-weight-semibold,
					);

					vertical-align: bottom;

					@include min-screen($screen__m) {
						@include type__24(
							$color: $ebony-clay,
							$font-weight: $font-weight-semibold,
						);
					}
				}
			}
		}
	}
}
