/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to account area
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// sets all of the main gutters
body[class*="customer"],
body[class*="company"],
.quickorder-index-index,
.account,
.sales-guest-form,
.sales-guest-view,
.sales-guest-invoice,
.sales-guest-shipment {
	.page-main {
		.actions {
			button.action.primary {
				@include button__default();
				margin-top: $twenty-gutter;
			}
		}

		.columns {
			padding: $page-padding-mobile;

			@include min-screen($screen__m) {
				padding: $page-padding;
			}
		}
	}

	.main {
		padding: 0;
	}

	.block-title,
	.legend,
	.legend span {
		@include type__20(
			$color: $heading-font-color,
			$font-weight: $font-weight-bold
		);
		border-bottom: 0;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-bottom: em(23);
		}

		strong {
			display: flex;

			@include type__20(
				$color: $dusty-gray,
				$font-weight: $font-weight-bold
			);
		}
	}

	.actions-toolbar {
		margin-top: $account-actions-toolbar-margin-top;

		.secondary {
			display: inline-block;
			@include type__13;
			@include link__underline;
			margin-top: $account-inner-spacing;;
		}

		.primary {
			display: block;

			.action {
				@include button__default;
			}
		}
	}

	.manage-company-accounts {
		.actions-toolbar {
			.primary {
				display: inline-block;
			}
		}
	}

	.login-secured-message {
		@include secure__text;
		max-width: 220px;
		display: block;
		margin: $twenty-gutter auto rem(36) auto;

		@include min-screen($screen__s) {
			max-width: none;
			display: flex;
			margin: $account-secure-text-spacing;
		}

		@include min-screen($screen__m) {
			margin-top: $gutter;
		}
	}

	.fieldset {
		.password {
			.control {
				div {
					display: $account-password-strength-indicator-display;
				}
			}
		}
	}

	.block.block-balance-giftcard {
		.block-content {
			a {
				@include link__underline;
			}
		}
	}

	// Giftcard Page
	.form-giftcard-redeem {
		.actions-toolbar {
			display: flex;

			.primary,
			.secondary {
				button {
					@include button__journey;
				}
			}

			.primary {
				@include min-screen($screen__l) {
					// creates space on desktop
					margin-right: $twenty-gutter;
				}
			}

			.secondary {
				margin-top: 0;
				border: 0;
			}
		}
	}
}

.account.page-layout-2columns-left {
	.page-title-wrapper {
		.page-title,
		.block-title {
			@include min-screen($screen__m) {
				// 2 column the title always sits left as center is thrown off by sidebar
				text-align: center;
				padding-left: $account-title-padding-left;
			}
		}
	}
}

.account.newsletter-manage-index {
	.form-newsletter-manage {
		fieldset {
			.field {
				label {
					flex-basis: auto;
				}
			}
		}

		.actions-toolbar {
			margin-top: 0;

			.primary {
				display: none;
				// remove save button as saves automatically when checkbox is clicked
			}
		}
	}
}
