/* -------------------------------------------------
 * -------------------------------------------------
 * Address pages - bit of a catch all
 *
 * -------------------------------------------------
 * @author Fisheye
 */
 .account {
 	&.customer-address-index {
 		.column.main {
 			display: flex;
 			flex-wrap: wrap;
 		}

 		.block-addresses-default,
 		.block-addresses-list {
 			flex: 1 0 100%;
 			position: relative;
 			margin-bottom: $gutter;

 			.block-content {
 				display: flex;
 				flex: 1;
			    margin-top: $twenty-gutter;

 				ol {
 					display: flex;

 					li {
 						flex: 0 1 100%;

 						.item.actions {
 							margin-top: $twenty-gutter;
 						}
 					}
 				}

 				.box {
 					@include min-screen($screen__s) {
 						padding-right: $twenty-gutter;
 					}
 				}
 			}
 		}

 		.block-addresses-default {
 			.block-content {
 				display: flex;
 				flex-flow: row wrap;

 				.box {
 					flex: 1 0 100%;

 					@include min-screen($screen__s) {
 						flex: 0 1 percentage(1/2);
 						display: flex;
 						flex-direction: column;

 						.box-actions {
 							margin-top: auto;
					    }
				    }

				    @include min-screen($screen__ll) {
					    flex: 0 1 percentage(1/3);
				    }

				    @include min-screen(1600px) { // Custom bp
					    flex: 0 1 percentage(1/4);
				    }

				    .box-title {
					    @include type__14($font-weight: $font-weight-bold);
					    display: block; // because this is actually a <strong> tag and margin won't apply unless display is set :(
					    margin-bottom: $twenty-gutter;
				    }

					.box-content {
						margin-bottom: $account-inner-spacing;

						p {
							@include type__13;
						}

						address {
							@include type__address;
						}
					}

					.box-actions {
						.action.edit {
							@include button__default;
							display: inline-block;
						}

						.action.delete {
							display: $account-address-book-delete-default-display;
						}
					}
				}

				.box-address-shipping {
					padding: $account-address-book-mobile-gutter;

					@include min-screen($screen__s) {
						padding: 0;
						padding-right: $account-inner-spacing;
					}
				}
			}
		}

		.block-addresses-list {
			.block-title {
				margin-bottom: $twenty-gutter;
			}

			.block-content {
				margin-top: 0;

				p {
					@include type__13;
				}
			}

			ol.addresses {
				display: flex;
				flex-flow: row wrap;

				li {
					margin-top: $twenty-gutter;
					flex: 0 1 100%;
					display: flex;
					flex-direction: column;

					address {
						@include type__address;
					}

					.item.actions {
						margin-top: $twenty-gutter;

						a {
							@include type__14($font-weight: $font-weight-normal);
							@include link__underline;

							&:first-child {
								margin-right: $account-inner-spacing;
							}
						}
					}
				}
			}
		}

		.actions-toolbar {
			padding-left: 0;
			margin: 0;

			@include min-screen($screen__m) {
				margin: $account-address-book-block-toolbar-margin;
			}

			.primary {
				display: inline-block;
			}

			.secondary {
				margin-left: $item-gutter;
			}

			.action {
				@include type__14($font-weight: $font-weight-normal);
			}
		}
	}
}
