/* -------------------------------------------------
 * -------------------------------------------------
 * Typography
 * Most mixins are in the tpm theme
 * This files just for any global changes we need to
 * make to them
 * -------------------------------------------------
 * @author Fisheye
 */


/* --------------------------------------------------
 * Type scales - Indecicies represents the
 * approximate PX value
 * -------------------------------------------------
 */

// We have to redefine all of these in our new theme to avoid errors
$type-scale: (
	8: 0.5rem,
	11: 0.6875rem,
	12: 0.75rem,
	13: 0.8125rem,
	14: 0.875rem,
	15: 0.9375rem,
	16: 1rem,
	17: 1.063rem,
	18: 1.125rem,
	19: 1.188rem,
	20: 1.25rem,
	21: 1.313rem,
	22: 1.375rem,
	24: 1.5rem,
	28: 1.75rem,
	29: 1.813rem,
	32: 2rem,
	36: 2.25rem,
	40: 2.5rem,
	56: 3.5rem,
	83: 4.882rem,
);

// Easy REM calculator based on 16px base
// : https://offroadcode.com/rem-calculator/
@function type-scale($level) {
	@return map-get($type-scale, $level);
}

// Again, we can make a helper function:
@function line-heights($level) {
	@return map-get($line-heights, $level);
}

$base-line-height: 1;
// look at tpm theme typography file for explanations
$line-heights: (
	8: 1.125rem, // custom do not change
	11: 1.82,
	12: 1.67,
	13: 1.54,
	14: 1.43,
	15: 1.33,
	16: 1.25,
	17: 1.18,
	18: 1.11,
	19: 1.05,
	20: 1,
	21: 1.04,
	22: 1.09,
	24: 1.16,
	28: 2.25rem, // custom do not change
	29: 1.31,
	32: 1.37,
	36: 1.44,
	40: 1.44,
	56: 3.5rem, // custom do not change
	83: 1.25
);

@mixin type__8(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(8, $line-height);
}

@mixin type__10(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(11, $line-height);
}

@mixin type__14(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(14, $line-height);
}

@mixin type__16(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(16, $line-height);
}

@mixin type__18(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(18, $line-height);
}

@mixin type__20(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(20, $line-height);
}

@mixin type__24(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(24, $line-height);
}

@mixin type__28(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(28, $line-height);
}

@mixin type__32(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-semibold,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(32, $line-height);
}

@mixin type__40(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(40, $line-height);
}

@mixin type__56(
	$color: $body-font-color,
	$font-family: $body-font-family,
	$font-weight: $font-weight-normal,
	$line-height: null,
	$letter-spacing: 0.02em
) {
	font-family: $font-family;
	letter-spacing: $letter-spacing;
	font-weight: $font-weight;
	color: $color;
	@include type-setting(56, $line-height);
}

