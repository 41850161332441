/* -------------------------------------------------
 * -------------------------------------------------
 * Ajax Cart - Added to Basket Popup
 *
 * TODO: Test this. Copied directly from Urban Gifts
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.block-basket-preview {
	.modal-inner-wrap {
		.modal-header {
			.action-close {
				@include icon__cross-white(
					$background-color: $edward
				);
				position: static;
				float: right;
			}
		}

		.basket-popup-product-details {
			.basket-popup-title {
				align-self: center;

				span {
					@include type__28(
						$font-weight: $font-weight-semibold,
						$color: $white
					);
					@extend %trailing-full-stop;

					&:before {
						display: none; // Remove text crop mixin
					}

					&:after {
						content: '.'; // Need to overwrite trailing stop as textcrop from tpm breaks it
						display: inline-block;
						margin: 0;
					}
				}
			}
		}
	}
}

