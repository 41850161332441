/* -------------------------------------------------
 * -------------------------------------------------
 * Footer block styles
 * -------------------------------------------------
 * @author Fisheye
 */

.page-footer {
	margin-top: auto;
	padding-top: $gutter;

	.footer.content {
		background-color: $footer-row-one-bg;
	}

	.copyright-info {
		margin-left: auto;
	}

	/* Footer row one
	 * -------------------------------------
	 */
	.footer-row-one {
		max-width: $cms-container-width;
		margin: 0 auto;

		.account-footer-phone,
		.account-footer-email,
		.block.newsletter,
		.footer-brochure-container {
			text-align: left;
			padding: $footer-row-one-padding-mobile;

			@include min-screen($screen__m) {
				flex: $footer-col-newsletter-flex-desktop;
				padding: $footer-row-one-padding-desktop;
			}

			&:first-child {
				@include min-screen($screen__m) {
					padding-left: $gutter;
				}
			}

			&:last-child {
				@include min-screen($screen__m) {
					padding-right: $gutter;
				}
			}

			[data-content-type="text"] {
				padding-bottom: $twenty-gutter;

				&:first-child {
					p {
						@include type__24(
							$color: $white,
							$font-weight: $font-weight-normal
						);

						@include min-screen($screen__m) {
							@include type__36(
								$color: $white,
								$font-weight: $font-weight-normal
							);
							max-width: rem(300);
						}

						@include min-screen($screen__l) {
							max-width: none;
						}
					}

					strong {
						@include type__24(
							$color: $white,
							$font-weight: $font-weight-semibold
						);
						@extend %trailing-full-stop;

						@include min-screen($screen__m) {
							@include type__36(
								$color: $white,
								$font-weight: $font-weight-semibold
							);
						}
					}
				}

				p {
					@include type__16($color: $white);
					margin: 0;
					max-width: none;
				}

				.pagebuilder-button-primary {
					display: block;
				}
			}
		}
	}

	/* Footer row two
	 * -------------------------------------
	 */
	.footer-row-two {
		display: block;
		padding: $footer-row-two-padding-mobile;

		@include min-screen($screen__m) {
			padding: $footer-row-two-padding-desktop;
		}

		.footer-links {
			@include min-screen($screen__m) {
				border-top: $footer-row-two-padding-border;
				border-bottom: $footer-row-two-padding-border;
				max-width: $cms-container-width;
				margin: 0 auto;
				padding: rem(37) 0;
			}

			.pagebuilder-column-group {
				gap: $small-gutter;
			}

			h4 {
				@include type__16(
					$color: $footer-title-color
				);
				text-transform: none;
				padding-bottom: $five-gutter;
			}

			.pagebuilder-button-link {
				text-decoration: none;

				span {
					@include type__14(
						$color: $gray
					);
				}
			}
		}

		.footer-social-container {
			width: 100%;
		}

		.footer-social-links {
			flex-direction: row !important; // override inline styles
			justify-content: space-between !important;
			align-items: center;
			padding-top: $forty-gutter;
			max-width: $cms-container-width;
			margin: 0 auto;

			svg {
				height: rem(40);
			}

			p {
				@include type__14(
					$color: $white,
					$font-weight: $font-weight-semibold
				);
			}
		}
	}

	.signed-out {
		.footer-row-two {
			padding-top: rem(56);

			@include min-screen($screen__m) {
				padding-top: $forty-gutter;
			}
		}

		.footer-social-links {
			padding: 0;
		}

		.footer-row-three {
			.strapline-container {
				@include min-screen($screen__m) {
					justify-content: flex-end;
				}
			}
		}
	}

	/* Footer row three
	 * -------------------------------------
	 */
	.footer-row-three {
		background-color: $keppel;
		padding: rem(17) 0 rem(19) 0;

		@include min-screen($screen__m) {
			padding: $small-gutter $gutter;
		}

		.strapline-container {
			@include min-screen($screen__m) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				max-width: $cms-container-width;
				margin: 0 auto;
			}
		}

		.pagebuilder-buttons {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding-bottom: rem(17);

			@include min-screen($screen__m) {
				padding: 0;
				gap: $small-gutter;
			}

			li {
				&:not(:last-child) {
					a {
						&:after {
							content: '|';
							padding: 0 $ten-gutter;

							@include min-screen($screen__m) {
								display: none; /* Not wanted on desktop */
							}
						}
					}
				}

				a {
					padding: 0;
					text-decoration: none;

					@include min-screen($screen__m) {
						padding: 0;
					}
				}
			}
		}

		.pagebuilder-button-link,
		.copyright-info p {
			@include type__12(
				$color: $white,
			    $font-weight: $font-weight-normal
			);
			text-align: center;
		}
	}

	/* Mobile accordion
	 * -------------------------------------
	 */
	.mob-footer-info-tab {
		padding: 0;

		.mobile-accordion-title {
			@include type__16(
				$color: $footer-title-color,
				$font-weight: $font-weight-semibold
			);
			text-transform: none;
			border-top: $footer-row-two-padding-border;
			border-bottom: $footer-row-two-padding-border;

			&:after {
				@include svg-background('arrow-down-brand-accent');
			}

			&.open {
				margin-bottom: $gutter;

				&:after {
					@include svg-background('arrow-down-brand-accent'); // Override tpm
					transform: rotate(180deg);
				}
			}
		}
	}
}


.checkout-index-index {
	.page-footer .footer-row-three {
		padding: rem(16) rem(16);

		.strapline-container {
			display: flex;
			justify-content: space-between;

			.pagebuilder-buttons {
				padding-bottom: 0;
				justify-content: flex-start;
			}

			[data-content-type="row"] {
				width: 50%;

				.checkout-footer-quick-links {
					flex-flow: nowrap;
    				justify-content: space-between;

					&:after {
						content: '|';
						color: $white;
						padding: 0 $ten-gutter;

						@include min-screen($screen__m) {
							display: none; /* Not wanted on desktop */
						}
					}
				}
			}
		}
	}
}
