/* -------------------------------------------------
 * -------------------------------------------------
 * Account tables
 *
 * -------------------------------------------------
 * @author
 */

.account,
.sales-guest-view,
.sales-guest-invoice {
	table.table-order-items {
		thead {
			td {
				padding: $small-gutter;
			}
		}

		tbody {
			tr {
				border: 1px solid $mercury;
				border-radius: $border-radius-m;
				background-color: $wild-sand;
				padding: $small-gutter;
				margin-bottom: $small-gutter;

				&:first-child {
					padding: $small-gutter; // override tpm theme
				}

				@include min-screen($screen__ml) {
					border: 0;
				}

				&:nth-child(even) {
					@include min-screen($screen__ml) {
						background-color: $white;
					}
				}

				&:nth-child(odd) {
					@include min-screen($screen__ml) {
						background-color: $silver;
					}
				}

				td.col {
					display: block;
					padding: 0 0  rem(7) 0;

					@include min-screen($screen__ml) {
						display: table-cell;
						padding: rem(12) $small-gutter;
					}

					&:last-child {
						padding-top: rem(7);
						padding-bottom: 0;
					}

					&:before {
						padding-right: $five-gutter;
					}

					.action {
						span {
							border-bottom: 0;
							color: $keppel;
						}
					}
				}
			}
		}
	}
}

.company-users-index,
.company_credit-history-index,
.company-role-index {
	.admin__data-grid-outer-wrap {
		.quotes-grid-toolbar {
			@include min-screen($screen__m) {
				margin-top: $twenty-gutter;
			}
		}
	}
}
