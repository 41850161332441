/* -------------------------------------------------
 * -------------------------------------------------
 * Customer recent order
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.customer-account-index {
	.block-dashboard-orders {
		.title-action-wrapper {
			display: flex;
			padding-top: rem(40);

			.block-title {
				margin-bottom: rem(16);

				@include min-screen($screen__ml) {
					margin-bottom: rem(4);
				}

				strong {
					@include type__20(
						$font-weight: $font-weight-semibold
					);

					@include min-screen($screen__ml) {
						@include type__24(
							$font-weight: $font-weight-semibold
						);
					}
				}
			}

			.top-action {
				margin-left: auto;

				@include min-screen($screen__ml) {
					display: none; /* does not show on desktop */
				}

				span {
					@include type__16(
						$color: $keppel,
						$font-weight: $font-weight-normal
					);
				}
			}
		}

		thead tr th.col {
			@include type__14(
				$font-weight: $font-weight-semibold
			);

			@include min-screen($screen__ml) {
				@include type__16(
					$font-weight: $font-weight-semibold
				);

				padding: rem(20) $small-gutter;
			}
		}

		td.total {
			@include min-screen($screen__ll) {
				white-space: nowrap;
			}

			.tax-label {
				white-space: nowrap;
			}
		}

		.bottom-action {
			display: none; /* only show on desktop */
			justify-content: flex-end;
			padding-top: rem(24);

			@include min-screen($screen__ml) {
				display: flex;
			}

			span {
				@include type__16(
					$color: $keppel,
					$font-weight: $font-weight-semibold
				);
			}
		}
	}
}
