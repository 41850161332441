/* -------------------------------------------------
 * -------------------------------------------------
 * Breadcrumbs
 *
 * -------------------------------------------------
*/

.breadcrumbs {
	display: flex;
	align-items: center;
	gap: $ten-gutter;
	margin: $five-gutter auto;
	max-width: $cms-container-width;
	position: static; /* please do not change this, its global. */
	width: 100%;

	/* Category page specific */
	.catalog-category-view :not(.category-header) > & {
		padding: 0 0 0 $small-gutter;
		position: absolute;
		margin: $twenty-gutter 0 0 0;

		@include min-screen($screen__m) {
			position: static;
			padding: 0;
			margin: $twenty-gutter auto;
		}
	}

	.catalog-category-view & {
		margin: 0;
	}

	// Product page specific styles
	.catalog-product-view & {
		margin: 0 auto;
		max-width: $cms-container-width;

		@include min-screen($screen__m) {
			padding-left: $forty-gutter;
		}

		@include min-screen($screen__cms-width) {
			padding-left: 0;
		}

		.back {
			&:before {
				@include svg-background(
					$name: arrow-left-red,
					$size: contain
				);
				content: '';
				cursor: pointer;
				display: inline-block;
				height: 0.75rem;
				width: 0.75rem;
				transition-duration: 0.5s;
			}

			&:hover:before {
				@include svg-background(
					$name: arrow-left-white,
					$size: contain
				);
				transform: translateX(-0.5rem);
			}
		}
	}

	.items {
		display: none; // Hide breadcrumbs on mobile

		@include min-screen($screen__m) {
			display: block;
		}
	}

	li {
		&:after {
			content: "";
			@include svg-background(
				$name: arrow-right,
				$size: 100%
			);
		}

		a,
		strong {
			@include type__12(
				$font-weight: $font-weight-normal
			);
		}
	}

	.back {
		@include button__default;
		display: flex;
		align-items: center;
		gap: $ten-gutter;
		font-size: 0.75rem;
		padding: 0.5rem $ten-gutter;

		@include min-screen($screen__m) {
			gap: $fifteen-gutter;
			padding: rem(8) $fifteen-gutter;
		}

		&-chevron {
			height: 0.75rem;
			width: 0.75rem;
			fill: $keppel;
			transition-duration: 0.5s;
		}

		&:hover {
			.back-chevron {
				fill: $white;
				transform: translateX(-0.5rem);
			}
		}
	}
}
