/* -------------------------------------------------
 * -------------------------------------------------
 * Base Input styles
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Primary input, used globally for most inputs
// -------------------------------------------------

@mixin input-style-one(
	$border: $field-border,
	$background-color: $field-background-color,
	$color: $field-color,
	$border-radius: $field-border-radius,
	$padding: $field-padding
) {
	@include type__16(
		$color: $field-color,
		$font-weight: $font-weight-normal,
		$line-height: rem(28)
	);
	border: $border;
	background-color: $background-color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;
	width: 100%;

	&::placeholder {
		@include type__13(
			$color: $field-placeholder-color,
			$font-weight: $font-weight-normal
		);
		font-style: normal;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;
		box-shadow: $field-outline-shadow;
	}
}

// -------------------------------------------------
// Secondary input, used for search & other inputs
// Very similar to style-one but without the box-shadow
// -------------------------------------------------

@mixin input-style-two(
	$border: $field-two-border,
	$background-color: $field-two-background-color,
	$color: $field-two-color,
	$border-radius: $field-two-border-radius,
	$padding: $field-two-padding,
) {
	@include type__14(
		$color: $field-two-color,
		$font-weight: $font-weight-normal
	);
	border: $border;
	background-color: $background-color;
	color: $color;
	border-radius: $border-radius;
	padding: $padding;
	-webkit-appearance: none;
	transition: all 0.2s ease;

	&::placeholder {
		@include type__13(
			$color: $field-two-placeholder-color,
			$font-weight: $field-two-placeholder-weight
		);
		font-style: $field-two-placeholder-font-style;
		opacity: 1;
		line-height: normal;
	}

	&:hover,
	&:active {
		border: $field-two-border-hover;
	}

	&:focus {
		border: $field-two-border-focus;
		box-shadow: none;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Used for QTY fields
// -------------------------------------------------

@mixin input-style-three(
	$border: $field-three-border,
	$background-color: $field-three-background-color,
	$border-radius: $field-three-border-radius,
	$padding: $field-three-padding,
	$width: $field-three-width
) {
	@include type__16(
		$font-weight: $font-weight-normal,
		$color: $field-three-color
	);
	border: $border;
	background-color: $background-color;
	border-radius: $border-radius;
	padding: $padding;
	width: $field-three-width;
	text-align: center;

	&:placeholder {
		@include type__16;
	}

	&:hover,
	&:active {
		border: $field-three-border-hover;
	}

	&:focus {
		border: $field-three-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// -------------------------------------------------
// Global select style
// -------------------------------------------------

// Default select style
@mixin select-style-one(
	$border: $select-border,
	$background-color: $select-background-color,
	$color: $select-color,
	$border-radius: $select-border-radius, // For Safari, it seems to add a radius.
	$padding: $select-padding,
) {
	@include type__16(
		$color: $select-color,
		$font-weight: $font-weight-normal,
		$line-height: rem(28)
	);
	-webkit-appearance: none;
	width: 100%;
	border: $select-border;
	background-color: $select-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-arrow-background-size
	);
	background-position: $select-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-border-radius;
	padding: $select-padding;

	&:placeholder {
		@include type__14;
	}

	&:hover,
	&:active {
		border: $field-border-hover;
	}

	&:focus {
		border: $field-border-focus;

		&:placeholder {
			opacity: 0;
		}
	}
}

// Select style used on layered nav items
// Note, this style may need to be applied to an element that isn't select (i.e. a div)
@mixin select-style-two(
	$border-top: $select-two-border-top,
	$border-bottom: $select-two-border-bottom,
	$border-left: $select-two-border-left,
	$border-right: $select-two-border-right,
	$background-color: $select-two-background-color,
	$color: $select-two-color,
	$border-radius: $select-two-border-radius,
	$padding: $select-two-padding,
) {
	@include type__14(
		$color: $color,
		$font-weight: $select-two-weight
	);
	-webkit-appearance: none;
	width: 100%;
	border-top: $select-two-border-top;
	border-bottom: $select-two-border-bottom;
	border-left: $select-two-border-left;
	border-right: $select-two-border-right;
	background-color: $select-two-background-color;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-two-arrow-background-size
	);
	background-position: $select-two-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $select-two-border-radius;
	padding: $select-two-padding;

	&:placeholder {
		@include type__14;
	}
}

// Select style used on category toolbars
@mixin select-style-three(
	$border: $select-three-border,
	$background-color: $select-three-background-color,
	$color: $select-three-color,
	$border-radius: $select-three-border-radius,
	$padding: $select-three-padding
) {
	@include type__14(
		$color: $color,
		$font-weight: $select-three-weight
	);
	-webkit-appearance: none;
	width: 100%;
	background-color: $background-color;
	border: $border;
	@include svg-background(
		$name: "arrow-down",
		$size: $select-three-arrow-background-size
	);
	background-position: $select-three-arrow-background-position; // background image used instead of pseudo to make the arrow clickable
	border-radius: $border-radius;
	padding: $padding;
}

input[type='checkbox']+label span,
input[type='radio']+label span  {
	@include type__14;
}

/* Set default form styles */

input {
	@include input-style-one;
}

select {
	@include select-style-one;
}

textarea {
	@include input-style-one;
}

// -------------------------------------------------
// Placeholder pseudo element
// -------------------------------------------------

::placeholder {
	@include type__16;
}

input.mage-error {
	@include svg-background(
		$name: error-icon,
		$size: rem(14)
	);
	background-position:  calc(100% - #{$twenty-gutter}) 50%;
	border: $field-error-border;
	box-shadow: $field-error-outline-shadow;
}
