/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to account area
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

body[class*="customer"],
body[class*="company"],
.quickorder-index-index,
.account,
.sales-guest-form,
.sales-guest-view,
.sales-guest-invoice {
	&.page-layout-2columns-left {
		.columns {
			width: 100%;
			max-width: $cms-container-width;
			margin: 0 auto;
			padding: rem(26) $small-gutter $fifty-gutter $small-gutter;

			@include min-screen($screen__m) {
				display: grid;
				grid-template-columns: rem(285) auto;
				gap: $gutter;
				padding: $forty-gutter 0 rem(100) 0;
			}

			@include min-screen($screen__l) {
				gap: $forty-gutter;
			}
		}

		.page-main {
			padding: 0;

			@include min-screen($screen__m) {
				padding: 0 $gutter;
			}

			.page-title-wrapper {
				padding: 0;
				width: 100%;

				.page-title {
					text-align: left;
					padding-bottom: rem(40);

					@include min-screen($screen__m) {
						text-align: left;
					}
				}

				.base {
					@extend %trailing-full-stop;
					font-weight: $font-weight-normal;
				}

				span {
					@include type__28(
						$line-height: rem(38)
					);
					text-transform: capitalize;

					@include min-screen($screen__m) {
						@include type__40(
							$line-height: rem(56)
						);
					}

					span {
						font-weight: $font-weight-semibold;
					}
				}

				.actions span {
					@include type__16;
				}
			}

			.main {
				padding: 0;

				@include min-screen($screen__m) {
					padding: 0;
				}
			}
		}

		.toolbar {
			padding-top: $small-gutter;

			@include min-screen($screen__m) {
				padding-top: $gutter;
			}
		}
	}

	.block-title {
		strong {
			@include type__20;
		}
	}
}

.sales-order-view {
	.block-requisition-list {
		position: relative;

		.requisition-list-action {
			margin: rem(10) auto rem(15) auto;
			width: fit-content;

			&.active {
				.items {
					display: block;
				}
			}

			.requisition-list-button {
				@include input-style-one(
					$padding: rem(6) rem(40) rem(6) rem(14)
				);
				@include svg-background("arrow-down");

				background-position: calc(100% - #{$fifteen-gutter}) 50%;
			}

			.items {
				border: $field-border;
				border-radius: $field-border-radius;
				display: none; // only show when block-requisition-list is active
				position: absolute;
				background-color: $dropdown-background-color;
				padding: $dropdown-outer-spacing;
				width: max-content;

				.list-items {
					display: flex;
					flex-direction: column;

					li {
						padding: 0 $medium-gutter;
						min-height: rem(52);

						&:hover,
						&:focus {
							background-color: $grey-light;
						}

						> span {
							@include type__16;
							cursor: pointer;
							border-bottom: 1px solid $grey-light;
							display: block;
							padding: rem(17) 0 rem(13);
							text-align: left;
							width: 100%;

							&:hover,
							&:focus {
								opacity: 1;
								background-color: transparent;
								border-bottom: 0;
							}

						}
					}

					&:last-child li:last-child {
						span {
							border-bottom: 0 !important; // Needed to override previous important declaration
						}
					}
				}
			}
		}

	}
}

.sales-order-print {
	.logo {
		padding: 0 $fifteen-gutter;
		width: rem(200);
	}
}
