/* -------------------------------------------------
 * -------------------------------------------------
 * General product listings. Mostly category pages
 *
 * Can also be in cross sells and upsells -
 * see _cms-products-list.scss for more styles for products grid in cms areas
 * Nearly everything should be defined elsewhere i.e.
 * how prices display, typography for product title, buttons etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product-listing {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 1px solid $mercury;
	padding: 0;

	@include min-screen($screen__s) {
		border: 0;
	}
}

.product-item-info {
	column-gap: $small-gutter;
	display: flex;
	flex-direction: column;
	padding: $small-gutter;

	@include min-screen($screen__s) {
		gap: rem(9);
		padding: 0;
	}

	.product-item-photo {
		aspect-ratio: 1 / 1;
		max-height: 100%;
		max-width: 100%;
	}

	.product-image-container {
		align-items: center;
		display: flex;
		height: 100%;
		width: 100% !important; /* Override inline styles */
		justify-content: center !important; /* Override inline styles */

		@include min-screen($screen__s) {
			padding: $small-gutter;
			border: 1px solid $mercury;
		}

		.product-image-photo {
			display: block;
			width: 100%;
		}
	}

	.product-item-name {
		order: 3;
		text-align: left;
		padding: 0;
		margin: 0;
		min-height: 0;

		@include min-screen($screen__s) {
			flex: 1 1 auto;
		}

		a {
			@include type__12(
				$font-weight: $font-weight-normal
			);

			@include min-screen($screen__s) {
				line-height: rem(24);
			}
		}
	}

	.product-item-details {
		align-items: flex-start;
		gap: $five-gutter;
	}

	.price-box {
		order: 1;
		margin: 0;
		padding: 0;

		.price-container {
			justify-content: flex-start;
			gap: rem(4);
		}

		.price-label,
		.price-display-label,
		.price {
			@include type__14(
				$color: $keppel,
				$line-height: rem(24)
			);
			margin: 0;
		}

		.tiered-price-display-label {
			color: $edward;
		}

		.price {
			font-weight: $font-weight-semibold;
		}

		.price-including-tax-container {
			order: 2;

			.price-display-label,
			.price {
				color: $edward;
			}
		}

		.price-excluding-tax-container {
			order: 1;
		}

		.retail-price-container {
			display: inline-block;

			.price,
			.price-display-label {
				color: $edward;
			}
		}

		.old-price {
			[class*='container'],
			.retail-price-container {
				display: none; //hide retail price on special price
				gap: rem(3);

				span {
					color: $edward;
					text-decoration: line-through;
				}
			}
		}

	}

	.price-container {
		align-items: center;
		display: flex;

		.price-rrp_price {
			order: 2;
			padding-left: rem(4);

			.retail-price-container {
				.price,
				.price-display-label {
					@include type__14(
						$color: $edward,
						$font-weight: $font-weight-normal
					);
				}

				.price {
					font-weight: $font-weight-semibold;
				}
			}
		}
	}

	div[class^="swatch-opt"] {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		order: 3;
		row-gap: rem(9);

		.swatch-attribute {
			&.diameter {
				order: 2;
			}

			.swatch-attribute-options {
				flex-flow: row wrap;
				gap: rem(4);

				.swatch-option {
					@include type__10(
						$font-weight: $font-weight-semibold
					);
					border: 1px solid $mercury;
					padding: rem(3);
					margin: 0;
					white-space: nowrap;

					&.image,
					&.color {
						border-radius: 100px;
						width: rem(15) !important; /* Override inline styles */
						height: rem(15) !important; /* Override inline styles */

						&.disabled,
						&[disabled] {
							&::before {
								left: rem(1);
								top: 40%;
								transform: rotate(-40deg);
								-webkit-transform: rotate(-40deg);
								-moz-transform: rotate(-40deg);
								-ms-transform: rotate(-40deg);
								-o-transform: rotate(-40deg);
							}
						}
					}

					// Ensure unavailability cross fits within circular swatches
					&.unavailable {
						&.image,
						&.color {
							&::before {
								border-radius: 100%;
							}
						}
					}
				}
			}
		}
	}

	.product-item-inner {
		display: block;
		margin: 0;
		order: 5;

		.stock {
			@include type__12(
				$color: $edward,
				$font-weight: $font-weight-semibold
			);
			align-items: center;
			display: flex;
			gap: rem(8);

			&:before {
				@include svg-background('info-icon');
				content: '';
				display: block;
				height: rem(14);
				width: rem(14);
			}
		}

		.available {
			color: $tuna;

			&:before {
				@include svg-background('success-icon');
			}
		}
	}
}

.category-cms.category-cms-bottom {
	max-width: $cms-container-width;
	margin: 0 auto;
}
