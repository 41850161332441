/* -------------------------------------------------
 * -------------------------------------------------
 * Products when in basket
 *
 * This should be reusable in chekout summary too.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.cart.items.table {
		border-top: $checkout-success-table-product-border;
		border-bottom: $checkout-success-table-product-border;
		margin-bottom: $fifteen-gutter;

		@include min-screen($screen__m) {
			border: $checkout-success-table-product-border;
			border-radius: $border-radius-m;
			margin-bottom: $medium-gutter;
		}

		.cart-items-qty-summary {
			display: block;
			margin: $medium-gutter $fifteen-gutter;
			@include type__16(
				$font-weight: $font-weight-semibold,
			);

			@include min-screen($screen__ml) {
				margin: $medium-gutter;
			}
		}

		.block-cart-item {
			border-bottom: none;

			&:first-of-type {
				.delivery-status-mobile {
					position: absolute;
					top: rem(-52);
					right: 0;
				}
			}

			&:last-child .cart-item-row {
				padding-bottom: 0;
				border-bottom: none;
			}

			.cart-item-row {
				display: grid;
				grid-template-columns: rem(90) 1fr 1fr;
				align-items: center;
				gap: $five-gutter;
				margin: $medium-gutter $small-gutter;
				padding-bottom: $medium-gutter;
				border-bottom: $checkout-success-table-product-border;

				@include min-screen($screen__ml) {
					grid-template-columns: rem(80) auto 1fr;
					margin: $medium-gutter;
				}

				.cart-item-col.photo {
					margin: 0;
					width: rem(80);
					order: 1;

					.product-item-photo {
						img.product-image-photo {
							width: rem(80);
							border: 1px solid $mercury;
							padding: 0;
						}
					}

					.cataloglabel {
						/* Display none as we want to remove labels on this page without disabling globally */
						display: none;
					}
				}

				.cart-item-col.details {
					display: block;
					grid-column: span 2 / span 2;
					order: 2;
					margin-left: $fifteen-gutter;

					@include min-screen($screen__ml) {
						grid-column: span 1 / span 1;
					}

					a {
						@include type__14(
							$font-weight: $font-weight-semibold
						);
						display: block;
					}

					.cart-item-info {
						margin: 0;
					}

					.product-item-name {
						display: flex;
						flex-direction: column;
						@include type__16(
							$font-weight: $font-weight-semibold
						);
					}

					.product-item-sku {
						@include type__12(
							$font-weight: $font-weight-normal
						);
					}
				}

				.cart-item-col.subtotal {
					@extend %cancel-visuallyhidden;
					grid-column: span 2 / span 2;
					padding: 0;
					margin: 0;
					overflow: hidden;
					order: 3;

					@include min-screen($screen__ml) {
						order: 5;
						grid-column: span 1 / span 1;
						text-align: right;
						padding-top: $twenty-gutter;
					}

					.label {
						@extend %visuallyhidden;
					}
				}

				.price-per-item {
					span {
						@include type__12(
							$color: $dusty-gray
						);
						text-transform: lowercase;
					}
				}

				.cart-item-col.qty {
					align-items: flex-end;
					display: flex;
					flex-direction: column;
					gap: rem(16);
					margin: 0;
					order: 4;

					@include min-screen($screen__ml) {
						order: 3;
						grid-column: span 1 / span 1;
					}

					.next-day-delivery-label {
						display: none; /* Not required on mobile */

						@include min-screen($screen__ml) {
							display: inline-block;
						}
					}

					.cart-product-actions {
						left: auto;
						right: 0;
						top: rem(51);
						z-index: 2;

						@include min-screen($screen__ml) {
							top: auto;
							bottom: rem(-42);
						}

						.button {
							background-color: $white;
							width: rem(112);
						}
					}

					.field {
						margin-left: auto;
					}

					.control {
						@include input-style-two(
							$padding: 0 $five-gutter
						);
						width: rem(112);
						padding: 0;
						justify-content: space-between;

						input {
							background-color: transparent;
							border: 0;
							border-radius: 0;
							color: $ebony-clay;
							padding: 0;

							&.qty-minus {
								padding-left: $ten-gutter;
							}

							&.qty-plus {
								padding-right: $ten-gutter;
							}

							&.qty {
								background-image: none;
								width: rem(60);

								& ~.mage-error {
									@include type__13($font-weight: $font-weight-semibold);
									align-items: center;
									background-color: $error-message-background;
									border-radius: $border-radius-m;
									bottom: 100%;
									display: flex;
									gap: $five-gutter;
									padding: $five-gutter;
									position: absolute;
									right: 0;
									z-index: 1;

									@include min-screen($screen__ml) {
										bottom: auto;
										top: 100%;
									}

									&:before {
										@include svg-background(
											$name: error,
											$size: contain
										);
										content: '';
										display: block;
										width: em(18);
										height: em(18);
									}

								}
							}
						}
					}
				}

				.item-options {
					.toggle {
						margin: 0;
					}

					li {
						@include type__12(
							$font-weight: $font-weight-semibold
						);
						display: flex;
						text-transform: none;

						&:before {
							font-weight: $font-weight-normal;
							white-space: nowrap;
							margin-right: rem(3);
						}
					}
				}

				.cart-item-action {
					display: flex;
					flex-flow: row-reverse;
					justify-content: flex-end;
					gap: $small-gutter;
					grid-column: span 3 / span 3;
					order: 5;

					@include min-screen($screen__ml) {
						order: 4;
						grid-column: span 2 / span 2;
						padding-top: $twenty-gutter;
					}

					.gift-options-cart-item:empty {
						display: none; // Hide if empty
					}

					.block-requisition-list {
						display: flex;
						align-items: center;
					}

					.requisition-list-button,
					.action-edit,
					.action-delete button {
						@include type__14;
						align-items: center;
						background-color: transparent;
						border: 0;
						display: flex;
						gap: rem(3);
						position: relative;

						&:before {
							@include svg-background(
								$name: edit,
								$size: contain
							);
							content: '';
							width: rem(16);
							height: rem(16);
							right: 0;
							display: block;
						}
					}

					/* Requisition list */
					.requisition-list-action {
						position: relative;
					}

					[data-target="dropdown"] {
						display: none; /* hide dropdown by default */

						&[aria-hidden="false"] {
							background-color: $white;
							box-shadow: 0 1px 16px rgba(23, 27, 63, 0.24);
							display: block;
							padding: 0 $ten-gutter;
							position: absolute;
							left: 0;
							right: auto;
							z-index: 1;
						}

						.item {
							padding: $ten-gutter 0;
							border-bottom: 1px solid $mercury;

							&:last-child {
								border-bottom: 0;
							}
						}

						span {
							cursor: pointer;
						}
					}

					.requisition-list-button {
						&:before {
							@include svg-background(bookmark);
						}

						&.active:before {
							@include svg-background(bookmark-filled)
						}
					}

					.action-delete {
						button {
							@extend %cancel-visuallyhidden;
							border: 0;
							background-color: transparent;

							&:hover,
							&:focus {
								opacity: 0.65;
							}

							&:before {
								@include svg-background(
									$name: delete,
									$size: contain
								);
							}
						}

						&:after {
							display: none; /* Icon not wanted on this theme */
						}
					}
				}
			}

			.delivery-status-mobile {
				grid-column: span 3 / span 3;
				text-align: right;

				@include min-screen($screen__ml) {
					display: none; /* Only required on mobile */
				}
			}
		}
	}

	/* Quick Order Failed Items */
	.block-cart-failed {
		.block-content {
			border-top: 1px solid $error-message-color;
			border-bottom: 1px solid $error-message-color;
			margin-bottom: $fifteen-gutter;

			@include min-screen($screen__m) {
				border: 1px solid $error-message-color;
				border-radius: $border-radius-m;
				margin-bottom: $medium-gutter;
			}
		}

		.block-title {
			@extend %trailing-full-stop;
			@include type__20(
				$font-weight: $font-weight-semibold
			);
			padding: 0 $fifteen-gutter $small-gutter;

			@include min-screen($screen__m) {
				@include type__24(
					$font-weight: $font-weight-semibold
				);
				padding: 0 0 rem(24);
			}
		}

		.cart-items-wrapper {
			.cart-item-row:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}

		.cart-item-row {
			display: grid;
			grid-template-columns: rem(90) 1fr 1fr;
			align-items: center;
			gap: $five-gutter;
			margin: $medium-gutter $small-gutter;
			padding-bottom: $medium-gutter;
			border-bottom: 1px solid $error-message-color;

			@include min-screen($screen__ml) {
				grid-template-columns: rem(80) auto 1fr;
				margin: $medium-gutter;
			}

			.product-image-wrapper {
				padding-bottom: 0 !important; // Override inline JS styles

				img.product-image-photo {
					width: rem(80);
					border: 1px solid $mercury;
					padding: 0;
				}
			}

			.product-item-details {
				display: block;
				grid-column: span 2 / span 2;
				margin-left: $fifteen-gutter;

				@include min-screen($screen__ml) {
					grid-column: span 1 / span 1;
				}

				.product-attribute-sku,
				.action.configure,
				.message.error {
					@include type__14;
				}

				.message.error {
					color: $error-message-color;
				}
			}

			.cart-item-col.qty {
				display: flex;
				justify-content: flex-end;
				margin: 0;
				grid-column: span 2 / span 2;

				@include min-screen($screen__ml) {
					grid-column: span 1 / span 1;
				}

				.control {
					@include input-style-two(
						$padding: 0 $five-gutter
					);
					width: rem(112);
					padding: 0;

					input {
						background-color: transparent;
						border: 0;
						border-radius: 0;
						color: $ebony-clay;
						padding: rem(12) 0;
						text-align: center;

						&[disabled] {
							cursor: not-allowed;
						}

						&.qty {
							@include type__15(
								$font-weight: $font-weight-semibold,
							);
						}
					}
				}
			}

			.actions-toolbar {
				display: flex;
				align-items: center;
				gap: $small-gutter;
				grid-column: span 3 / span 3;

				@include min-screen($screen__ml) {
					grid-column: span 2 / span 2;
					padding-top: $twenty-gutter;
				}

				// Reset button styles
				button {
					background: none;
					border: none;
					padding: 0;
				}

				.action-delete {
					display: flex;

					.label {
						@include icon__cross-white;

						&:after {
							margin: 0;
						}
					}
				}

				.action.configure {
					@include button__journey;
				}
			}

			.price-container {
				display: flex;

				@include min-screen($screen__m) {
					justify-content: flex-end;
					order: 2;
				}
			}
		}
	}
}
