// loyalty points - gift card (Payment page)
//--------------------------------

.checkout-container .opc-wrapper {
	.giftcardaccount,
	.discount-code,
	.userewardpoints {
		position: relative;
		padding: rem(17) 0;
		margin-bottom: $five-gutter;
		border-top: 1px solid $gallery;
		border-bottom: 1px solid $gallery;
		text-transform: uppercase;

		@include min-screen($screen__m) {
			margin-bottom: 0;
			padding: $checkout-promo-inner-spacing 0;
		}

		&.choice {
			background-color: $trust-builder-background;
		}

		.payment-option-title {
			margin: 0;
			cursor: pointer;

			.action {
				> span {
					@include type__14;
				}
			}
		}

		.payment-option-inner {
			width: 100%;

			label {
				display: none;
			}
		}

		.payment-option-content {
			margin-top: rem(14);

			.messages {
				.message {
					div {
						text-transform: none;
					}
				}

				.message-success {
					div { // no class or id
						margin-bottom: $ten-gutter;
						@include type__13(
							$color: $dell,
							$font-weight: $font-weight-semibold
						);
					}
				}
			}

			form {
				padding: 0;
				border-bottom: 0;
				display: flex;
				position: relative;

				.control {
					display: flex;
					flex-wrap: wrap;

					.input-text {
						@include input-style-one;
						flex: 1 1 100%;

						&.mage-error {
							margin: 0;
						}
					}
				}

				.actions-toolbar {
					position: absolute;
					right: em(15);
					top: em(9);

					button {
						display: flex;
						justify-content: center;
						padding: 0;
						position: relative;
						background: none;
						border: none;

						&:after {
							content: '';
							display: inline-block;
							position: relative;
							height: 15px;
							width: 15px;
							top: em(2);
							@include svg-background(
								$name: 'arrow-right-black',
								$size: contain
							);
						}

						span {
							@extend %visuallyhidden;
						}
					}
				}

				.secondary {
					display: none; // Not normally used but could be useful in the future
				}
			}
		}
	}

	.rewardpoints {
		margin-bottom: $checkout-promo-outer-spacing;

		.payment-option-title {
			@include type__29;
		}

		.payment-option-content {
			display: $checkout-promo-reward-point-content-display;

			@include min-screen($screen__l) {
				white-space: $checkout-promo-reward-point-content-white-space;
			}

			.payment-option-inner,
			.actions-toolbar {
				display: $checkout-promo-reward-point-toolbar-display;
				width: $checkout-promo-reward-point-width;
			}

			.payment-option-inner {
				width: $checkout-promo-reward-point-inner-width;
			}

			.actions-toolbar { 
				width:  100%;
				margin-left: $checkout-promo-reward-point-toolbar-left-margin;

				@include min-screen($screen__l) {
					width: $checkout-promo-reward-point-toolbar-width;
				}
				

				.primary {
					button {
						@include button__default;
						margin-top: $item-gutter;

						@include min-screen($screen__l) {
							margin-top: 0;
							display: $checkout-promo-reward-point-inner-button-display;
							margin-left: $checkout-promo-reward-point-inner-button-margin-left;
						}
					}
				}
			}
		}
	}

	.userewardpoints {
		.actions-toolbar {
			margin-top: $checkout-promo-outer-spacing;
		}
	}

	// Store Credit
	// -----------------------
	.customerbalance {
		padding-top: $gutter;

		.payment-option-title {
			@include type__14;
			text-transform: uppercase;
			padding-bottom: $fifteen-gutter;
		}

		.payment-option-inner {
			@include type__13;
			padding-bottom: $ten-gutter;
		}

		.action-use {
			@include button__default;
			@include type__21;
			width: 100%;
		}
	}
}
