/* -------------------------------------------------
 * -------------------------------------------------
 * Account Login Area
 *
 * Lots of nesting to override tpm theme
 * -------------------------------------------------
 * @author Fisheye
 */

form,
.form {
	fieldset,
	.fieldset {
		.field {
			margin-bottom: $ten-gutter;

			&.street {
				display: inline-block; /* Overwrite important in tpm theme */
			}

			.label {
				span {
					font-size: inherit;
					font-weight: inherit;
				}

				align-items: center;
			}
		}

		&.info.company {
			.field:not(:last-child) {
				margin-bottom: $twenty-gutter;
			}
		}
	}
}
