/* -------------------------------------------------
 * -------------------------------------------------
 * The minicart in the header.
 * -------------------------------------------------
 * @author Fisheye
 */

.page-header {
	.header.content {
		.minicart-wrapper {
			.block-minicart {
				@include min-screen($screen__xl) {
					right: 0;
					left: auto;
					transform: none;
				}

				&:before {
					@include min-screen($screen__m) {
						left: auto;
						right: type-scale(8);
					}

					@include min-screen($screen__xl) {
						right: 0;
					}
				}
			}

			.block-title {
				@include min-screen($screen__m) {
					padding-left: $forty-gutter;
					padding-right: $forty-gutter;
				}
				border: 0;

				.close {
					@include svg-background(
						$name: close-icon,
						$size: contain
					);
					width: rem(22);
					height: rem(22);
					right: 0;
					display: block;
					background-color: transparent;
					content: '';

					span {
						@extend %visuallyhidden; /* text not required */
					}
				}

				.text {
					span {
						@extend %trailing-full-stop;

						@include type__28(
							$font-weight: $font-weight-semibold
						);

						@include min-screen($screen__m) {
							@include type__40(
								$font-weight: $font-weight-semibold
							);
						}
					}
				}
			}

			.block-content {
				strong.subtitle.empty {
					margin: $ten-gutter $fifteen-gutter $twenty-gutter;

					@include min-screen($screen__m) {
						margin: 0 $forty-gutter $forty-gutter;
					}
				}

				.minicart.empty.text {
					margin: 0 $fifteen-gutter;

					@include min-screen($screen__m) {
						margin: 0 $forty-gutter;
					}

					& + .actions {
						.viewcart {
							display: block;
						}
					}
				}
			}

			.minicart-items-wrapper {
				padding: 0 $small-gutter;

				@include min-screen($screen__m) {
					padding: 0 $forty-gutter;
				}
			}

			.product-item {
				padding: 0;
				margin: 0;

				&:last-child {
					border-bottom: 1px solid $mercury;
				}

				.product {
					padding: $ten-gutter 0;
				}

				.product-image-photo {
					width: rem(80) !important; /* Override inline styles */
					height: rem(80) !important; /* Override inline styles */
					border: 1px solid $mercury;
				}

				.product-item-details {
					position: relative;
				}

				.product-item-name {
					@include type__14(
						$font-weight: $font-weight-semibold,
						$line-height: 2
					);
					padding-right: $forty-gutter;
					display: flex;
					flex-direction: column;

					.product-item-sku {
						@include type__12($font-weight: $font-weight-normal);
					}
				}

				.options {
					ul {
						li {
							min-height: 0;
							padding: 0;
							font-weight: $font-weight-semibold;

							&:before {
								font-weight: $font-weight-normal;
								white-space: nowrap;
								margin-right: rem(3);
							}
						}
					}
				}

				.actions {
					position: absolute;
					top: 0;
					right: 0;
					padding: 0;

					.delete {
						margin: 0;

						&:after {
							@include svg-background(
								$name: close-icon,
								$size: contain
							);
							background-color: transparent;
							width: rem(16);
							height: rem(16);
							padding: 0;
						}
					}
				}

				.product-item-pricing,
				.details-qty {
					margin-top: 0;
					padding-top: $five-gutter;
				}

				.details-qty {
					display: flex;
					top: 0;
					right: 0;

					.qty-input-wrapper {
						display: flex;
						align-items: center;
						position: relative;

						.cart-item-qty {
							@include input-style-two(
								$color: $ebony-clay,
							);
							font-weight: $font-weight-semibold;
							width: rem(112);
							float: none;
						}

						.qty-increment {
							@include type__20(
								$color: $ebony-clay,
								$font-weight: $font-weight-medium,
							);
							position: absolute;
							width: auto;
							height: $qty-input-height;
							background: none;
							border: none;

							&.qty-plus {
								right: 0;
							}
						}
					}

					.update-cart-item {
						position: initial;
						margin-right: $five-gutter;

						@include min-screen($screen__s) {
							margin-right: $fifteen-gutter;
						}

						span {
							@include type__12(
								$color: inherit,
								$font-weight: $font-weight-semibold
							);

							@include min-screen($screen__s) {
								@include type__16(
									$color: inherit,
									$font-weight: $font-weight-semibold
								);
							}
						}
					}
				}

				.price {
					@include type__16($font-weight: $font-weight-semibold);
				}

				.price-excluding-tax-label {
					color: $edward;
					margin-left: rem(6);
				}
			}

			.subtotal,
			.actions {
				background-color: transparent;

				@include min-screen($screen__m) {
					padding-left: $forty-gutter;
					padding-right: $forty-gutter;
				}
			}

			.subtotal {
				.label {
					@include type__18(
						$font-weight: $font-weight-normal
					);
				}

				.price-excluding-tax-label {
					color: $edward;
				}
			}

			.actions .secondary,
			.actions.primary {
				padding-bottom: $small-gutter;

				@include min-screen($screen__m) {
					padding-bottom: $forty-gutter;
				}

				.checkout,
				.viewcart {
					height: rem(48);
				}

				.viewcart {
					@include button__default(
						$padding: rem(13) $twenty-gutter rem(11)
					);
					margin-top: $small-gutter;
					margin-bottom: 0;

					span {
						border: 0;
					}
				}
			}
		}
	}
}
