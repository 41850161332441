/* ------------------------------------------------
 * ------------------------------------------------
 * Checkout Payment Methods
 * ------------------------------------------------
 */

.checkout-container .opc-wrapper {
	.checkout-payment-method .payment-methods.items .payment-group {
		// they are all pointing to the same container here, but need override
		.field.choice,
		.payment-method,
		.payment-method.field.choice {
			.payment-method-title {
				background-color: $silver;
				border-color: $mercury;
				margin-bottom: rem(16);
				padding: rem(16);

				&:last-child {
					margin-bottom: 0;
				}

				.credit-card-types,
				.payment-icon {
					margin-left: auto;
				}

				label > span,
				label[for='companycredit'] .company-credit span {
					@include type__16(
						$color: $ebony-clay,
						$font-weight: $font-weight-semibold,
					);

					flex: auto;
				}

				label[for='companycredit'] {
					flex: 0 1 auto;
					max-width: none;

					.company-credit {
						display: flex;
						flex-direction: column;

						span {
							align-self: flex-start;
						}

					}

					.available-credit {
						align-self: flex-start;
						margin: 0;

						span {
							@include type__12($line-height: rem(24));

							@include min-screen($screen__m) {
								@include type__14($line-height: rem(26));
							}
						}
					}
				}
			}

			[id="purchaseorder-form"] {
				padding-top: rem(16);
			}
		}

		.payment-method._active {
			.payment-method-title .available-credit span {
				@include type__14(
					$color: $ebony-clay,
				);
			}

			.payment-method-content:last-child {
				.field.choice {
					padding-top: 0;
				}
			}
			.payment-method-title .label span {
				@include type__16(
					$color: $ebony-clay,
					$font-weight: $font-weight-semibold,
				);
			}
		}
	}
}
