/* -------------------------------------------------
 * -------------------------------------------------
 *  Layout and general styles for order summary sidebar
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-index-index {
	// ---------------------------------------------------------------
	// Although technically not in the sidebar, this is what shows
	// the sidebar on mobile on click so should live here
	.opc-estimated-wrapper {
		position: relative;
		border: none;
		margin: 0;
		padding-bottom: rem(18);

		.estimated-block {
			background-color: $checkout-sidebar-mobile-button-background;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

		.estimated-label {
			display: flex;
			text-transform: uppercase;

			@include type__14(
				$font-weight: $font-weight-semibold
			);

			&:after {
				content: '';
				display: inline-block;
				position: relative;
				height: 16px;
				width: 16px;
				@include svg-background('arrow-down');
				background-size: contain;
				margin-left: $ten-gutter;
			}
		}

		&.sidebar-open {
			.estimated-label {
				&:after {
					@include svg-background('arrow-up');
				}
			}
		}

		.estimated-price {
			@include price;
		}

		.minicart-wrapper {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;

			button.action.showcart {
				// this button has the counter qty in and should take up the full
				// width of its area to make the whole thing clickable
				@extend %cancel-button;
				position: relative;
				width: 100%;
				height: 100%;
				background: transparent;
				border-color: transparent;
				border-style: none;

				.counter.qty {
					display: none; // remove quantity number on mobile Order Summary
				}
			}
		}
	}

	/* Main sidebar on desktop
 	 * -------------------------------------------------
 	 */
	.opc-sidebar {
		border-bottom: 1px solid $gallery;

		@include min-screen($screen__m) {
			border-bottom: 0;
		}

		.modal-header {
			display: flex;
			justify-content: flex-end;

			@include min-screen($screen__m) {
				display: none;
			}

			.action-close {
				display: flex;
				align-items: center;
				margin: $twenty-gutter $twenty-gutter 0;

				&:after {
					@include svg-background('cross-circle-grey');
					height: em(27);
					width: em(27);
				}

				span {
					@include type__13;
				}
			}
		}

		.opc-block-summary {
			background-color: $checkout-sidebar-background-color;

			// -----------------------------------
			// Title and edit basket button at top
			.title {
				display: none; // title within dropdown button
				text-transform: uppercase;
				float: left; // floats used for alignment as flex would not be suitable
				clear: left;
				border: none;

				@include min-screen($screen__m) {
					display: block;
					@include type__29;
				}

				strong {
					display: none;
				}
			}

			.button.action-edit {
				@include link__underline;
				@include type__13;
				display: block;
				float: right;
				margin-top: em(10); // vertically center 'edit basket' - cannot be done with flex as no container provided
			}

			.price {
				font-weight: $font-weight-extrabold;
			}

			.minicart-items-wrapper {
				border: 0;

				.product-item {
					border-bottom: 1px solid $gallery;
					border-top: 0;
					margin: 0;
					padding: 0;

					.product-item-inner {
						flex: 1;
					}

					.product {
						align-items: center;
						width: 100%;
						padding: $twenty-gutter 0;

						@include min-screen($screen__m) {
							padding: $forty-gutter 0;
						}

						.product-item-name,
						.options {
							@include type__13;
							display: block;
							margin-bottom: $ten-gutter;
						}

						.product-item-sku {
							@include type__11($font-weight: $font-weight-normal);
						}

						.options {
							padding: 0;
							font-weight: $font-weight-bold;
							margin-bottom: $twenty-gutter;

							> span {
								cursor: pointer;
							}

							.content {
								margin-top: $ten-gutter;

								.item-options {
									dd,
									dt {
										@include type__13;
									}

									dd {
										margin-bottom: $five-gutter;

										&:last-child {
											margin-bottom: 0;
										}
									}

									dt {
										font-weight: $font-weight-bold;
										margin-bottom: $five-gutter;
									}
								}
							}
						}

						.details-qty {
							span {
								@include type__13($font-weight: $font-weight-bold);
								text-transform: uppercase;
							}
						}
					}
				}
			}

			table.table-totals {
				display: block;
				padding: $fifteen-gutter;
				width: 100%;
				margin: $twenty-gutter 0;
				background-color: $wild-sand;
				display: block;
				padding: $twenty-gutter;

				@include min-screen($screen__m) {
					padding: 0;
					margin-top: $gutter;
					margin-bottom: 0;
				}

				@include min-screen($screen__m) {
					padding: $gutter;
				}

				tbody {
					display: flex;
					flex-direction: column;
					border-bottom: none;

					.totals {
						.mark,
						.title {
							text-transform: capitalize;
						}

						&:first-of-type {
							th,
							td {
								padding: 0;
							}
						}

						.discount {
							display: none; // remove duplicate discount coupon value
						}
					}

					.totals-tax-details {
						display: none;
					}

					.grand {
						order: 5;
					}
				}

				th {
					width: 100%;
					text-align: left;
					@include type__20(
						$font-weight: $font-weight-extrabold,
						$color: $dusty-gray
					);

					span,
					strong {
						@include type__20(
							$font-weight: $font-weight-extrabold,
							$color: $dusty-gray
						);
					}
				}

				th,
				td {
					line-height: 1.4; // custom in order to stop wrapped labels becoming squashed
					padding: $twenty-gutter 0 0 0;
				}

				td {
					text-align: right;
					min-width: rem(160);
				}

				// -----------------------------------
				// <div> between <tr>'s containing shipping address on step 2
				.shipping-information {
					width: 100%;
					margin-top: $fifteen-gutter;
					order: 3;

					.ship-to {
						display: block;
						width: 100%;
						padding: 0;

						.shipping-information-title {
							@extend %visuallyhidden;
						}

						.shipping-information-content {
							@include type__13;
							margin-bottom: 0;
						}

						button {
							@extend %cancel-button;
							@include link__underline;
							padding: 0;
							margin-top: rem(9);
							margin-bottom: rem(3);
							text-transform: capitalize;

							span {
								@include type__13;
							}
						}
					}
				}

				.totals-tax-summary {
					order: 4;
				}
			}

			.basket-delivery-upsell {
				display: none; // remove delivery upsell block (KO used, so cannot remove via layout)
			}
		}
	}
}
