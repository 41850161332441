/* -------------------------------------------------
 * -------------------------------------------------
 * Header Styling
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Header Content
// -------------------------------------------------

.page-header {
	.header-container { // Ensure header overlaps the nav overlay
		background-color: $header-mobile-background;
		z-index: 100;
		position: relative;

		@include min-screen($screen__m) {
			padding: 0 $gutter;
		}
	}

	/* Main header container
	 * -------------------------------------
	 * Logo | Hamburger | Header links | Cart drawer
	 */
	.header.content {
		padding: 0;
		display: grid;
		// 2nd column required to toggle central alignment when mobile nav opens
		// Ideally would use a :has(.nav-open) selector to change first column width, but not fully supported yet
		grid-template-columns: rem(48) rem(80) auto rem(128);
		grid-template-rows: rem(58) rem(66);
		column-gap: $item-gutter;
		justify-content: stretch;

		@include min-screen($screen__m) {
			background-color: $header-desktop-background;
			grid-template-columns: rem(200) auto rem(200);
			grid-template-rows: repeat(1, minmax(0, 1fr));
			column-gap: $gutter;
			max-width: $cms-container-width;
			margin: 0 auto;
		}

		@include min-screen($screen__l) {
			grid-template-columns: auto minmax(12.875rem, 45rem) auto;
		}

		/* Logo
		 * -------------------------------------
		 */
		.logo {
			grid-column: span 2;

			@include min-screen($screen__s) {
				grid-column: span 1;
			}

			a {
				width: auto;

				@include min-screen($screen__m) {
					text-align: left;
					margin-right: auto;
				}
			}

			img {
				height: rem(41);

				@include min-screen($screen__m) {
					height: rem(48);
				}
			}
		}

		/* Search
		 * -------------------------------------
		 */
		.block-search {
			width: 100%;
			grid-column: span 4 / span 4;

			@include min-screen($screen__m) {
				grid-column: span 1 / span 1;
				background-color: transparent;
			}

			.block-content,
			.mage-dropdown-dialog {
				max-width: none;
				position: static;
				align-items: center;
				width: 100%;
				display: flex !important; // override inline styles

				.search-toggle {
					display: none !important; // Dropdown not needed on this theme
				}

				.input-text {
					padding-left: $minisearch-input-padding-right; // Using existing vars not making more here
					height: rem(42);
					@include type__14;
				}
			}

			.minisearch {
				padding: 0 $small-gutter;
				width: 100%;

				@include min-screen($screen__m) {
					background-color: $white;
					padding: 0;
				}
			}

			.actions {
				right: auto;
				left: $small-gutter;

				@include min-screen($screen__m) {
					left: 0;
				}
			}

			.action {
				padding: 0 $ten-gutter;
			}
		}

		/* Header Links
		 * -------------------------------------
		 */
		.icons-block,
		.header.items {
			gap: $ten-gutter;
			margin: 0;

			@include min-screen($screen__m) {
				gap: $twenty-gutter;
			}

			@include min-screen($screen__ll) {
				gap: $gutter;
			}

			.header.items {
				> li {
					margin: 0;

					@include min-screen($screen__m) {
						position: relative;
					}
				}
			}

			/* Header Link Dropdowns
			 * -------------------------------------
			 * Heavy nesting to override tpm theme
			 */
			.ui-dialog-content {
				border-radius: $border-radius-s;
				box-shadow: $dropdown-shadow;
				left: 0;
				right: 0;
				top: $dropdown-top-position-mobile;

				@include min-screen($screen__m) {
					left: auto;
					top: $dropdown-top-position-desktop;
				}

				@include min-screen($screen__xl) {
					left: 50%;
					transform: translate(-50%, 0);
					right: auto;
				}

				&:before {
					@include min-screen($screen__m) {
						@include css-triangle(
							$triangle-size: 10px,
							$triangle-color: $dropdown-arrow-colour,
							$triangle-direction: bottom
						);
						position: absolute;
						top: rem(-19);
						left: calc(100% - #{rem(35)});
					}

					@include min-screen($screen__xl) {
						left: 50%;
						transform: translate(-50%, 0);
					}
				}

				[data-content-type="buttons"] {
					padding: 0;
				}

				ul {
					padding: rem(3) 0;
					background-color: $dropdown-background-color;

					li {
						padding: 0 $medium-gutter;
						min-height: rem(52);
						background-color: $dropdown-background-color;
						transition: background-color 100ms linear;
						cursor: default;
						display: flex !important; // override important on tpm theme
						order: initial; // Override tpm theme

						&:hover,
						&:focus {
							background-color: $grey-light;
						}

						a {
							@include type__16;
							background-color: transparent;
							border-top: 0;
							border-left: 0;
							border-right: 0;
							cursor: pointer;
							border-bottom: 1px solid $grey-light !important; /* override inline styles */
							display: block;
							padding: rem(17) 0 rem(13);
							text-align: left;
							width: 100%;

							&:hover,
							&:focus {
								opacity: 1;
								background-color: transparent;
								border-bottom: 0; /* override inline styles */
							}

							span {
								@include type__16(
									$font-weight: $font-weight-normal
								);
								padding: 0;
							}
						}
					}
					&:last-child li:last-child {
						a {
							border-bottom: 0 !important; // Needed to override previous important declaration
						}
					}
				}

				ul.header.links.trade {
					order: 2;
				}
			}

			/* Account specific */
			.customer-welcome {
				order: 1;

				.header.links {
					li {
						&.authorization-link {
							order: 1; // Cannot correctly be ordered with layout
							text-align: center;

							a {
								align-items: center;
								display: flex;
								gap: $ten-gutter;

								&[data-post] {
									justify-content: center;

									&:before {
										@include svg-background(
											'sign-out',
											contain
										);
										content: '';
										display: block;
										height: 15px;
										width: 17px;
									}
								}
							}
						}

						&:last-child {
							a {
								border-bottom: 1px solid $grey-light !important; // Required to override previous !important usage
							}
						}
					}
				}
			}

			/* Help center specific */
			.customer-help-center {
				order: 2;
			}

			.customer-help-center,
			.customer-welcome {
				display: flex;
			}

			svg {
				min-width: rem(30);

				@include min-screen($screen__m) {
					height: rem(26);
				}
			}

			.header-link-button,
			.customer-name,
			.showcart {
				gap: rem(5);
			}

			.header-link-title,
			.customer-title,
			.minicart-title {
				@extend %visuallyhidden; // not required on mobile

				@include min-screen($screen__m) {
					@include type__10(
						$line-height: 1
					);
					position: static;
					height: auto;
					width: auto;
					margin: 0;
					padding: 0;
				}
			}

			/* Cart drawer toggle
			 * -------------------------------------
			 */
			.minicart-wrapper {
				margin: 0;
				padding-right: rem(19);

				@include min-screen($screen__m) {
					padding: 0;
				}

				// counter
				.qty {
					top: $minicart-count-bubble-top;
					right: $minicart-count-bubble-right;

					@include min-screen($screen__m) {
						top: $minicart-count-bubble-top-desktop;
					}

					.counter-number {
						color: $minicart-count-color;
						font-size: 8px; // no point making a new type mixin as its only used once
						line-height: rem(9);
						padding-left: 2px;
						padding-right: 1px;
						min-width: $minicart-count-bubble-width; // Minimum badge width (i.e. 2 or less digits)
						width: auto; // Allow badge to display 3+ digit quantities
					}
				}
			}
		}

		/* Mobile nav btn
		 * -------------------------------------
		 */
		.navigation-toggle {
			background-color: $white;

			button {
				margin-left: 0;
				padding: 0 $small-gutter;
			}
		}
	}

	.nav-overlay {
		z-index: 90;
	}
}

.account-header {
	align-items: center;
	background-color: $keppel;
	display: flex;
	justify-content: center;
	padding: rem(16) 0;

	svg {
		height: rem(40);
	}
}
