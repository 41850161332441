/* -------------------------------------------------
 * -------------------------------------------------
 * Cart Promotion & help area
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	// -------------------------------------------------
	// Basket discount, giftcards, gift messages
	// -------------------------------------------------

	.block.discount,
	.block.giftcard,
	.gift-item-block {
		border-top: $basket-promo-border;
		border-bottom: $basket-promo-border;
		margin-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			margin-bottom: $gutter;
		}

		.title {
			position: relative;
			padding: $basket-promo-spacing-vertical $basket-promo-spacing-horizontal;
			cursor: pointer;

			strong {
				text-transform: uppercase;
				@include text-crop;
				@include type__15(
					$color: $basket-promo-color,
					$font-weight: $font-weight-semibold
				);
			}

			span {
				display: none;
			}

			&:after {
				content: "";
				position: absolute;
				width: $basket-promo-icon-size;
				height: $basket-promo-icon-size;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				@include svg-background(
					$name: arrow-down,
					$size: contain
				);
			}
		}

		.content {
			margin-bottom: $basket-promo-spacing-vertical;
		}

		&.active {
			margin-bottom: $basket-promo-spacing-vertical;

			.title {
				&:after {
					@include svg-background('arrow-up');
				}
			}
		}

		form[novalidate="novalidate"] {
			position: relative;

			&:after {
				content: '';
				background-color: rgba(255, 255, 255, 0.8);
				@extend %element-loader;
				width: auto;
				height: auto;
				background-size: 60px; // match size on checkout
				display: block;
				z-index: 1;
			}
		}

		.form.giftcard .fieldset,
		.fieldset.coupon {
			position: relative;

			.field {
				margin-bottom: $basket-promo-spacing-vertical;

				.control {
					flex: 0 1 100%;
				}
			}

			.label {
				@extend %visuallyhidden;
			}

			.actions-toolbar {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: rem(40);
				overflow: hidden;

				.primary {
					height: 100%;
				}

				.secondary {
					display: none;
				}
			}
		}

		.gift-options-title {
			@include type__16;
		}

		.gift-receipt,
		.gift-printed-card {
			margin-top: $basket-promo-choice-spacing;

			.label {
				width: 100%;
			}
		}

		.price-box {
			margin-top: $basket-promo-price-spacing;
		}

		.gift-options {
			.actions-toolbar {
				margin-top: $basket-promo-actions-vertical-spacing;

				.action {
					margin-right: $basket-promo-actions-horizontal-spacing;
				}
			}
		}

		input {
			width: $basket-promo-input-width;
			@include input-style-one;
		}

		button.primary {
			padding: 0;
			background: transparent;
			border: 0;
			height: 100%;
			display: block;
			margin: 0 auto;

			span {
				@extend %visuallyhidden;
			}

			&:after {
				@include svg-background('arrow-right-black');
				background-size: contain;
				content: '';
				display: inline-block;
				width: em(20);
				height: em(20);
				vertical-align: middle;
			}
		}
	}

	// -------------------------------------------------
	// Basket help block
	// -------------------------------------------------

	.help-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		border: 0;
		margin: 0;
		background-color: $basket-summary-background;
		padding: $gutter;

		@include min-screen($screen__m) {
			padding: $fifty-gutter;
		}

		h2 {
			width: 100%;

			@include min-screen($screen__m) {
				width: auto;
			}
		}

		h2,
		span,
		a { // no class - Need Help? title
			text-transform: uppercase;
			font-style: normal;
			@include type__16(
				$font-weight: $font-weight-extrabold,
				$color: $dusty-gray
			);

			@include min-screen($screen__m) {
				@include type__21(
					$font-weight: $font-weight-extrabold,
					$color: $dusty-gray
				);
			}
		}

		a {
			color: $charcoal-grey;
		}

		> span {
			margin: 0 $five-gutter;

			a {
				margin: 0 0 0 $five-gutter;
			}
		}

		.store-email {
			margin: 0;
		}

		br {
			&:first-of-type {
				display: none;
			}
		}
	}
}
