.checkout-footer {
	.checkout-footer-row-one {
		background-color: $footer-row-one-bg;
		display: flex;
		flex-direction: column;

		.help-title-row [data-content-type="text"],
		.contact-info-row {
			max-width: $cms-container-width;
		}

		.help-title-row {
			align-items: center;
			border-bottom: 1px solid $border-color-alt;
			justify-content: center !important; /* override inline styles from page builder */
			min-height: rem(166);

			@include min-screen(399px) {
				min-height: rem(145);
			}

			@include min-screen($screen__m) {
				min-height: rem(166);
			}

			@include min-screen(784px) {
				min-height: rem(146);
			}

			@include min-screen($screen__ml) {
				min-height: rem(248);
			}

			@include min-screen($screen__l) {
				min-height: rem(197);
			}

			[data-content-type="text"] {
				margin: 0;

				@include min-screen($screen__m) {
					margin: auto;
				}
			}

			h2 {
				@include type__32(
					$color: $white,
					$font-weight: $font-weight-semibold
				);
				@extend %trailing-question-mark;
				padding: $fifty-gutter $small-gutter;

				@include min-screen($screen__m) {
					@include type__40(
						$color: $white,
						$font-weight: $font-weight-semibold
					);
					padding: 0;
				}
			}
		}

		.contact-info-row {
			text-align: left;
			margin: 0 auto;

			.pagebuilder-column-group {
				.pagebuilder-column {
					flex: $footer-col-newsletter-flex;
					padding: $footer-row-one-padding-mobile;

					@include min-screen($screen__ml) {
						flex: $footer-col-newsletter-flex-desktop;
						padding: $footer-row-one-padding-desktop;
					}

					&:first-child {
						@include min-screen($screen__ml) {
							padding-left: $gutter;
						}
					}

					&:last-child {
						@include min-screen($screen__ml) {
							padding-right: $gutter;
						}
					}
				}
			}


			h2 {
				@include type__24(
					$color: $white,
					$font-weight: $font-weight-normal
				);
				padding-bottom: rem(16);

				@include min-screen($screen__ml) {
					@include type__36(
						$color: $white,
						$font-weight: $font-weight-normal
					);
					max-width: rem(300);
				}

				@include min-screen($screen__l) {
					max-width: none;
				}

				strong {
					@include type__24(
						$color: $white,
						$font-weight: $font-weight-semibold
					);
					@extend %trailing-full-stop;

					@include min-screen($screen__ml) {
						@include type__36(
							$color: $white,
							$font-weight: $font-weight-semibold
						);
					}
				}
			}

			p {
				@include type__16($color: $white);
				margin: 0;
				max-width: none;
			}

			a {
				@include type__16(
					$color: $keppel,
					$line-height: rem(28)
				);

				text-decoration: underline;
				white-space: nowrap;
			}

			.pagebuilder-column-group {
				.pagebuilder-column {
					&:first-child {
						border-bottom: 1px solid $border-color-alt;

						@include min-screen($screen__m) {
							border-bottom: 0;
							border-right: 1px solid $border-color-alt;
						}
					}
				}
			}
		}
	}

	.footer-row-two .footer-social-links {
		padding-top: 0;
	}
}
