/* -------------------------------------------------
 * -------------------------------------------------
 * General layout of main check out steps
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	// main list of steps in checkout
	.opc,
	.checkout-onepage-success {
		>li {
			.step-title {
				// repeated in checkout-payment-method to beat specificty
				@include type__22();
				text-transform: uppercase;
				border-bottom: none;
				padding: $checkout-step-title-mobile-padding;

				@include min-screen($screen__m) {
					@include type__29();
					padding: $checkout-step-title-padding;
				}

				&._required {
					&:after {
						content: '*';
						display: inline-block;
						vertical-align: top;
						padding: em(3) 0;
						@include type__18(
							$color: $radical-red,
							$font-weight: $font-weight-semibold
						)
					}
				}
			}

			&.checkout-shipping-address {
				> .step-title {
					@include min-screen($screen__m) {					
						padding-top: 0;
						padding-bottom: $checkout-field-spacing-mobile;
					}
				}
			}
		}

		button.primary {
			@include button__journey;
			width: 100%;
		}

		.action-update,
		.action-cancel {
			@include button__default;
			margin-right: $twenty-gutter;
		}
	}
}
