/* -------------------------------------------------
 * -------------------------------------------------
 * Configurable Product options
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product-info-addto,
.bundle-info {
	.swatch-opt,
	.field.configurable {
		background-color: $silver;
		border-bottom: 1px solid $mercury;
		border-top: 1px solid $mercury;
		order: 1;
		padding: $small-gutter 0;

		@include min-screen($screen__m) {
			border: 0;
			padding: $medium-gutter;
		}

		.swatch-attribute {
			align-items: center;
			column-gap: rem(3);
			row-gap: 0;
			margin: 0;

			@include min-screen($screen__m) {
				row-gap: $small-gutter;
			}

			.swatch-attribute-label {
				font-weight: $font-weight-normal;
			}

			.swatch-attribute-options {
				gap: rem(14);
			}

			.swatch-attribute-options {
				flex-wrap: nowrap;
				gap: rem(8);
				padding: rem(4) 0 rem(4) $small-gutter;
				overflow: auto;

				@include min-screen($screen__m) {
					flex-wrap: wrap;
					padding: 0;
					overflow: visible;
				}
			}

			.swatch-option {
				@include type__14(
					$font-weight: $font-weight-semibold
				);
				background-color: $white;
				border: 1px solid $mercury;
				border-radius: $border-radius-s;
				min-height: rem(42);
				white-space: nowrap;

				&.selected {
					background-color: $keppel;
					border-color: $keppel;
					color: $white;
				}

				&.colour,
				&.color {
					border-radius: 100%;
					height: rem(32);
					min-width: 0;
					min-height: 0;
					position: relative;
					width: rem(32);

					&:hover {
						box-shadow:
							0 0 0 2px $white,
							0 0 0 3px $keppel;
					}

					&.selected {
						border: 0;
						box-shadow:
							0 0 0 2px $white,
							0 0 0 3px $ebony-clay;
						overflow: visible;
					}
				}

				&.image {
					border-radius: 100%;
					height: rem(32) !important; // importants used to override inline styles specific to img uploads
					width: rem(32) !important; // importants used to override inline styles specific to img uploads
					min-width: 0 !important; // importants used to override TPM important
					min-height: 0;

					&:hover {
						box-shadow:
							0 0 0 2px $white,
							0 0 0 3px $keppel;
					}

					&.selected {
						border: 0;
						box-shadow:
							0 0 0 2px $white,
							0 0 0 3px $ebony-clay;
						overflow: visible;
					}
				}

				&.disabled,
				&[disabled] {
					&.color,
					&.image {
						&::before {
							left: rem(1);
							top: 45%;
							transform: rotate(-40deg);
							-webkit-transform: rotate(-40deg);
							-moz-transform: rotate(-40deg);
							-ms-transform: rotate(-40deg);
							-o-transform: rotate(-40deg);
						}
					}
				}

				// Ensure unavailability cross fits within circular swatches
				&.unavailable {
					&.image,
					&.color {
						&::before {
							border-radius: 100%;
						}
					}
				}
			}
		}

		.swatch-attribute-selected-option {
			font-weight: $font-weight-semibold;
			text-transform: capitalize;
		}
	}

	.field.configurable {
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			padding: $medium-gutter;
		}

		.label {
			display: block;

			&:first-child {
				padding-top: $small-gutter;

				@include min-screen($screen__m) {
					padding-top: 0;
				}
			}

			@include min-screen($screen__m) {
				padding-bottom: $small-gutter;
			}
		}

		.control {
			padding-bottom: $small-gutter;

			&:last-child {
				@include min-screen($screen__m) {
					padding-bottom: 0;
				}
			}
		}
	}

	.swatch-attribute-label {
		padding-left: $small-gutter;

		@include min-screen($screen__m) {
			padding-left: 0;
		}
	}

	.swatch-attribute-label,
	.swatch-attribute-selected-option,
	.field.configurable .label {
		@include type__14(
			$line-height: rem(26),
			$font-weight: $font-weight-semibold
		);
		text-transform: none;
	}

	.box-tocart {
		order: 4;

		.fieldset {
			> .block-requisition-list {
				display: none; /* Remove duplicate button as its used elsewhere. Unable to remove from shared template */
			}
		}
	}

	.product-options-wrapper {
		background-color: $white;
		border: 1px solid $ebony-clay;
		border-radius: $border-radius-s;
		padding: rem(13) $small-gutter;
		margin-bottom: $small-gutter;

		&.active {
			background-color: $grey-lightest;
			border-color: $keppel;

			h4 {
				color: $keppel;
				text-transform: none;

				&:after {
					@include svg-background(
						$name: 'minus-accent',
						$size: contain
					);
				}
			}

			[data-role="content"] {
				display: block;
			}
		}

		h4 {
			@include type__16(
				$font-weight: $font-weight-semibold
			);
			text-transform: none;

			&:after {
				@include svg-background(
					$name: 'plus',
					$size: contain
				);
				width: rem(12);
				height: rem(12);
			}
		}

		[data-role="content"] {
			display: none; /* Hidden on page load until active class is applied */
		}

		.fieldset {
			display: flex;
			flex-direction: column;
			gap: $medium-gutter;
			padding-top: $medium-gutter;

			.field {
				margin: 0;
				padding: 0;

				.label {
					@include type__14(
						$font-weight: $font-weight-semibold,
						$line-height: $medium-gutter
					)
				}

				textarea {
					border-width: 2px;
					border-radius: $border-radius-s;
					width: 100%;
				}

				.control {
					.character-counter-note {
						padding-top: rem(6);
						position: static;

						span {
							@include type__14(
								$color: $edward,
								$line-height: $medium-gutter
							);
						}
					}
				}
			}

			// Upload file and modal popup options files
			.file-container {
				display: flex;
				flex-direction: column;
				gap: $small-gutter;
				margin: 0;
				padding: 0;

				> .label {
					@include type__14(
						$font-weight: $font-weight-semibold
					);
				}

				> .field {
					align-items: center;
					background-color: $white;
					border: 2px dashed $mercury;
					border-radius: 2px;
					flex-direction: column;
					padding: $small-gutter;
					text-align: center;
					display: flex;
					gap: $five-gutter;

					.active {
						align-self: flex-start;
					}

					.upload-label {
						@include type__14(
							$color: $edward,
							$line-height: $medium-gutter
						);
						display: block;
						margin: 0;
						padding: $small-gutter 0;
						text-align: center;
					}

					.label:before,
					.choose-option,
					.select-new {
						@include type__12(
							$color: $keppel,
							$font-weight: $font-weight-semibold,
							$line-height: $medium-gutter
						);
						background-color: transparent;
						border-width: 1px;
						margin: 0 auto;
						min-width: 4.625rem;

						&:hover {
							background-color: $keppel;
							color: $white;

							&:before {
								background-color: $keppel;
								color: $white;
							}
						}
					}

					.control {
						text-align: center;

						.selected-option {
							.label {
								@extend %visuallyhidden; /* Label duplicated elswhere but useful for screen readers */
							}
						}

						.title {
							display: block;
							margin: 0;
							padding-bottom: $small-gutter;
						}

						.actions {
							display: block;
						}

						.field {
							padding: 0;
						}
					}

					.button {
						background-color: transparent;
					}
				}
			}

			.note {
				@include type__14(
					$color: $edward,
					$line-height: $medium-gutter
				);
				font-style: normal;
				margin: 0;

				strong {
					font-style: normal;
				}
			}

			.engraving {
				.field-wrapper {
					.label {
						order: 1;
					}

					.control.engraving-text:first-child {
						order: 2;
					}

					.same-engraving-container {
						order: 3;
					}
				}
			}

			.field-wrapper-info {
				width: 100%;
			}

			.title {
				@include type__13();
				font-width: 400;
			}
		}

		.product-custom-option {
			max-width: none;
		}
	}
}

// personalisation popup
// ----------------------
[class*="option"] {
	&.modal-popup {
		.product-option-modal-wrapper {
			.options-view {
				.options-list.nested {
					display: grid;
					gap: $small-gutter;
					grid-template-columns: repeat(2, minmax(0, 1fr));
					padding-bottom: 4rem;

					@include min-screen($screen__m) {
						gap: $gutter;
						grid-template-columns: repeat(3, minmax(0, 1fr));
					}
				}

				.label {
					margin-bottom: 0;
				}

				.choice {
					margin: 0;
					width: 100%;

					img {
						aspect-ratio: 1 / 1;
						object-fit: cover;
						object-position: center;
					}
				}

				.options-container {
					button.bottom {
						@include svg-background('arrow-down-brand-accent');
					}

					button.top {
						@include svg-background('arrow-up-brand-accent');
					}
				}
			}
		}

		.current-selection {
			display: grid;
			gap: $small-gutter;

			&:before {
				display: none;
			}

			@include min-screen($screen__s) {
				gap: $gutter;
				grid-template-columns: rem(80) auto;
			}

			.content-wrapper {
				padding: 0;
			}

			.image-wrapper {
				margin: 0 auto;
				width: rem(80);
			}

			button.primary {
				font-weight: $font-weight-semibold;

				span {
					font-weight: inherit;
					font-size: inherit;
				}
			}
		}
	}
}

.swatch-option-tooltip {
	background-color: $white;
	border: 0;
	box-shadow: 0 4px 45px rgba(0, 0, 0, 0.2);
	padding: 0;
	z-index: 10;

	.image,
	.title,
	.corner {
		margin: rem(3);

		&:empty {
			display: none; /* hide swatch if theres no content */
		}
	}
}
