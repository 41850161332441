/* -------------------------------------------------
 * -------------------------------------------------
 * Product upsells
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.block-products-list,
.block.related,
.block.upsell,
.block-viewed-products,
[data-appearance='fisheye_carousel'][data-content-type='products'] {
	border-top: 1px solid $mercury;

	.block-title {
		strong {
			@include type__24;
			padding: 0 0 $twenty-gutter $small-gutter;
			text-align: $upsells-title-alignment;
			text-transform: none;

			@include min-screen($screen__m) {
				padding: 0 0 rem(25) 0;
			}

			&:before {
				display: none; /* Remove unwanted text crop */
			}

			&:after {
				display: inline;
				content: '.'; /* unable to use %trailing-full-stop due to specificity */
				color: $keppel;
				margin: 0;
			}
		}
	}

	.product-items {
		display: block;

		&:not(.slick-initialized) {
			display: grid;
			gap: rem(16);
			grid-template-columns: repeat(2, minmax(0, 1fr));

			@include min-screen($screen__m) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}

			@include min-screen($screen__l) {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
		}

		&.slick-initialized {
			.slick-slide {
				margin: 0 $small-gutter / 2;
			}

			.slick-list {
				margin: 0 (-$small-gutter / 2); /* Slick docs say to use minus margins for spacing */
			}

			.slick-track {
				padding-left: $small-gutter;

				@include min-screen($screen__m) {
					padding-left: 0;
				}
			}

			.product-item {
				padding: 0;
			}
		}

		&:not(.slick-dotted) {
			width: 100%;

			.slick-list {
				min-width: 100%;
			}

			.slick-track {
				display: flex;
				min-width: 100%;

				&:before,
				&:after {
					display: none; /* not wanted if slick is not initialized */
				}
			}

			.slick-slide {
				flex: 0 1 auto;
			}

			.product {
				padding: 0;
			}
		}
	}

	.product-image-photo {
		width: auto;
	}

	.product-item-info {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-template-rows: auto 1fr;
		gap: rem(9);
		padding: 0;

		.price {
			color: $keppel;
		}
	}

	.product-image-container {
		padding: $small-gutter;
		border: 1px solid $mercury;
		border-radius: $border-radius-s;
	}

	.slick-dots {
		padding: 0 $small-gutter;
		margin: rem(18) 0 0 0;
		width: 100%;

		@include min-screen($screen__m) {
			display: none; /* Not required on desktop */
		}

		li {
			flex: 1 1 auto;
			padding: 0;

			button {
				border-radius: 0;
				height: rem(2);
				width: 100%;
			}
		}

		.slick-active {
			button {
				background-color: $ebony-clay;
			}
		}
	}
}
