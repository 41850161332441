/* -------------------------------------------------
 * Product Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Product Title
// -------------------------------------------------

$product-title-color: $ebony-clay; // Product title color
$product-title-weight: $font-weight-semibold; // Product title font weight

$product-custom-options-sample-image-width: rem(56);
$product-custom-options-sample-image-height: rem(56);
$product-custom-options-sample-image-width-desktop: rem(74);
$product-custom-options-sample-image-height-desktop: rem(74);

// -------------------------------------------------
// 2. Product Gallery
// -------------------------------------------------

$slider-dots: $gray;
$slider-dots-active: $keppel;
$thumbnail-active: 2px solid $keppel;

$product-tabs-padding-mobile: $forty-gutter $small-gutter rem(48) $small-gutter;
$product-tabs-padding-desktop: $sixty-gutter 0;
