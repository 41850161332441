/* -------------------------------------------------
 * Delivery address boxes when logged in
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	.checkout-shipping-address {
		border-bottom: 0;
		padding-bottom: rem(40);

		.shipping-address-items {
			flex-direction: column;
		}

		.shipping-address-item {
			background-color: $silver;
			border: 1px solid $mercury;
			border-radius: $border-radius-m;
			margin-bottom: rem(16);
			padding: 0;

			&.selected-item {
				border-color: $ebony-clay;

				.item-selection-container .delivery-title-edit-row span {
					@include type__14(
						$color: $ebony-clay,
						$font-weight: $font-weight-semibold,
						$line-height: rem(26),
					);
				}
			}

			.item-selection-container {
				@include type__14(
					$color: $ebony-clay,
				);

				cursor: pointer;
				padding: rem(16);

				.delivery-title-edit-row {
					display: flex;
					padding-bottom: rem(8);

					span {
						@include type__14(
							$color: $grey-medium,
							$font-weight: $font-weight-normal,
							$line-height: rem(26),
						);
					}

					.delivery-edit {
						cursor: pointer;
						display: block;
						padding: 0 rem(16);
						position: absolute;
						right: 0;

						span {
							@include type__14(
								$color: $keppel,
								$font-weight: $font-weight-semibold,
								$line-height: rem(26),
							);
						}
					}
				}
			}
		}

		.action-show-popup {
			width: 100%;

			@include button__default(
				$color: $ebony-clay,
				$border: 2px solid $ebony-clay,
			)
		}
	}

	.checkout-shipping-method {
		.table-checkout-shipping-method {
			tbody tr {
				border-style: none;
			}

			.row {
				background-color: $silver;
				border: 1px solid $mercury;
				border-radius: $border-radius-m;
				margin-bottom: rem(16);
				padding: rem(16);

				&.selected {
					background-color: $silver;
					border-color: $ebony-clay;

					.price,
					.col-method {
						color: $ebony-clay;
					}
				}

				&:last-child {
					border: 1px solid $mercury;
					margin-bottom: 0;
				}

				// painful override
				&.selected:last-child {
					border-color: $ebony-clay;
				}
			}

			.price-method-wrapper {
				width: 100%;

				.col-price {
					text-align: left;

					.price {
						@include type__16(
							$color: $ebony-clay,
							$font-weight: $font-weight-semibold,
							$line-height: rem(28),
						);
					}
				}

				.col-method {
					@include type__14(
						$color: $grey-medium,
						$font-weight: $font-weight-normal,
						$line-height: rem(26),
					);
				}
			}
		}
	}

	.plain-packaging-wrapper {
		.text-wrapper {
			align-self: baseline;
		}
	}
}
