/* -------------------------------------------------
 * -------------------------------------------------
 * Summary totals
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.cart-summary {
	padding: 0;
	background-color: transparent;

	.summary.title {
		@extend %cancel-visuallyhidden;
		@include type__16(
			$font-weight: $font-weight-semibold
		);
		display: block;
		padding-bottom: $small-gutter;
		margin-bottom: $ten-gutter;
		border-bottom: $checkout-success-table-product-border;
	}

	.totals.sub,
	.block.shipping,
	.cart-totals,
	.shipping strong,
	.grand strong {
		@include type__14;
		padding: $basket-summary-totals-vertical-spacing 0;
	}

	.price {
		@include type__14;
	}

	.checkout-methods-items {
		.primary {
			@include button__journey;
			margin: 0;
		}
	}

	.block.shipping {
		.title {
			&:after {
				right: 0;
			}
		}
	}

	[data-content-type="row"] {
		padding-top: $small-gutter;

		li {
			@include type__14(
				$line-height: rem(26),
			);
			display: flex;
			align-items: center;
			gap: rem(4);
			padding-bottom: $five-gutter;

			&:before {
				@include svg-background(
					$name: green-tick,
				);
				content: '';
				display: block;
				height: rem(14);
				width: rem(17);
			}
		}
	}

	.negotiable-quote.request {
		margin-top: $medium-gutter;

		.action {
			@include button__default;
			background-color: $white;
			width: 100%;
		}
	}
}

.form-quote-popup {
	.actions-toolbar {
		.action.save.primary {
			@include button__journey;
			margin: $medium-gutter 0 0 0;
			width: 100%;
		}

		.cancel-quote-request {
			@include button__default;
			margin: $medium-gutter 0;
			width: 100%;
		}
	}
}
