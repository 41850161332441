/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Content Styles
 * Used for banners and repeatable CMS content
 * --------------------------------------------------
 * @author Fisheye
 */

/* Set default pagebuilder btns
 * -------------------------------------
 * Only one btn design at the moment
 */

.pagebuilder-button-primary {
	@include button__journey;
	display: block;
}

.pagebuilder-button-secondary {
	@include button__default;
}

.pagebuilder-button-link {
	text-decoration: underline;
}

/* Pagebuilder slider
 * -------------------------------------
 */

.pagebuilder-slider {
	.pagebuilder-slide-wrapper {
		background-color: $ebony-clay; /* can be overwritten in the admin - fallback colour */
	}

	.pagebuilder-slide-image-mobile,
	.pagebuilder-slide-image-desktop {
		min-height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.pagebuilder-collage-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $small-gutter;
		padding: $gutter $small-gutter;

		@include min-screen($screen__ml) {
			padding: $medium-gutter;
			gap: $medium-gutter;
			height: 100%;
		}

		p {
			@include type__14(
				$color: $white,
				$line-height: 1.625rem
			);

			@include min-screen($screen__ml) {
				@include type__16(
					$color: $white
				);
			}
		}

		strong,
		h1 {
			@include type__32(
				$color: $white,
				$line-height: 2.625rem
			);

			@include min-screen($screen__ml) {
				@include type__56(
					$font-weight: $font-weight-semibold,
					$color: $white,
					$line-height: 4.5rem
				);
			}
		}

		.pagebuilder-slide-button {
			margin-top: auto;
		}
	}

	/* Rich text featuring image */
	[data-appearance='fisheye-collage-right'] {
		.pagebuilder-slide-wrapper {
			@include min-screen($screen__m) {
				display: grid;
				grid-template-columns: 1.5fr 1fr;
			}

			@include min-screen($screen__xl) {
				grid-template-columns: 2fr 1fr;
			}
		}

		.pagebuilder-collage-content {
			position: relative;

			p {
				max-width: 70%;
			}

			img {
				position: absolute;
				right: 0;
				bottom: 0;
				max-width: 40%;
				max-height: 95%;
				height: auto !important; /* override inline styles */
				width: auto !important; /* override inline styles */
				object-position: right bottom;

				@include min-screen($screen__ml) {
					max-width: 30%;
				}
			}
		}
	}
}

/* Text Slider - Used for global trustbuilder
 * -------------------------------------
 */

.cms-text-slider {
	padding: 0;

	.pagebuilder-column-group {
		max-width: $cms-container-width + rem(60);
		padding: $ten-gutter rem(8);

		@include min-screen($screen__m) {
			padding: $ten-gutter $gutter;
		}

		@include min-screen($screen__ml) {
			gap: $small-gutter;
			justify-content: space-between;
		}

		.pagebuilder-column {
			display: flex !important; /* override inline styles */
			flex-direction: row !important; /* override inline styles */
			width: auto !important; /* override inline styles */
			justify-content: center !important; /* override inline styles */
			align-items: center;
			gap: $ten-gutter;
			padding: 0;

			@include min-screen($screen__xl) {
				flex: 0 1 auto;
			}

			svg {
				height: rem(17);

				@include min-screen($screen__m) {
					height: rem(20);
				}
			}

			p {
				@include type__14;

				@include min-screen($screen__xl) {
					white-space: nowrap;
				}

				&:before {
					display: none; /* Icon not required on this theme */
				}

				strong {
					@include type__14(
						$font-weight: $font-weight-semibold
					);
				}
			}
		}
	}

	.slick-arrow {
		&.slick-prev,
		&.slick-next {
			display: none !important; /* not required and override tpm theme important */

			@include min-screen($screen__m) {
				display: none !important; /* not required and override tpm theme important */
			}
		}
	}
}

/* Cms Banners
 * -------------------------------------
 */

.cms-banners {
	.pagebuilder-banner-wrapper {
		flex-direction: column;
	}

	.pagebuilder-overlay {
		order: 2;
	}
}

/* Cms Brands
 * -------------------------------------
 */

.cms-brands {
	padding: $fifteen-gutter 0 $gutter 0;

	@include min-screen($screen__m) {
		padding-top: $medium-gutter;
	}

	.row-full-width-inner {
		max-width: $cms-container-width;
		margin: 0 auto;
		width: 100%;
		overflow: hidden;
	}

	li {
		margin-right: rem(11);
		max-width: rem(130);
		display: inline-flex !important; /* Override inline styles */

		@include min-screen($screen__m) {
			max-width: rem(140);
		}
	}

	a {
		border: 0;
		background-color: transparent;
		padding: 0;
		margin: 0;

		&:focus,
		&:hover {
			border: 0;
			background-color: transparent;
		}

		.pagebuilder-image-wrapper {
			background-color: $wild-sand;
			border-radius: $border-radius-s;
			border: 1px solid $mercury;
			overflow: hidden;
		}

		span {
			@include type__18($font-weight: $font-weight-semibold);
			text-align: left;
			text-transform: none;
			margin-top: $small-gutter;
			white-space: normal;
		}
	}

	img {
		aspect-ratio: 1 / 1;
		object-fit: cover;
	}

	.scroll-navigation-wrapper {
		padding-left: $small-gutter;

		@include min-screen($screen__m) {
			padding-left: $gutter;
		}

		@include min-screen($screen__cms-width) {
			padding-left: 0;
		}

		.next-arrow,
		.previous-arrow {
			display: none; // Always hidden on mobile

			@include min-screen($screen__m) {
				@include svg-background(
					$name: button-slider-arrow,
					$size: rem(48)
				);
				background-color: transparent;
				display: block;
				height: rem(140); /* Height of images */
				margin-top: 0;
				right: 0;
				width: rem(48);

				&:before {
					display: none; // Icon not wanted
				}
			}

		}

		.previous-arrow {
			transform: rotate(180deg);
			left: 0;
			right: auto;
		}
	}
}

/* Homepage popular categories slider
 * -------------------------------------
 */

[id="category-slider"] {
	padding: $gutter 0 rem(33) 0;

	@include min-screen($screen__m) {
		padding: rem(36) 0 $gutter 0;
	}

	[data-element="inner"] {
		max-width: $cms-container-width;
		margin: 0 auto;

		@include min-screen($screen__m) {
			display: grid !important; /* Override inline styles */
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		/* title */
		> [data-content-type="text"] {
			order: 1;

			> p {
				@include type__28;
				text-align: left;
				padding: 0 $small-gutter rem(17) $small-gutter;

				@include min-screen($screen__m) {
					@include type__40;
					padding: 0 $gutter rem(22) $gutter;
				}

				@include min-screen($screen__cms-width) {
					padding-left: 0;
					padding-right: 0;
				}

				strong {
					@extend %trailing-full-stop;
					font-weight: $font-weight-semibold;

					@include min-screen($screen__m) {
						display: block;
					}
				}
			}
		}

		/* View all categories link */
		> [data-content-type="buttons"] {
			order: 3;
			width: 100%;
			padding-bottom: 0;
			padding: $medium-gutter $small-gutter 0 $small-gutter;

			@include min-screen($screen__m) {
				order: 2;
				align-self: flex-end;
				text-align: right;
				padding: $medium-gutter $gutter rem(27) $gutter;
			}

			@include min-screen($screen__cms-width) {
				padding-left: 0;
				padding-right: 0;
			}

			.pagebuilder-button-link {
				@include type__16(
					$color: $keppel
				);
			}
		}

		/* Slider styles */
		.pagebuilder-column-group {
			order: 2;

			@include min-screen($screen__m) {
				order: 3;
				grid-column: span 2 / span 2;
			}

			.slick-list {
				padding-left: $small-gutter;

				@include min-screen($screen__m) {
					padding-left: $gutter;
				}

				@include min-screen($screen__cms-width) {
					padding-left: 0;
				}
			}
		}

		.pagebuilder-column {
			padding: 0 $small-gutter / 2 0 0;

			@include min-screen($screen__m) {
				padding: 0 $small-gutter 0 0;
			}

			p {
				@include type__14(
					$line-height: rem(26)
				);
				display: block;
			}

			strong {
				@include type__18(
					$font-weight: $font-weight-semibold
				);
				padding-bottom: rem(8);
				text-align: left;
				margin-top: $small-gutter;
				text-transform: none;
				display: block;
			}
		}

		[data-content-type="image"] {
			a {
				background-color: $wild-sand;
				border-radius: $border-radius-s;
				border: 1px solid $mercury;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
			}

			~ [data-content-type="buttons"] {
				padding-top: $small-gutter;
			}
		}
	}
}

/* Full width banner
 * -------------------------------------
 * Found on homepage
 */

.full-width-banner {
	padding: $gutter 0;

	a {
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
	.pagebuilder-banner-wrapper {
		position: relative;

		.pagebuilder-overlay {
			position: absolute;
			inset: 0;
			padding: $medium-gutter $small-gutter;

			@include min-screen($screen__m) {
				padding: rem(60) $gutter;
			}
		}

		.pagebuilder-poster-content {
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			width: 100%;
			height: 100%;
			max-width: $cms-container-width;
			margin: 0 auto;
			gap: $medium-gutter;

			@include min-screen($screen__m) {
				gap: rem(40);
			}
		}

		h4 {
			@include type__32(
				$color: $white,
			    $font-weight: $font-weight-normal
			);

			@include min-screen($screen__m) {
				@include type__56(
					$color: $white,
				);
			}

			strong {
				font-weight: $font-weight-semibold;
				@extend %trailing-full-stop;
			}
		}

		p {
			@include type__18(
				$color: $white,
				$font-weight: $font-weight-normal
			);
			padding-top: $ten-gutter;
		}

		.pagebuilder-banner-button {
			margin-right: auto;
		}
	}
}

/* SEO text block
 * -------------------------------------
 * Found on homepage
 */

.cms-seo-block {
	padding: $gutter $small-gutter;

	@include min-screen($screen__m) {
		padding: $gutter $gutter rem(83) $gutter;
	}

	.pagebuilder-column-group {
		max-width: $cms-container-width;
		margin: 0 auto;

		@include min-screen($screen__m) {
			display: grid !important; // ovrride inline styles
			grid-template-columns: repeat(2, minmax(0, 1fr));
			column-gap: $gutter;
		}

		@include min-screen($screen__ll) {
			grid-template-columns: rem(260) 2fr 2fr;
			column-gap: rem(80);
		}
	}

	.pagebuilder-column {
		gap: $small-gutter;
		width: auto !important; // override inline styles

		p {
			@include type__16(
				$line-height: rem(28)
			);
			padding-bottom: $gutter;
		}

		&:first-child {
			@include min-screen($screen__m) {
				grid-column: span 2 / span 2;
				grid-row-start: 1;
			}

			@include min-screen($screen__ll) {
				grid-column: span 1 / span 1;
				border-bottom: 2px solid $keppel;
			}

			p {
				@include type__28;
				padding-bottom: $forty-gutter;

				@include min-screen($screen__m) {
					@include type__40;
				}

				@include min-screen($screen__ll) {
					padding-bottom: $gutter;
				}

				span {
					text-underline-offset: rem(20);
					text-decoration-color: $keppel !important; /* override inline styles */

					@include min-screen($screen__ll) {
						text-decoration: none !important; /* override inline styles */
					}
				}
			}

			br {
				display: none;
			}

			strong {
				font-weight: $font-weight-semibold;
				@extend %trailing-full-stop;

				@include min-screen($screen__ll) {
					display: block;
				}
			}
		}

		&:nth-child(2) {
			@include min-screen($screen__ll) {
				grid-column-start: 2;
				grid-row-start: 2;
			}
		}

		&:last-child {
			@include min-screen($screen__ll) {
				grid-column-start: 3;
				grid-row-start: 2;
			}

			 p {
				 padding-bottom: 0;
			 }
		}
	}
}
