/* -------------------------------------------------
 * -------------------------------------------------
 * Forms for checkout only
 * Most of this is should be definied in forms and inputs etc already
 *
 * TODO: Check this against Rokit code
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-container,
.page-layout-checkout._has-modal {
	form {
		max-width: 100%;

		&.form-login {
			border-bottom: $checkout-methods-border;
			padding-bottom: $checkout-methods-outer-spacing;

			.fieldset {
				.fieldset {
					margin-top: $fifteen-gutter;

					@include min-screen($screen__m) {
						margin-top: $twenty-gutter;
					}

					.label {
						@extend %cancel-visuallyhidden;
						margin: 0 0 $ten-gutter;
						flex: 1;
						
						&:after {
							content: '*';
							display: inline-block;
							vertical-align: top;
							padding: em(3) 0;
							@include type__18(
								$color: $radical-red,
								$font-weight: $font-weight-semibold
							)
						}

						@include min-screen($screen__m) {
							padding-top: 0;
						}
					}
				}

				.field {
					margin-bottom: 0;

					.label {
						@extend %visuallyhidden;
					}

					.note {
						padding: 0;
						margin-top: $ten-gutter;
						@include type__13;

						&:before {
							margin: 0;
						}
					}

					.control {
						margin-bottom: 0;
					}
				}

				.actions-toolbar {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-top: $checkout-field-spacing-mobile;

					@include min-screen($screen__ml) {
						flex-wrap: nowrap;
						margin-top: $checkout-field-spacing;
					}

					.primary,
					.secondary {
						flex: 1 1 100%;

						@include min-screen($screen__ml) {
							flex: auto;
						}
					}

					.secondary {
						margin-top: $fifteen-gutter;

						@include min-screen($screen__ml) {
							margin-top: 0;
							text-align: right;
						}
					}

					button.primary {
						@include button__journey;

						@include min-screen($screen__ml) {
							width: rem(240);
						}
					}

					.action.remind {
						span {
							@include link__underline;
							@include type__13($font-weight: $font-weight-normal);
						}
					}
				}
			}

			label[for="customer-email"] {
				display: none;
			}
		}

		.fieldset {
			.g-recaptcha {
				margin-top: $twenty-gutter;
			}

			.field {
				margin-bottom: 0;

				.label {
					> span {
						@include type__14($font-weight: $font-weight-bold);	
					}
				}

				.control {
					margin-bottom: $checkout-field-spacing-mobile;
				}

				label,
				.label {
					span {
						@include type__14;
					}
				}

				.label,
				.control {
					width: 100%;

					input,
					select {
						width: 100%;
						@include type__13;
					}

					select {
						@include input-style-one;
					}
				}

				&.required,
				&._required {
					.label {
						span {
							span {
								&:after {
									display: none;
								}
							}
						}
					}
				}

				&.telephone {
					padding-top: rem(16);
					border-top: $checkout-methods-border;

					@include min-screen($screen__m) {
						padding-top: rem(26);
						margin-top: $ten-gutter;
					}

					.label {
						&:after {
							display: none; // remove asterisk
						}
					}

					.control {
						margin-bottom: 0;
					}
				}

				&.street {
					margin-bottom: 0;

					.label {
						> span {
							&:after {
								content: '*';
								display: inline-block;
								vertical-align: top;
								padding: em(3) 0;
								@include type__18(
									$color: $radical-red,
									$font-weight: $font-weight-semibold
								)
							}
						}
					}

					label {
						display: none;
					}

					legend.label {
						@extend %cancel-visuallyhidden;
						margin-left: 0;
						margin-bottom: rem(8);

						> span {
							&:after {
								padding-top: 0;
							}
						}

						&:after {
							padding-top: 0;
							display: none;
						}
					}

					> .control {
						margin-bottom: $checkout-field-spacing-mobile;

						&:last-child {
							margin-bottom: 0;
						}

						@include min-screen($screen__m) {
							margin-bottom: $checkout-field-spacing;
						}
					}
				}
			}

			div[name="shippingAddress.postcode"],
			div[name="billingAddressbraintree.postcode"] { // no unique class or id
				.control {
					display: flex;
					flex-wrap: wrap;

					input { // no class or id
						flex: 1 1 55%;

						@include min-screen($screen__ml) {
							flex: 1 1 60%;
						}
					}

					.find-address {
						display: flex;
						justify-content: center;
						border: 0;
						@include button__address;
						@include type__14;
						width: auto;
						flex: auto;
						margin-left: $checkout-delivery-address-button-spacing;
					}

					.label {
						margin-bottom: 0;
						margin-top: $fifteen-gutter;

						> span {
							@include link__underline;
							@include type__13;
							text-transform: capitalize;
							cursor: pointer;
							width: auto;
						}

						&:before {
							display: none;
						}
					}

					select {
						margin-top: $twenty-gutter;
					}

					[id="getaddress_error_message"] { // no class
						@include text-crop;
						@include type__13(
							$color: $form-notices-color,
							$font-weight: $font-weight-semibold
						);
						margin-top: $item-gutter * 1.5;
					}
				}
			}
		}
	}

	.methods-shipping {
		padding-bottom: 0;
		border: 0;

		.actions-toolbar {
			margin-top: $twenty-gutter;

			@include min-screen($screen__m) {
				margin-top: $gutter;
			}
		}
	}
}

.checkout-container {
	.braintree-apple-pay-button {
		-webkit-appearance: -apple-pay-button;
		-apple-pay-button-type: plain;
		display: block;
		width: 200px;
		min-height: 40px;
		border: 1px solid black;
		background-image: -webkit-named-image(apple-pay-logo-black);
		background-size: 100% calc(62%);
		background-repeat: no-repeat;
		background-color: black;
		background-position: 50% 50%;
		border-radius: 5px;
		padding: 2px;
		margin: 20px auto;
		transition: background-color 0.15s;
		cursor: pointer;
	}
}
