/* -------------------------------------------------
 * -------------------------------------------------
 * Product layout
 * -------------------------------------------------
 * @author Fisheye
 */

.catalog-product-view {
	.columns {
		@include min-screen($screen__m) {
			padding: rem(37) $gutter 0 $gutter;
		}
	}

	.main {
		border: 0;
		display: block;
		margin: 0 auto;
		max-width: $cms-container-width;
		padding: 0;
		width: 100%;

		.product-main-container {
			display: grid;
			grid-template-columns: repeat(1, minmax(0, 1fr));

			@include min-screen($screen__m) {
				column-gap: $gutter;
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@include min-screen($screen__cms-width) {
				column-gap: rem(55);
				grid-template-columns: auto rem(590);
			}
		}
	}

	.product-info-main {
		@include min-screen($screen__m) {
			padding: 0;
		}
	}

	.product-aside-container {
		margin: 0 auto;
		max-width: $cms-container-width;
		width: 100%;
	}
}
