// ------------------------------------------------------------
// ------------------------------------------------------------
// Recent orders table on dashboard and in recent orders (obvs)
// Stored Payment Methods Table
// ------------------------------------------------------------
// ------------------------------------------------------------
.account,
.sales-guest-view,
.sales-guest-invoice,
.sales-guest-shipment,
.sales-guest-creditmemo {
	table {
		margin-bottom: $twenty-gutter * 2.5;

		.col {
			width: 100%;
			vertical-align: middle;

			@include min-screen($account-table-desktop-breakpoint) {
				width: percentage(1/6);

				&.name {
					width: 30%;
				}

				&.sku {
					width: 25%;
				}

				&.price {
					width: 10%;
				}

				&.qty {
					width: 15%;
				}

				&.subtotal {
					width: 20%;
				}
			}
		}

		thead {
			background: $account-table-head-color;

			@include min-screen($account-table-desktop-breakpoint) {
				border-bottom: 1px solid $wild-sand;
			}

			th {
				padding: $item-gutter 0;
				display: block;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-cell;
				}
			}
		}


		tbody {
			tr {
				width: 100%;
			}

			td {
				padding: 0;
				margin: 0;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-cell;
					padding: $twenty-gutter 0;
				}

				&.actions {
					padding-bottom: $twenty-gutter;

					@include min-screen($account-table-desktop-breakpoint) {
						padding-bottom: 0;
						text-align: right;
					}

					.action {
						display: block;
						padding-left: $twenty-gutter;

						span {
							@include link__underline;
						}
					}

					.view {
						color: $body-font-color;

						&:after {
							display: none;
						}
					}

					.action.delete {
						&:hover {
							text-decoration: none;
						}
					}

					.action.order {
						display: none;
					}
				}

				&:before {
					margin-top: auto;
					margin-bottom: auto;
				}
			}
		}

		tfoot {
			tr {
				th,
				td.mark {
					// .mark is for giftcards
					text-align: right;
					font-weight: $font-weight-bold;
					padding: $twenty-gutter $item-gutter / 2 0 0;
				}

				td {
					// 4px is a hack as th and td don't align but can't see where th has bottom padding :(
					padding: $twenty-gutter 0 4px 0;
					text-align: right;
				}
			}
		}

		// Specifically orders tables
		&.orders-recent,
		&.table-order-items,
		&.table-credit-cards,
		&.items-ordered,
		&.table.data-grid.data.table,
		&.data-grid {

			// hide the table head completely on mobile as out of order visually, not needed and doesn't describe orders
			thead {
				display: none;
				background: $account-order-table-background-color;

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-header-group; // forces thead to be full width
					tr {
						// forces each row inside to be full width
						display: table-row;

						th,
						th span {
							@include type__14($font-weight: $font-weight-bold);
						}
					}
				}
			}

			tbody {
				tr {
					padding: $mobile-order-table-padding;
					border-bottom: 1px solid $wild-sand;

					&:last-child {
						border-bottom: 0;

						@include min-screen($screen__m) {
							border-bottom: 1px solid $wild-sand;
						}
					}

					@include min-screen($screen__m) {
						padding: $order-table-padding;
					}

					td,
					td > div {
						@include type__14($font-weight: $font-weight-normal);
						text-align: left;

						.price,
						.title,
						.content {
							@include type__14($font-weight: $font-weight-normal);
						}
					}
				}
			}

			td,
			.col,
			.data-grid-th {
				padding: $mobile-order-table-column-padding;

				@include min-screen($account-table-desktop-breakpoint) {
					padding: $account-table-inner-spacing;
					padding-left: $account-col-side-padding;
					padding-right: $account-col-side-padding;
				}

				.item-options {
					margin-top: $ten-gutter;

					dt,
					dd {
						@include type__14($font-weight: $font-weight-normal);
					}

					dt {
						font-weight: $font-weight-semibold;
						margin-bottom: $five-gutter;
					}

					dd {
						margin-bottom: $ten-gutter;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}


		&.orders-recent,
		&.table-order-items,
		&.table-credit-cards,
		&.data-grid {
			tbody {

				@include min-screen($account-table-desktop-breakpoint) {
					display: table-row-group; // forces tbody back to fullwidth
				}

				tr {
					flex: 0 1 50%;

					&:first-child {
						padding-top: 0;
						//remove padding on first child as padding is correclty applied to parent
					}

					@include min-screen($account-table-desktop-breakpoint) {
						display: table-row;
						border-bottom: 1px solid $wild-sand;
					}
				}
			}
		}

		&.table-order-items {
			margin-top: auto;
			flex-direction: column;

			tbody {
				td {
					&.col.actions {
						a {
							margin-right: auto;
							padding-left: 0;
						}

						&:before {
							display: none;
						}
					}
				}
			}

			tfoot {
				display: table-footer-group;
				align-self: flex-end;

				tr {
					display: table-row;

					th,
					td {
						display: table-cell;
					}

					td {
						padding-left: $twenty-gutter;
					}
				}
			}

			.col.name {
				flex-wrap: wrap;

				strong {
					flex: 0 1 50%;
				}

				.item-options {
					margin-left: 50%;

					@include min-screen($screen__ml) {
						margin-left: 0;
					}
				}
			}
		}

		&.items-ordered {
			tbody {
				border-bottom: none;

				tr {
					display: block;
					border-bottom: none;

					td {
						display: block;
					}
				}

				@include min-screen($screen__s) {
					border-bottom: 1px dashed $border-color;

					&:last-child {
						border-bottom: 1px solid $border-color;
					}

					tr {
						display: table-row;
						border-bottom: 1px dashed $border-color;

						td {
							display: table-cell;
						}
					}
				}
			}

			tfoot {
				border-top: 1px dashed $border-color;
				border-bottom: 1px solid $border-color;
				width: 100%;
				display: table;

				@include min-screen($screen__s) {
					display: table-footer-group;
				}
			}

			.col {
				&.name {
					padding-top: $item-gutter;
				}

				&.subtotal {
					padding-bottom: $item-gutter;
				}

				@include min-screen($account-table-desktop-breakpoint) {

					&.name {
						padding-top: $twenty-gutter;
					}

					&.subtotal {
						padding-bottom: $twenty-gutter;
						text-align: right;
					}
				}
			}
		}

		&.data-grid.table {
			tr:first-child {
				padding-top: 0;
			}

			td,
			td.data-grid-actions-cell {
				width: 100%;
				text-align: right;

				@include min-screen($screen__ml) {
					width: auto;
					text-align: left;
				}
			}

			tr.data-grid-tr-no-data {
				border-bottom: 1px solid $border-color;

				td {
					text-align: center;
					display: block;

					@include min-screen(992px) {
						display: table-cell;
					}

					&:before {
						display: none;
					}
				}
			}
		}

		&.table-credit-cards {
			tbody {
				tr {
					td {
						padding-left: 0;

						&.paypal-account {
							display: flex;
							align-items: center;

							@include min-screen($account-table-desktop-breakpoint) {
								display: table-cell;
							}
						}
					}

					td.col.actions {
						button.action.delete {
							@extend %cancel-button;
							padding: 0;

							&:hover {
								background: none;
								border: 0;
							}

							span {
								@include type__16(
									$color: $black,
									$font-weight: $font-weight-bold
								);
							}
						}
					}
				}
			}
		}
	}

	/*
	* general responsive table layout
	* ------------------------------------------
	* apply flex to the table then set all other table elements to display block
	* which forces them underneath each other. Then at $screen__m set them back to default
	* the thead is then removed on mobile and replaced with a :before appended to the td.
	*/

	table {
		display: flex;
		flex-wrap: wrap;

		@include min-screen($account-table-desktop-breakpoint) {
			display: table;
		}


		thead { // hide the thead on mobile and use &before to display it instead
			display: none;
		}

		tr, th, td, tbody, tfoot {
			display: block;
		}

		tbody {
			width: 100%;

			td {
				display: inline-flex;
				padding: $account-table-inner-spacing;
				text-align: left;

				&:before {
					content: attr(data-th);
					flex: 0 0 50%;
					text-align: left;
					font-weight: $font-weight-bold;
				}
			}
		}

		// forces the table footer under the body on my orders page
		tfoot {
			flex: 0 1 100%;
		}

		// reset table elements to default for desktop

		@include min-screen($account-table-desktop-breakpoint) {
			tbody {
				display: table-row-group;

				tr {
					display: table-row;
				}

				td {
					display: table-cell;

					&:before {
						display: none;
					}
				}
			}

			thead {
				display: table-header-group;

				tr {
					display: table-row;
				}
			}

			tfoot {
				display: table-footer-group;

				tr {
					display: table-row;
				}

				th,
				td {
					display: table-cell;
				}
			}
		}
	}
}

table.table-credit-cards {
	tbody {
		width: 100%;

		tr {
			td {
				padding-left: 0;
				padding-right: 0;

				@include min-screen($screen__ml) {
					padding-left: $twenty-gutter;
					padding-left: $twenty-gutter;

					&:first-child {
						padding-left: 0;
					}
				}
			}

			td.col.card-number {
				span {
					margin-right: $saved-card-account-table-card-number-spacing;
					text-transform: capitalize;
				}
			}
		}
	}
}

.account {
	.table-order-items,
	.data-grid {
		margin-bottom: 0; // remove the bottom margin that is applied to all tables so pagination sits with the table
	}
}

// not really table styling but these are pagination controlls added to the bottom of a table so kept here
.account {
	.order-products-toolbar,
	.quotes-grid-toolbar {
		padding-right: $account-col-side-padding;

		.pager {
			flex: 0 1 100%;

			.toolbar-amount {
				display: $account-pagination-toolbar-amount-display;
			}
		}

		.limiter {
			display: flex;
			align-items: center;
			width: 100%;

			.limiter-label,
			.label {
				display: $account-pagination-limiter-label-display;
			}

			select,
			.control {
				flex: 1;
				width: 100%;
				margin-right: $twenty-gutter;

				@include min-screen($screen__xs) {
					flex: 0 1 $account-table-pagination-select-max-width;
					max-width: $account-table-pagination-select-max-width;
					margin-left: auto;
				}
			}
		}

		.limiter-text {
			@include type__14(
				$color: $body-font-color,
				$font-weight: $font-weight-bold
			);
		}
	}
}

// Reward Points
.table-reward-history {
	tbody {
		tr {
			td {
				width: 100%;

				@include min-screen($screen__ml) {
					width: auto;
				}
			}
		}
	}
}

// B2B Specific Tables
.company-users-index,
.company-role-index {
	// Yuck selector to match core styles
	&.account table.data-grid tbody td.col.actions {
		display: flex;
		flex-direction: row;

		@include min-screen($screen__ml) {
			flex-direction: column;
			align-items: flex-start;
		}

		&:before {
			display: none;
		}

		.action {
			@include link__underline;
			padding-left: 0;
			margin-right: $twenty-gutter;

			@include min-screen($screen__ml) {
				margin-right: 0;
			}
		}
	}
}
