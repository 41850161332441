// account wishlist area
// -------------------------------------------------
.page-multiple-wishlist {
	.block-wishlist-management {
		margin-left: -$item-gutter;
		margin-right: -$item-gutter;
		display: $account-multiple-wishlist-display;
		flex-wrap: wrap;

		.wishlist-title {
			display: $account-multiple-wishlist-title-display;
		}

		.wishlist-select {
			display: $account-multiple-wishlist-display;
			border-bottom: 1px solid $border-color;
			flex: 0 1 100%;

			.wishlist-name {
				padding: $item-gutter;
				white-space: nowrap;

				&:after {
					content: $account-multiple-wishlist-name-after-content;
				}

				.wishlist-name-current {
					display: $account-multiple-wishlist-name-current-display;
				}
			}

			.wishlist-select-items {
				display: $account-multiple-wishlist-display;
				flex: $account-multiple-wishlist-display;

				.item {
					padding: $item-gutter;

					&.current {
						border-bottom: 1px solid $brand-accent;
						position: relative;
						bottom: -1px;
						/* push down 1px to sit on top of current border for the active indicator */
					}

					&.wishlist-add {
						margin-left: auto;
					}
				}
			}
		}

		.wishlist-info,
		.wishlist-toolbar {
			padding: $item-gutter;
			flex: 0 1 100%;
			display: $account-multiple-wishlist-display;
			flex-wrap: wrap;

			@include min-screen($screen__s) {
				flex-wrap: nowrap;
			}

			@include min-screen($screen__xl) {
				flex: 0 1 50%;
			}

			.wishlist-toolbar-actions {
				display: $account-multiple-wishlist-display;
				z-index: $account-multiple-wishlist-dropdown-z-index;
				flex-wrap: wrap;

				@include min-screen(500px) {
					margin-left: auto;
					flex-wrap: nowrap;
				}

				.wishlist-dropdown {
					padding-right: $twenty-gutter;
					flex: 0 1 100%;
					white-space: nowrap;
					position: relative;

					@include min-screen($screen__s) {
						flex: 0 1 50%;
					}

					&:last-child {
						padding-right: 0;
					}

					span.action.toggle {
						z-index: $account-multiple-wishlist-dropdown-toggle-z-index;
						margin-top: $item-gutter;
						display: $account-multiple-wishlist-display;
						align-items: center;

						&:first-child {
							padding-top: 0;
						}

						@include type__16(
							$color: $brand-accent
						);

						&:after {
							content: '';
							@include svg-background('#{$account-multiple-wishlist-open-icon}');
							display: inline-flex;
							width: $account-multiple-wishlist-icon-size;
							height: $account-multiple-wishlist-icon-size;
							background-size: contain;
							margin-left: em(10);
						}

						&.active {
							&:after {
								@include svg-background('#{$account-multiple-wishlist-close-icon}');
							}
						}
					}

					ul.items.dropdown {
						display: none;
						height: 0;
						position: absolute;
						width: 100%;
						z-index: 10;
					}

					&.active {
						ul.items.dropdown {
							display: block;
							height: auto;
							background: $white;
							border: 1px solid $border-color;

							li {
								padding: $item-gutter;

								&:hover {
									background: $grey-light;
								}
							}
						}
					}
				}
			}

			.wishlist-toolbar-select {
				margin-right: $item-gutter;
				margin-bottom: $item-gutter;
			}
		}

		.wishlist-info {
			display: $account-multiple-wishlist-display;
			align-items: center;

			.wishlist-notice {
				display: $account-multiple-wishlist-display;
				align-items: center;

				&.private {
					&:before {
						@include svg-background('padlock');
						display: inline-flex;
						content: '';
						width: $account-multiple-wishlist-icon-size;
						height: $account-multiple-wishlist-icon-size;
						background-size: contain;
						margin-right: em(10);
					}
				}
			}

			.counter {
				padding-left: $twenty-gutter;
			}
		}
	}

	.products-grid {
		.product-items {
			@extend %flex__row;
			justify-content: $account-wishlist-product-items-justify;
			display: $account-multiple-wishlist-display;
		}

		.product-item {
			border-bottom: $account-wishlist-product-item-border;
			width: 100%; //parent has relevant width foe breakpoints e.g. percentage(1/4) at desktop so make the item fill this width;
			padding: 0 $item-gutter;
				
			&:last-child {
				border-bottom: $account-wishlist-last-product-item-border;
			}

			@include min-screen($screen__xs) {
				border-bottom: 0;
			}

			@include min-screen($screen__xs) {
				flex: 0 1 $account-wishlist-product-item-tablet-width;
			}

			@include min-screen($screen__xl) {
				flex: 0 1 $account-wishlist-product-item-desktop-width;
			}

			.product-item-info {
				display: $account-multiple-wishlist-display;
				flex-direction: column;

				.product-item-checkbox {
					display: $account-multiple-wishlist-product-item-checkbox-display;
					border: 0;

					&:before {
						@include svg-background("checkbox_unchecked");
						content: '';
						height: $checkbox-height;
						width: $checkbox-height;
						display: block;
						margin-left: auto;
						margin-right: auto;
						background-size: contain;
					}

					&:checked {
						&:before {
							@include svg-background("checkbox_checked");
						}
					}
				}

				.product-item-inner {
					display: $account-wishlist-product-item-inner-display;
					flex-wrap: $account-wishlist-product-item-inner-wrap;

					.field.comment-box {
						margin: $account-wishlist-comment-box-margin;
						flex: $account-wishlist-comment-box-flex;

						label {
							display: $account-wishlist-comment-box-label-display;
						}

						.control {
							textarea {
								@include input-style-one;
							}
						}
					}

					.box-tocart {
						flex: 0 1 100%;

						button {
							@include button__journey;
						}
					}

					.product-item-actions {
						margin-left: auto;
						margin-right: auto;

						.action.delete,
						.action.edit {
							margin-top: $account-inner-spacing;
							margin-bottom: $account-inner-spacing;
							display: inline-block;
						}

						.action.edit {
							margin-right: $account-inner-spacing;
							
						}

						a {
							@include link__underline;
							text-align: center;
						}
					}

					.fieldset {
						.field.qty {
							display: $account-wishlist-qty-field-display;
						}
					}
				}
			}
		}
	}
}

.account.wishlist-index-index {
	.actions-toolbar {
		.secondary {
			display: $account-wishlist-toolbar-secondary-display;
			@include link__underline;
			margin-top: $account-inner-spacing;
		}
		
		.primary {
			display: $account-wishlist-toolbar-primary-display;
			justify-content: space-between;
			flex-wrap: wrap;

			@include min_screen($screen__s) {
				flex-wrap: nowrap;
			}

			.action {
				@extend %cancel-button;
				@include button__accent;
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 100%;
				margin: $item-gutter 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				@include min_screen($screen__s) {
					flex-basis: calc(#{percentage(1/3)} - #{$twenty-gutter});
					margin: 0;
				}
			}
		}
	}
}

.account.page-multiple-wishlist {
	.actions-toolbar {
		.primary {
			.action {
				@include min_screen($screen__s) {
					flex-basis: calc(#{percentage(1/4)} - #{$twenty-gutter});
				}
			}
		}
	}
}
