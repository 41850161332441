/* -------------------------------------------------
 * -------------------------------------------------
 * Main Sidebar
 *
 * -------------------------------------------------
 * @author Fisheye
 */

[id="layered-filter-block"] {
	.filter-title {
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			display: none; /* Not required on desktop */
		}

		strong {
			@include type__14(
				$font-weight: $font-weight-semibold
			);
			border: 1px solid $ebony-clay;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: $five-gutter;
			height: rem(36);
			width: rem(70);
			cursor: pointer;

			&:before {
				content: '';
				display: inline-block;
				height: rem(16);
				width: rem(16);
				@include svg-background(
					$name: filter,
					$size: contain
				);
			}
		}
	}

	.toolbar-sorter {
		background-color: $white;
		margin-top: $ten-gutter;
		padding: $small-gutter;

		label {
			font-weight: $font-weight-semibold;
			padding-bottom: $twenty-gutter;
			display: block;
		}
	}

	&.active {
		.block-content {
			display: block;
			z-index: 102; /* Overlay the header */
		}
	}

	.block-content {
		display: none; // hide dropdown by default */
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background-color: $silver;
		box-shadow: $dropdown-shadow;
		border-radius: $border-radius-l;
		overflow: hidden;

		@include min-screen($screen__m) {
			position: static;
			box-shadow: none;
			background-color: transparent;
			display: block;
			overflow: visible;
			border-radius: 0;
		}
	}

	.block-subtitle {
		@include type__20;
		display: block;
		padding: $medium-gutter $small-gutter $twenty-gutter $small-gutter;
		background-color: $white;
		border-bottom: 1px solid $mercury;

		@include min-screen($screen__m) {
			display: none; /* Not required on desktop */
		}
	}

	.filter-current {
		@include min-screen($screen__m) {
			display: none; /* Hidden on desktop */
		}

		.items {
			padding: $small-gutter 0;
		}

		.item {
			background-color: white;
		}
	}

	.filter-actions {
		padding: $small-gutter;
		background-color: $white;
		margin-bottom: $small-gutter;

		@include min-screen($screen__m) {
			display: none; /* Hidden on desktop */
		}
	}

	.filter-clear {
		@include button__default;
		width: 100%;
		display: block;
	}

	.filter-options {
		display: flex;
		flex-direction: column;
		gap: $small-gutter;
		padding-top: $small-gutter;

		@include min-screen($screen__m) {
			flex-flow: row wrap;
			gap: rem(12);
		}
	}

	.filter-options-item {
		background-color: $white;

		@include min-screen($screen__m) {
			position: relative;
			z-index: 2;
		}

		&.active {
			.filter-options-title:after {
				transform: rotate(180deg);
			}

			.filter-options-content {
				display: flex !important; /* Override inline styles */
			}
		}

		&:not(.active) {
			.filter-options-content {
				display: none; /* hide dropdowns until js loads */
			}
		}
	}

	.filter-options-title,
	[id="next-day-delivery-filter"] {
		@include type__16(
			$font-weight: $font-weight-semibold
		);
		align-items: center;
		border: 0;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: rem(17) $small-gutter;
		text-align: left;

		@include min-screen($screen__m) {
			border: 1px solid $mercury;
			gap: rem(4);
			padding: rem(7);
		}

		&.active {
			@include min-screen($screen__m) {
				border-color: $ebony-clay;

				p {
					color: $ebony-clay;
				}
			}
		}

		&:after {
			@include svg-background(
				$name: arrow-down,
				$size: contain
			);
			width: rem(10);
			height: rem(6);
			display: block;
			content: '';
		}

		p,
		span {
			@include type__16(
				$font-weight: $font-weight-semibold
			);
			text-align: left;
			display: flex;
			gap: rem(3);
			align-items: center;

			@include min-screen($screen__m) {
				@include type__12(
					$color: $grey-medium
				);
			}
		}

		.amount {
			@include type__8(
				$font-weight: $font-weight-semibold,
				$color: $white
			);
			text-align: center;
			width: rem(16);
			height: rem(16);
			background-color: $ebony-clay;
			border-radius: 20px;
			display: block;

			@include min-screen($screen__m) {
				margin: 0 rem(3);
			}
		}
	}

	[id="next-day-delivery-filter"] {
		background-color: $white;

		&:after {
			display: none; /* Remove unwanted icon */
		}
	}

	.filter-options-content {
		display: flex;
		flex-direction: column;

		@include min-screen($screen__m) {
			background-color: $white;
			box-shadow: $dropdown-shadow;
			padding: $small-gutter 0;
			position: absolute;
			left: 0;
			width: rem(280);
			z-index: 1;
		}
	}

	.filter-details {
		order: 1;

		.clear-filter {
			@include type__16(
				$font-weight: $font-weight-semibold
			);
			display: flex;
			justify-content: center;
			align-items: center;
			gap: $five-gutter;
			border-top: 1px solid $mercury;
			min-height: rem(60);

			@include min-screen($screen__m) {
				min-height: 0;
				padding: rem(16) $small-gutter 0 $small-gutter;
				margin-top: $medium-gutter;
			}

			&:before {
				@include svg-background(
					$name: reset,
					$size: contain
				);
				content: '';
				display: block;
				width: rem(14);
				height: rem(14);
			}
		}
	}

	li a,
	.swatch-option {
		@include type__14;
		border-top: 1px solid $silver;
		min-height: rem(49);
		padding: $five-gutter $small-gutter;
		display: flex;
		align-items: center;
		gap: rem(3);

		@include min-screen($screen__m) {
			border: 0;
			min-height: 0;
		}

		&[option-type="0"] {
			&:before {
				content: "";
				height: 1rem;
				width: 1rem;
				margin: 0 $five-gutter 0 0;
				@include svg-background(
				    $name: 'unchecked-box',
				    $size: contain
				);
			}

			&.selected:before {
				@include svg-background("checked-box");
			}
		}

		.count {
			background-color: $grey-light;
			border-radius: $border-radius-s;
			padding: rem(2) rem(8);

			.filter-count-label {
				@extend %visuallyhidden;
			}
		}

		label {
			width: 100%;
			display: flex;
			align-items: center;
			gap: rem(3);

			span {
				@include type__14;
				width: auto;
			}

			&:before {
				order: 3;
				height: rem(23);
				margin: 0 0 0 auto;
				width: rem(24);

				@include min-screen($screen__m) {
					height: rem(16);
					margin: 0 $five-gutter 0 0;
					order: initial;
					width: rem(16);
				}
			}
		}
	}

	.smile-es-range-slider {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: $small-gutter;
		padding: 0 $small-gutter;

		@include min-screen($screen__m) {
			padding: $small-gutter;
		}

		.from-label,
		.to-label {
			@include type__16;
		}

		[data-role="message-box"] {
			padding-bottom: $small-gutter;
			display: block;
		}

		.ui-slider-horizontal {
			flex: 1 1 100%;
			background-color: $mercury;
			height: rem(4);
		}

		.ui-slider-handle {
			height: rem(13);
			width: rem(13);
			margin-top: rem(-5);
		}

		.actions-toolbar {
			flex: 1 1 100%;
		}

		.primary {
			@include button__default;
			display: block;
			text-align: center;
		}
	}

	.colour {
		.filter-options-content {
			@include min-screen($screen__m) {
				width: rem(370);
			}
		}

		.swatch-attribute-options {
			padding: $ten-gutter $small-gutter;
			display: flex;
			gap: $small-gutter;
			overflow-x: auto;

			@include min-screen($screen__m) {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				overflow: hidden;
			}

			.swatch-option {
				width: rem(48);
				height: rem(48);
				padding: 0;
				border: 1px solid $mercury;
				border-radius: $border-radius-s;
				margin: 0;

				@include min-screen($screen__m) {
					width: rem(20);
					height: rem(20);
					min-height: 0;
				}
			}

			a {
				@include min-screen($screen__m) {
					display: flex;
					align-items: center;
					gap: $ten-gutter;
				}
			}
		}
	}

	.swatch-label {
		@include type__8;
		text-align: center;

		@include min-screen($screen__m) {
			@include type__14;
		}
	}

	.filter-count {
		position: relative;
		color: $white;
		background: $keppel;
		border-radius: 100%;
		font-size: 8px;
		height: 10px;
		width: 10px;
		top: 1rem;
		left: 1rem;
		display: flex;
		justify-content: center;
		align-content: center;
	}
}
