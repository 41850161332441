/* -------------------------------------------------
 * -------------------------------------------------
 * Pagination
 *-----------------------------------------------
 * @author Fisheye
 */

/* Lots of nasty nesting to override tpm and CORE themes */
.toolbar {
	.pages {
		padding: $forty-gutter $small-gutter rem(70) $small-gutter;

		.pages-items {
			.item,
			.item.current {
				.page {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.action {
					&.next,
					&.previous {
						display: flex;
						align-items: center;
						justify-content: center;
						background-image: none;
						width: auto;
						gap: $five-gutter;

						span:not(.label) {
							@extend %cancel-visuallyhidden;
						}

						&:after {
							@include svg-background(
								$name: arrow-right
							);
							content: '';
							height: rem(11);
							width: rem(6);
							display: block;
						}
					}

					&.previous {
						transform: none;

						span {
							order: 2;
						}

						&:after {
							transform: rotate(180deg);
						}
					}
				}

				&.current {
					font-weight: $font-weight-semibold;
				}
			}
		}
	}
}
