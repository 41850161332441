/* -------------------------------------------------
 * -------------------------------------------------
 * B2b Requisition List
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.requisition_list-requisition-view {
	.block-requisition-management {
		display: flex;
		flex-wrap: wrap;
	}

	.mobile-label {
		@extend %visuallyhidden; /* useful for screen readers */
	}

	.requisition-view-links,
	.requisition-info,
	.requisition-toolbar {
		flex: 0 1 100%;
	}

	.requisition-view-links {
		padding-bottom: $medium-gutter;
	}

	.requisition-list-title {
		align-items: center;
		display: flex;
		flex: 0 1 100%;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding-bottom: $forty-gutter;

		h1 {
			@extend %trailing-full-stop;
			@include type__32(
				$font-weight: $font-weight-semibold
			);
			text-transform: none;

			@include min-screen($screen__m) {
				@include type__40(
					$font-weight: $font-weight-semibold
				);
			}
		}

		button {
			-webkit-appearance: none;
			border: 0;
			padding: 0;
			margin-left: $item-gutter;
			background: transparent;
			@include link__underline();
		}

		button.add {
			@include button__default();
			margin-top: $item-gutter
		}

		.requisition-list-description {
			flex: 0 1 100%;
		}
	}

	.requisition-toolbar-actions,
	.requisition-view-buttons .primary:not(.action) {
		display: grid;
		gap: $small-gutter;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		padding: $gutter 0;
		position: relative;

		@include min-screen($screen__m) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		button {
			@include button__default;
			width: 100%;
		}
	}

	.requisition-view-buttons .primary.action {
		margin-top: $account-inner-spacing;
	}

	.block-requisition-list {
		position: relative;
	}

	.requisition-list-action {
		[data-target="dropdown"] {
			display: none; /* dropdown hidden by default */

			&[aria-hidden="false"] {
				background-color: $white;
				box-shadow: 0 1px 16px rgba(23, 27, 63, 0.24);
				display: block;
				min-width: rem(300);
				padding: 0 $ten-gutter;
				position: absolute;
				left: auto;
				right: 0;
				z-index: 1;
			}

			.item {
				padding: $ten-gutter 0;

				border-bottom: 1px solid $mercury;

				&:last-child {
					@include min-screen($screen__ml) {
						border-bottom: 0;
					}
				}
			}

			span {
				cursor: pointer;

				&:hover,
				&:focus {

				}
			}
		}
	}

	.requisition-content {
		.data-table,
		thead,
		tbody {
			display: block;
		}

		.data-table {
			@include min-screen($screen__ml) {
				border: 1px solid $mercury;
				border-radius: $border-radius-m;
				padding: $medium-gutter;
			}
		}

		.item {
			display: grid;
			grid-template-columns: rem(90) 1fr 1fr;
			align-items: center;
			gap: $five-gutter;
			padding: $medium-gutter 0;
			border-bottom: 1px solid $mercury;

			@include min-screen($screen__ml) {
				grid-template-columns: rem(20) rem(30) rem(80) auto minmax(18%, 25%);
			}

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				border: 0;
				padding-bottom: 0;
			}
		}

		.col {
			padding: 0;
			width: 100%;

			&:before {
				display: none; /* Remove unwanted icons */
			}
		}

		.col-checkbox {
			grid-column: span 2 / span 2;

			@include min-screen($screen__ml) {
				grid-column: span 1 / span 1;
			}
		}

		input[type="checkbox"]+label {
			@extend %checkbox-unchecked;

			span {
				@extend %visuallyhidden;
			}
		}

		input[type="checkbox"]:checked+label {
			@extend %checkbox-checked;
		}

		.col.action {
			grid-column: span 3 / span 3;
			order: 1;

			@include min-screen($screen__ml) {
				grid-column: span 4 / span 4;
				order: initial;
			}
		}

		.actions-toolbar {
			display: flex;
			justify-content: flex-start;
			gap: $small-gutter;
			order: 5;
		}
		button.action-delete,
		button.action-edit {
			@include type__14;
			appearance: none;
			align-items: center;
			background-color: transparent;
			border: 0;
			display: flex;
			gap: rem(3);
			position: relative;
			-webkit-appearance: none;

			&:before {
				@include svg-background(
					$name: edit,
					$size: contain
				);
				content: '';
				width: rem(16);
				height: rem(16);
				right: 0;
				display: block;
			}

			&:hover,
			&:focus {
				opacity: 0.65;
			}
		}

		button.action-delete:before {
			@include svg-background(
				$name: delete,
				$size: contain
			);
		}

		.col-photo {
			padding: 0;
		}

		.col.qty {
			text-align: right;
			width: 100%;

			.qty {
				border: 1px solid $grey-medium;
				color: $ebony-clay;
				text-align: center;
				width: rem(60);

				&:focus {
					border-color: $keppel;
				}
			}
		}

		.col.product {
			grid-column: span 2 / span 2;

			@include min-screen($screen__ml) {
				grid-column: span 1 / span 1;
			}
		}

		.col.price {
			display: flex;
			flex-direction: column;
			grid-column: span 2 / span 2;
			text-align: right;
			width: 100%;

			@include min-screen($screen__ml) {
				grid-column: span 1 / span 1;
			}
		}

		.price-per-container span {
			@include type__12(
				$color: $dusty-gray
			);
			text-transform: lowercase;
		}

		.product-item-subtotal {
			.price {
				@include price;
			}
		}
	}

	.toolbar {
		display: flex;
		justify-content: space-between;

		.pages {
			padding: $medium-gutter 0;
		}

		.limiter {
			width: auto;
			display: flex;
			gap: $fifteen-gutter;

			.limiter-label,
			.limiter-text,
			.label {
				display: inline;
				font-weight: 400;
				font-size: 16px;
				line-height: 28px;
				color: $edward;
				white-space: nowrap;
			}

			.control {
				margin: 0;
			}

			.limiter-options {
				border-radius: 0;
				background-color: $grey-light;
				color: $black;
				border: none;
			}
		}

		.pager {
			display: none; // Hidden rather than removed as to not impact other pages
		}

		.pages {
			.pages-items {
				.item {
					.page {
						font-weight: 400;
					}

					cursor: pointer;
				}

				.pages-item-previous {
					span {
						color: $edward;
					}

					.action::after {
						@include svg-background(
							$name: arrow-right-edward
						);
					}
				}
			}
		}
	}
}

.requisition_list-requisition-index {
	.title-actions-links {
		button.add.action {
			@include button__default;

			span {
				@include type__16(
					$color: inherit,
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__m) {
					@include type__16(
						$color: inherit,
						$font-weight: $font-weight-semibold
					);
				}
			}
		}
	}
}
