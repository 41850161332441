/* -------------------------------------------------
 * -------------------------------------------------
 * Countdown Clock Styling
 * -------------------------------------------------
 * @author Fisheye
 */

.countdown-clock {
	.time-remaining {
		color: $keppel;
	}
}

.page-layout-checkout {
	.checkout-panel-wrapper {
		.countdown-container {
			.countdown-clock {
				.time-remaining {
					color: $keppel;
				}
			}
		}
	}
}

.lead-time-message {
	.countdown-clock {
		.time-remaining {
			color: $keppel;
		}
	}
}
