/* -------------------------------------------------
 * -------------------------------------------------
 * Contact Page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.contact-index-index {
	.page-title-wrapper {
		width: 100%;
		padding: rem(33) 0 rem(9) 0;

		@include min-screen($screen__m) {
			padding-top: 0;
			padding-bottom: $medium-gutter;
		}

		.base {
			line-height: rem(56);
		}
	}

	.page-main {
		padding: 0 $small-gutter $ten-gutter $small-gutter;
		overflow: hidden;

		@include min-screen($screen__m) {
			padding: $forty-gutter $gutter;
		}
	}

	.columns {
		padding: 0;

		@include min-screen($screen__m) {
			gap: $forty-gutter;
		}

		p {
			@include type__16;
		}

		[data-content-type='image'] {
			img {
				padding-top: rem(8);

				@include min-screen($screen__m) {
					padding-top: $five-gutter;
				}
			}
		}

		.form-container {
			@include min-screen($screen__m) {
				display: block;
			}

			@include min-screen($screen__l) {
				display: flex;
			}
		}
	}
}

.contact .enquiry .primary .submit span {
	@include type__14($color: $keppel);
}

.store-information {
	.pagebuilder-button-link {
		span {
			// cancel original underline as duplicate styling on the actual link
			border-bottom: none;

			&:hover,
			&:focus,
			&:active {
				border-bottom: none;
			}
		}
	}
}
