/* -------------------------------------------------
 * -------------------------------------------------
 * placeholders
 * Most found in tpm
 * --------------------------------------------------
 * @author Fisheye
 */

%trailing-full-stop {
	&:after {
		content: '.';
		color: $keppel;
	}
}

%trailing-question-mark {
	&:after {
		content: '?';
		color: $keppel;
	}
}
