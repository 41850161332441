/* -------------------------------------------------
 * -------------------------------------------------
 * PDF download - enabled on tpm an trophy street
 * Found on category and product pages
 *
 * -------------------------------------------------
 * @author Fisheye
 */

/*  Catalog popup
 * -------------------------------------------------
 */

.download-popup {
	.modal-header {
		.modal-title {
			@extend %trailing-full-stop;
			font-weight: $font-weight-semibold;
			text-transform: none;
		}
	}
}
