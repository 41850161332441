/* -------------------------------------------------
 *  Layout and general styles for order summary sidebar
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-index-index {
	/* Main sidebar on desktop
 	 * -------------------------------------------------
 	 */
	.opc-sidebar {
		border-bottom: none;

		div[id="opc-sidebar"] {
			background-color: $silver;
			border-bottom: none;
			padding: $forty-gutter rem(16);

			@include min-screen($screen__l) {
				padding: rem(64) rem(108) rem(64) $forty-gutter;
			}

			.opc-block-summary {
				background-color: $checkout-sidebar-background-color;

				// -----------------------------------
				// Title and edit basket button at top
				.title-order-summary {
					@extend %trailing-full-stop;
					@include type__20(
						$color: $ebony-clay,
						$font-weight: $font-weight-semibold,
					);


					display: block;
					text-transform: none;
				}

				.title-order-delivery {
					@include type__16(
						$color: $grey-medium,
						$font-weight: $font-weight-normal,
						$line-height: rem(16),
					);

					display: flex;
					padding-top: rem(16);
					width: 100%;

					@include min-screen($screen__l) {
						padding-top: rem(24);
					}
				}

				table.table-totals {
					padding: 0;

					th,
					th span,
					th strong,
					td span,
					.price {
						@include type__16(
							$color: $ebony-clay,
							$font-weight: $font-weight-normal,
						);

						text-transform: initial;
					}

					th strong,
					td strong .price {
						@include type__16(
							$color: $ebony-clay,
							$font-weight: $font-weight-semibold,
						);
					}

					.totals.sub,
					.totals-tax,
					.shipping-information,
					.totals.shipping span.value,
					.totals.charge,
					.totals-tax-summary,
					.grand.totals.excl {
						display: none; // not showing sub total, tax total, delivery addess on sidebar per design
					}

					.secure-message th {
						color: $tuna;
					}

					.grand.totals {
						th strong,
						td strong .price {
							@include type__18(
								$color: $ebony-clay,
								$font-weight: $font-weight-semibold,
								$line-height: rem(30),
							);
						}

						td .price.label {
							@include type__18(
								$color: $edward,
								$font-weight: $font-weight-normal,
								$line-height: rem(30),
							);
						}
					}
				}

				.minicart-items-wrapper .product-item {
					.product-item-inner {
						flex-direction: column;

						.product-item-name-block {
							align-self: flex-start;
							width: 100%;

							.name-delete-wrapper {
								display: flex;

								.action.delete {
									cursor: pointer;
									margin-left: auto;
								}
							}
						}

						.subtotal-qty {
							display: flex;
							width: 100%;

							.details-qty {
								margin-left: auto;
								margin-top: 0;

								span {
									@include type__14;
								}
							}
						}

						.subtotal {
							align-self: flex-start;
							display: flex;
							flex-direction: column;

							@include min-screen($screen__ll) {
								flex-direction: row;
							}

							span {
								@include type__16($font-weight: $font-weight-semibold);
							}

							.price-per-item {
								@include min-screen($screen__ll) {
									margin-left: rem(8);
								}

								span {
									@include type__16(
										$color: $edward,
										$font-weight: $font-weight-normal
									);
								}
							}
						}
					}

					.product {
						padding-top: rem(16);

						@include min-screen($screen__l) {
							padding-top: rem(24);
						}

						.product-image-container {
							align-self: flex-start;
						}

						.product-item-name {
							@include type__14(
								$color: $ebony-clay,
								$font-weight: $font-weight-semibold,
							);
						}

						.product-item-sku {
							@include type__12(
								$font-weight: $font-weight-normal
							);
						}
					}
				}

				.button.action-edit {
					display: none; //no edit cart button on this theme
				}
			}
		}
	}
}
