/* -------------------------------------------------
 * -------------------------------------------------
 * Price Styles
 *
 * --------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

@mixin price(
	$price-color: $ebony-clay
) {
	@include type__18(
		$color: $price-color,
		$font-weight: $font-weight-semibold
	);
}

/* Global price and label styles
 * -------------------------------------------------
 */

.price-excluding-tax-container {
	display: flex;
	gap: rem(4);

	.price-display-label {
		@include type__18(
			$color: $edward,
			$font-weight: $font-weight-normal
		);
		order: 2;
	}

	.price-excluding-tax {
		order: 1;
	}
}

// -------------------------------------------------
// Product page price
// -------------------------------------------------

@mixin product-page-price(
	$label-color: $edward,
	$price-color: $keppel,
	$old-price-color: $edward
) {
	// Price label
	.price-label {
		@include type__18(
			$color: $label-color,
			$font-weight: $font-weight-normal,
			$line-height: rem(24)
		)
	}

	// Normal & special price
	.price {
		@include type__36(
			$color: $price-color,
			$font-weight: $font-weight-semibold,
			$line-height: rem(52)
		);
	}

	.old-price {
		.price-label {
			display: none;
		}

		.price {
			@include type__20(
				$color: $old-price-color,
				$font-weight: $font-weight-normal
			);
			text-decoration: line-through;
		}
	}
}

.product-info-main {
	@include product-page-price;

	.price-box {
		align-items: baseline;
	}

	.old-price {
		padding-right: $five-gutter;

		.retail-price-container {
			display: none; //hide retail price on special price
		}
	}

	.special-price {
		.price-label {
			@extend %visuallyhidden; /* text not required as the label is duplicated in another div but useful for accessibility */
		}
	}

	.price-rrp_price {
		width: 100%;

		.retail-price-container {
			.price,
			.price-display-label {
				@include type__18(
					$color: $edward,
					$font-weight: $font-weight-normal
				);
			}

			.price {
				font-weight: $font-weight-semibold;
			}
		}
	}
}

// -------------------------------------------------
// Default / fallback price
// -------------------------------------------------

.price {
	@include price;
}
