/* -------------------------------------------------
 * -------------------------------------------------
 * Page Titles
 *
 * These can be found on most pages, including the
 * basket page and category pages
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.page-title-wrapper {
	background-color: transparent;
	max-width: $cms-container-width;
	margin: 0 auto;
	padding: $ten-gutter $small-gutter;
	text-align: left;

	.page-title {
		text-align: left;
		padding: 0;

		span {
			@include type__32(
				$font-weight: $font-weight-semibold
			);
			text-transform: none;

			@include min-screen($screen__m) {
				@include type__40(
					$font-weight: $font-weight-semibold
				);
			}
		}
	}
}
