/* ----------------------------------------------------
 * ----------------------------------------------------
 * Desktop Mega Menu
 * This is the styling for the actual mega menu content
 * on desktop
 * ----------------------------------------------------
 * @author Fisheye
 */

.page-header {
	.nav-sections {
		z-index: 90; /* Ensure text from JS search does not appear above menu */

		.nav-sections-item-content {
			// Nesting required to override default TPM theme
			.navigation {
				// Breakpoint required to not interfere with mobile styles
				@include min-screen($screen__m) {
					background-color: $keppel;
				}

				ul.navigation-main-ul {
					position: relative;
				}

				ul.submenu {
					max-width: $cms-container-width + $ten-gutter; /* Account for button padding */
					margin: 0 auto;
					padding: $medium-gutter;
					z-index: 100;

					.award-type-container,
					.a-z-container {
						.pagebuilder-column {
							display: flex !important; /*overriding original styling */
							align-items: flex-start;
							flex-direction: column;

							h3 {
								color: $keppel;
							}

							.pagebuilder-buttons {
								.pagebuilder-button-link {
									text-decoration: none;

									span {
										@include type__16(
											$color: $ebony-clay,
											$line-height: rem(28),
										);
									}
								}
							}
						}
					}

					.award-type-container {
						.pagebuilder-column {
							.pagebuilder-buttons {
								column-rule: 1px solid $mercury;
								column-count: 2;

								@include min-screen($screen__ll) {
									border-right: 1px solid $mercury;
								}

								li {
									padding: rem(8) rem(16) 0;

									a {
										border-bottom: 1px solid $mercury;
										padding-bottom: rem(8);
									}
								}

								.pagebuilder-button-link {
									img {
										min-width: 48px;
										max-width: 48px;
									}
								}
							}
						}

						.cms-banners {
							display: none !important; // override pagebuilder styles
							margin-left: $fifteen-gutter;

							@include min-screen($screen__ll) {
								display: flex !important;
							}

							.pagebuilder-banner-wrapper {
								flex-direction: row;
								padding: $medium-gutter;
								padding-right: 0;

								.pagebuilder-banner-image-wrapper {
									flex: 1 0 50%;
									order: 3;
									padding-left: rem(20);

									.pagebuilder-banner-image-desktop {
										height: auto;
									}
								}
							}
						}
					}

					.ui-menu-item-wrapper:not(:last-child) {
						padding-bottom: rem(36);
					}
				}
			}
		}
	}
}
