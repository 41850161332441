/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Categories
 *
 * Most styles should be in relevant stylesheet i.e.
 * buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */
.blog-home-index,
.blog-search-result {
	.columns {
		display: flex;
	}

	.page-title .base {
		display: block;
		margin-bottom: rem(9);
	}

	.blog-search {
		display: flex;
		align-items: center;
		position: relative;
		margin: auto auto $five-gutter;
		width: 100%;

		@include min-screen($screen__m) {
			max-width: em(378);
			margin-bottom: $fifteen-gutter;
		}

		.input-text {
			@include input-style-two;
		}

		.search-button {
			display: flex;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 22px;
			align-items: center;
			background: 0;
			border: 0;
			padding: 0;

			&:before {
				@include svg-background(
					$name: 'magnifying-glass-white',
					$size: 18px 18px
				);
				content: '';
				display: inline-block;
				height: 18px;
				width: 18px;
			}
		}
	}

	.blog-post-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		
		@include min-screen($screen__m) {
			padding-top: $ten-gutter;
			margin-right: rem(-31); // used in order to prevent having to force inconsistent image sizes
		}

		~ .toolbar {
			flex-direction: column;
			border-bottom: 0;
			border-top: 1px solid $gallery;
			padding: $twenty-gutter 0 0 0;

			@include min-screen($screen__m) {
				flex-direction: row;
				margin-top: $forty-gutter;
			}

			.pages {
				order: 1;

				@include min-screen($screen__m) {
					order: 4;
				}
			}

			.toolbar-amount {
				@include type__14($font-weight: $font-weight-semibold);
				margin: 0;
				padding: $fifteen-gutter 0 0 0;
				justify-content: center;

				@include min-screen($screen__m) {
					flex: 1;
					padding: 0;
				}

				* {
					@include type__14($font-weight: $font-weight-semibold);
				}
			}
		}
	}

	.blog-post-item {
		display: flex;
		flex-direction: column;
		flex: 0 1 100%;
		border-bottom: 1px solid $gallery;
		padding-bottom: $twenty-gutter;
		margin-bottom: $twenty-gutter;

		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}

		@include min-screen($screen__m) {
			flex: 0 1 percentage(1/2);
			padding-top: $forty-gutter;
			padding-right: rem(31);
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;

			&:before {
				content: '';
				border-top: 1px solid $gallery;
				padding-top: $forty-gutter;
				margin-right: -$forty-gutter;
			}

			&:nth-child(-n+2) {
				border-top: 0;
				padding-top: 0;

				&:before {
					border-top: 0;
					padding: 0;
					margin-right: 0;
				}
			}

			&:nth-child(2n) {
				&:before {
					margin-right: 0;
				}
			}
		}

		@include min-screen($screen__l) {
			flex: 0 1 percentage(1/3);

			&:nth-child(-n+3) {
				border-top: 0;
				padding-top: 0;

				&:before {
					border-top: 0;
					padding: 0;
				}
			}

			&:nth-child(2n) {
				&:before {
					margin-right: -$gutter;
				}
			}

			&:nth-child(3n) {
				&:before {
					margin-right: 0;
				}
			}
		}

		@include min-screen($screen__xl) {
			flex: 0 1 percentage(1/4);

			&:nth-child(-n+4) {
				border-top: 0;
				padding-top: 0;

				&:before {
					border-top: 0;
					padding: 0;
				}
			}

			&:nth-child(2n),
			&:nth-child(3n) {
				&:before {
					margin-right: -$gutter;
				}
			}

			&:nth-child(4n) {
				margin-right: 0;

				&:before {
					margin-right: 0;
				}
			}
		}

		.post-title {
			margin-top: rem(22);
			margin-bottom: rem(14);

			@include min-screen($screen__m) {
				margin-top: rem(27);
				margin-bottom: rem(12);
				min-height: rem(66);
			}

			a {
				@include type__16;

				@include min-screen($screen__m) {
					@include type__20;
				}
			}
		}

		.post-excerpt {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
			@include type__13;

			h3 {
				@include type__20;
				@include text-crop;
				margin-bottom: $fifteen-gutter;
			}

			a {
				@include type__20;
				margin-bottom: $twenty-gutter;
			}
		}

		.post-read-more {
			@include button__default;
			margin-top: $fifteen-gutter;
			margin-bottom: 0;
			width: rem(208);

			@include min-screen($screen__m) {
				margin-top: rem(24);
			}
		}
	}
}
