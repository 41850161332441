/* -------------------------------------------------
 * -------------------------------------------------
 * Top links on accoutn dashboard, normally My Orders,
 * Account Details, Wishlist etc.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.account-top-links {
	background-color: $account-toplinks-background-color;
	margin: $twenty-gutter $page-padding-mobile 0;

	@include min-screen($screen__m) {
		margin: $gutter $page-padding 0;
	}

	&:after {
		content: '';
		border-bottom: 1px solid $wild-sand;
		width: 100%;
		display: none;

		@include min-screen($screen__m) {
			display: block;
			margin-top: $gutter;
		}
	}

	.block {
		justify-content: space-between;
		flex: 0 1 100%;
		padding-bottom: $twenty-gutter;
		margin-bottom: $twenty-gutter;
		border-bottom: 1px solid $wild-sand;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		@include min-screen($screen__m) {
			flex: 0 1 48%; // to take into account margin spacing between blocks
			border-bottom: 0;

			&:nth-child(odd) {
				margin-right: $gutter;
			}

			&:nth-last-of-type(-n+2) {
				margin-bottom: 0;
				padding: 0;
			}
		}

		@include min-screen($screen__ml) {
			flex: 1 1 32%;

			&:last-child {
				margin-right: 0;
			}
		}

		@include min-screen($screen__ll) {
			flex: 1 1 23%;
			margin-right: $gutter;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		h3,
		p {
			text-align: $account-top-links-text-align;
			margin-bottom: $account-top-link-content-spacing;
			flex: 0 1 auto;
		}

		h3 {
			@include type__29($color: $account-top-link-title-color);
			text-transform: uppercase;
		}

		p {
			@include type__14($font-weight: $font-weight-normal);
		}

		.button {
			@include button__journey();
			text-align: center;
			text-transform: uppercase;
		}
	}
}
