/* -------------------------------------------------
 * -------------------------------------------------
 * Desktop menu
 * This is the styling for the top level navigation
 * on desktop for CORE
 * There are lots of varaiables that we can change
 * meanins this file should quite small
 * -------------------------------------------------
 * @author Fisheye
 */

.page-header {
	.nav-sections {
		z-index: 90; /* Ensure text from JS search does not appear above menu */

		.navigation {
			@include min-screen($screen__m) {
				padding: 0 rem(23);
			}

			ul[id="main-navigation"] {
				max-width: $cms-container-width + $ten-gutter; /* Account for button padding */
				margin: 0 auto;
				justify-content: flex-start;

				@include min-screen($screen__ll) {
					gap: $five-gutter;
				}

				> li {
					> a.level-top,
					&.cms-tab .pagebuilder-button-link {
						@include type__14(
							$color: $white
						);
						text-transform: initial;
						text-decoration: none;
					}
				}
			}

			.cms-tab {
				.pagebuilder-column-group,
				[data-content-type='buttons'],
				.pagebuilder-buttons,
				li {
					height: 100%;

					li {
						border-left: 1px solid lighten($keppel, 12%);

						@include min-screen($screen__ll) {
							border-left: 0;
						}
					}
				}
			}
		}
	}
}
