// ------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------
// Catch all for things not covered
// ------------------------------------------------------------------------------------
body[class*="sales-order"],
.account,
.sales-guest-view,
.sales-guest-invoice,
.sales-guest-shipment,
.sales-guest-creditmemo,
.proofs-guest-view { // shares the same styling as the account order view page
	.page-title {
		a {
			color: $white;
			opacity: 1;

			&:hover,
			&:focus {
				color: $white;
				opacity: 1;
			}
		}
	}

	.order-links {
		border-bottom: none;

		li {
			line-height: 1rem; // custom line-height to match %text-links
		}
	}

	.actions {
		.action.print,
		.action.order {
			@include link__underline;
			float: none;
			margin: 0 $item-gutter / 2;
		}
	}

	.order-links {
		display: flex;
		margin-bottom: $twenty-gutter;

		.item {
			@include link__underline;
			@include type__14($font-weight: $font-weight-normal);
			margin-right: $fifteen-gutter;

			&:last-child {
				margin-right: 0;
			}

			&.current {
				color: $keppel;
				border-bottom: 1px solid $keppel;
			}
		}
	}

	.order-details-items {
		margin-top: $twenty-gutter;
		border-bottom: none;

		.order-title {
			display: none; // this is not needed at all
		}
	}

	.block-order-details-view {
		.block-title {
			text-align: center;
		}

		.block-content {
			display: flex;
			flex-flow: row wrap;

			@include min-screen($screen__m) {
				margin-bottom: $ten-gutter;
			}

			.box {
				flex: 0 1 100%;
				margin-bottom: rem(11);

				@include min-screen($screen__s) {
					flex: 0 1 percentage(1/2);
				}

				@include min-screen($screen__xl) {
					flex: 0 1 percentage(1/4);
				}

				&:last-child {
					.table {
						margin-bottom: 0;
					}
				}

				.box-title {
					margin-bottom: $item-gutter;
				}

				.box-content,
				address,
				.title {
					@include type__address;
					margin-bottom: 0;
				}

				.box-content {
					table.data {
						th {
							text-align: left;
						}

						th, td {
							padding: $item-gutter / 2 0;
						}
					}
				}
			}
		}
	}
}

body[class*="sales-order"],
.sales-guest-view,
.sales-guest-invoice,
.sales-guest-shipment,
.sales-guest-creditmemo {
	.page-title-wrapper {
		.order-status,
		.order-date,
		.actions-toolbar {
			text-align: center;
			color: $white;
			@include type__13($color: $white);

			@include min-screen($screen__m) {
				margin-left: $twenty-gutter;
			}
		}

		.order-status {
			display: block;
		}

		.actions-toolbar {
			a:first-child {
				margin-left: 0;
			}

			span {
				@include type__13($color: $white);
			}
		}
	}

	.block-order-details-view {
		.block-title {
			margin-top: $gutter;
			margin-bottom: rem(23);
		}

		.box-title {
			display: block;
			margin-bottom: $item-gutter;
		}
	}

	.table.table-order-items {
		tfoot {
			margin-top: $item-gutter;

			tr {
				th,
				td {
					padding: $item-gutter 0;
					@include type__14($font-weight: $font-weight-bold);
				}

				td {
					padding-left: $gutter;
				}

				td {
					.price {
						@include type__14($font-weight: $font-weight-normal);
					}
				}
			}
		}
	}

	.actions-toolbar {
		margin-top: 0;
	}
}
