/* -------------------------------------------------
 * -------------------------------------------------
 * Product Sort By & Pagination toolbars
 *
 * -------------------------------------------------
 * @author Fisheye
 */


/* Toolbar used on category bottom and recent orders in accounts section
 * -------------------------------------------------
 */

.toolbar {
	background-color: $toolbar-background-color;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: $small-gutter;

	@include min-screen($screen__m) {
		border-bottom: 0;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	/* Sorter
	 * ------------------------------------------------
	 */

	.toolbar-sorter {
		position: relative;

		label {
			@include type__14(
				$color: $grey-medium
			);
			align-items: center;
			bottom: 0;
			display: flex;
			padding-left: $ten-gutter;
			position: absolute;
			top: 0;
			left: 0;
		}

		select {
			@include input-style-one(
				$padding: rem(9) $twenty-gutter rem(9) rem(70)
			);
			min-height: rem(44);
			font-weight: $font-weight-semibold;
			color: $ebony-clay;
			font-size: rem(14);
		}
	}

	/*
	 * Showing x of y items text
	 * -----------------
	 */

	.field.limiter {
		padding: $forty-gutter 0 $gutter 0; // padding applied to content rather than footer toolbar incase it is empty

		@include min-screen($screen__m) {
			display: flex;
			align-items: center;
			gap: rem(3);
		}

		span {
			@include type__16(
				$color: $edward,
				$font-weight: $font-weight-normal
			);
		}

		select[id='limiter'] {
			@include select-style-two;
		}

		button {
			@include button__journey;
		}
	}
}

/* Category header toolbar
 * -------------------------------------------------
 */

.category-header {
	.toolbar {
		.pages,
		.limiter,
		.next-day-delivery,
		.modes,
		.toolbar-amount {
			display: none; /* Hide these from top toolbar */
		}

		.toolbar-sorter {
			margin-left: auto;
		}
	}
}

/* Toolbar Bottom
 * -------------------------------------------------
 */

.toolbar-bottom {
	.modes,
	.toolbar-amount,
	.next-day-delivery,
	.toolbar-sorter {
		display: none; /* Hide these from bottom toolbar */

		@include min-screen($screen__m) {
			display: none; /* Hide these from bottom toolbar */
		}
	}
}
