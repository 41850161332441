/* -------------------------------------------------
 * -------------------------------------------------
 * Global modal styling
 * -------------------------------------------------
 * @author Fisheye
 */

.modal-popup {
	padding: $modal-gutter-mobile;

	@include min-screen($screen__m) {
		padding: $modal-gutter-desktop;
	}

	.modal-inner-wrap {
		.modal-header {
			.action-close {
				@include icon__cross-white(
					$background-color: $keppel
				);
			}
		}
	}
}


[id='create-wishlist-block'] {
	background-color: $overlay-color-alt;
	padding: $modal-gutter-mobile;
	position: fixed;
	inset: 0;
	z-index: 101;

	@include min-screen($screen__m) {
		padding: $modal-gutter-desktop;
	}

	.popup-actions {
		max-width: $modal-max-width;
		margin: 0 auto;

		.close {
			@include icon__cross-white(
				$background-color: $keppel
			);
			align-items: center;
			background-color: transparent;
			border: 0;
			color: $white;
			display: flex;
			margin-left: auto;
		}
	}

	.popup-header {
		padding: $small-gutter;

		@include min-screen($screen__m) {
			padding: $gutter;
		}

		strong {
			@include type__20;
			@extend %trailing-full-stop;
		}
	}

	.popup-content {
		padding: 0 $small-gutter $gutter $small-gutter;

		@include min-screen($screen__m) {
			padding: 0 $gutter $gutter $gutter
		}

		[id='create-wishlist-form'] {
			.actions-toolbar {
				.primary {
					display: block;

					button.primary {
						@include button__default;
						text-align: center;
						width: 100%;
					}
				}
			}
		}
	}

	.popup-header,
	.popup-content {
		background-color: $white;
		margin: 0 auto;
		max-width: $modal-max-width;
	}

}
