/* -------------------------------------------------
 * -------------------------------------------------
 * Returns Page 
 *
 * -------------------------------------------------
 * @author Fisheye
 */
.magento-rma-returns-create,
.magento_rma-returns-create {
	form[id="rma_create_form"] {
		.field.qty {
			label[for*="qty"] {
				@extend %cancel-visuallyhidden;
				margin-left: 0; // remove auto margin causing it to center on ie
				margin-bottom: $form-label-spacing;
				// show qty label, we hide the qty label normally but this is the only case where we need a lebl for qty
			}
		}

		.actions-toolbar {
			.secondary {
				border-bottom: 0;

				&:hover {
					border-bottom: 0;
				}

				button {
					@include button__default();
					margin-bottom: $form-label-spacing;
				}
			}
		}
		.fieldset {
			.field {
				.control {
					flex: $form-input-flex;

					@include min-screen($screen__xl) {
						flex: $form-input-flex-desktop;
					}
				}
			}
		}
	}
}

.magento_rma-returns-history,
.magento_rma-returns-view {
	table {
		margin-bottom: 0;

		tbody {
			td {
				text-align: left;
				padding-left: 0;

				&.actions {
					padding: initial;
					// the rest of the td's are correct so this makes the actions follow there styling
				}
			}
		}
	}

	.order-products-toolbar {
		border-top: 0;
	}
}

.magento_rma-returns-view {
	.columns {
		.column.main {
			.block.block-return-details {
				margin-bottom: $row-gutter;

				.block-content {
					display: flex;

					.box {
						flex: 0 1 100%;

						@include min-screen($screen__m) {
							flex: 0 1 50%;
						}

						.box-title {
							@include type__11();
							margin-bottom: $item-gutter;
							display: block;
						}

						.box-content {
							@include type__16();
						}
					}
				}
			}

			.returns-details-items {
				.block.block-returns-items {
					margin-bottom: $row-gutter;
				}
			}
		}
	}
}
