.checkout-onepage-success {
	.page-main {
		padding: $page-padding-mobile;

		@include min-screen($screen__m) {
			padding: $page-padding;
		}
	}

	.checkout-success-title-wrapper {
		background-color: $checkout-success-message-background;
		padding: $checkout-success-message-inner-spacing $twenty-gutter;
		text-align: $checkout-success-message-text-align;
		color: $body-font-color;

		@include min-screen($screen__m) {
			padding: $checkout-success-message-inner-spacing;
		}

		.page-title-wrapper {
			background: none;
			padding: 0 0 $fifteen-gutter 0;

			@include min-screen($screen__m) {
				padding: 0 0 $five-gutter 0;
			}

			.page-title {
				span {
					@include type__22($color: $checkout-success-heading-color);

					@include min-screen($screen__m) {
						@include type__36($color: $checkout-success-heading-color);
					}
				}
			}
		}

		.checkout-success-summary {
			@include type__13($color: $checkout-success-text-color);

			strong {
				@include type__13($color: $checkout-success-text-color);
			}

			.order-reference {
				margin-bottom: $twenty-gutter * 0.75;

				span {
					display: block;
				}
			}
		}
	}

	.column.main {
		padding: 0;
	}

	// Create an account, delivery info, ordered items and payment details
	// ---------------------------------------------------------------------
	.checkout-success-order-account-info-wrapper {
		margin-bottom: $twenty-gutter;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include min-screen($screen__m) {
			flex-flow: row wrap;
			margin-bottom: rem(33);
		}

		// Registration Section - Please find code for
		// registration in _account-login.scss
		// -------------------------------------------------
		[id="registration"],
		.block-order-addresses {
			flex: 0 1 100%;

			@include min-screen($screen__m) {
				flex: 0 1 auto; // Flex basis set to auto here because it does not work with box-sizing: border-box
				width: calc(50% - #{$checkout-success-page-gutter} / 2); // Set the width instead of flex-basis
			}

			input[type="submit"] {
				// this silent class has been created and put in _shame-child cos its sad...
				@include button__default;
			}
		}

		// Delivery Addresses Section
		// -------------------------------------------------
		.block-order-addresses {
			display: flex;
			flex-flow: row wrap;
			border-bottom: $checkout-success-address-border;

			@include min-screen($screen__m) {
				border-top: $checkout-success-address-border;
				padding: $checkout-success-address-inner-spacing;
			}

			strong {
				display: block;
				margin-bottom: $twenty-gutter;

				span {
					@include type__20(
						$font-weight: $font-weight-bold,
						$color: $dusty-gray
					);
				}
			}

			.box-order-shipping-address,
			.box-order-billing-address {
				flex: 0 1 100%;

				@include min-screen($screen__s) {
					flex: 0 1 50%;
					padding-bottom: $twenty-gutter;
				}

				@include min-screen($screen__m) {
					padding-bottom: 0;
				}

				address {
					@include type__address;
					margin-bottom: 0;
				}
			}

			.box-order-billing-address {
				margin: $checkout-success-address-inner-spacing-mobile 0 0;
				padding: $checkout-success-address-inner-spacing-mobile;
				border-top: $checkout-success-address-border;

				@include min-screen($screen__s) {
					border: 0;
					margin: 0;
					padding: 0 0 0 $twenty-gutter;
				}
			}

			.contact-info {
				margin-top: $twenty-gutter;

				p:last-child {
					@include type__address;
				}
			}
		}

		// Register block
		// -------------------------------------------------
		[id="registration"] {
			margin: $twenty-gutter 0 0;
			background-color: $checkout-success-registration-background;
			padding: $twenty-gutter;

			@include min-screen($screen__m) {
				margin: 0;
				padding: $checkout-success-registration-inner-spacing;
			}

			h3 {
				@include type__20(
					$color: $white,
					$font-weight: $font-weight-bold
				);
				padding-bottom: $twenty-gutter;

				@include min-screen($screen__m) {
					padding-bottom: $checkout-success-registration-inner-spacing;
				}
			}

			ul {
				li {
					@include type__13(
						$color: $white,
						$font-weight: $font-weight-bold
					);

					&:last-child {
						padding-bottom: 0;
					}

					&:before {
						content: "";
						display: inline-block;
						width: 12px;
						height: 12px;
						margin-right: $checkout-success-registration-tick-spacing;
						@include svg-background(
							$name: tick-white,
							$size: contain
						);
					}
				}
			}

			a.action.primary {
				@include button__journey;
				display: block;
				text-align: center;
				margin-top: $twenty-gutter;
				width: 100%;

				@include min-screen($screen__m) {
					margin-top: em(25);
				}

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	// Items Ordered Section
	// -------------------------------------------------
	.checkout-success-order-items-wrapper {
		@include min-screen($screen__m) {
 			display: flex;
 			flex-wrap: wrap;
 		}

 		.block-order-details-items,
 		.block-order-totals {
 			@include min-screen($screen__m) {
	 			flex: 1;
	 		}
 		}

		.order-title {
			@include type__22;
			text-transform: uppercase;
			flex: 1 1 100%;
			padding-bottom: $twenty-gutter;
			margin-bottom: $twenty-gutter;
			border-bottom: $checkout-success-table-border;
			display: block;

			@include min-screen($screen__m) {
				@include type__29;
				padding-bottom: $checkout-success-table-title-spacing;
				margin-bottom: $checkout-success-table-title-spacing;
			}

			> span {
				@extend %visuallyhidden; // hides number of items in basket
			}
		}

		.block-order-details-items {
			margin-bottom: $checkout-success-page-gutter;

			@include min-screen($screen__m) {
				border-right: 1px solid $gallery;
				margin-right: $gutter;
				padding-right: $gutter;
			}

			.table-order-items {
				margin-bottom: 0;
				border-bottom: 1px solid $gallery;
				overflow: hidden;
				max-width: 100vw;

				tbody {
					border-bottom: $checkout-success-table-product-border;

					&:first-of-type {
						tr {
							margin-top: 0;
						}
					}

					&:last-child {
						border-bottom: 0;
					}

					tr { // product detail rows
						display: table;
						width: 100%;
						margin: $twenty-gutter 0;

						@include min-screen($screen__m) {
							margin: $checkout-success-table-inner-spacing;
						}

						.col {
							margin-left: $twenty-gutter;
						}

						.col,
						.cart-item-col {
							&.photo {
								margin-right: $twenty-gutter;
							}

							&.name {
								flex: 1 1 58%;
								margin-top: $ten-gutter;

								// Force the product quantity and price onto line underneath, due to lack of room
								 @include min-screen($screen__ll) {
								 	flex: 1 1 30%;
								 	padding-right: $item-gutter;
								 }
							}

							.item-options {
								cursor: pointer;
								@include type__13($font-weight: $font-weight-bold);
								margin: $ten-gutter 0;

								.content {
									margin-top: $ten-gutter;
								}

								dt,
								dd {
									display: block;
									@include type__13;
								}

								dt {
									font-weight: $font-weight-bold;
									margin-bottom: $five-gutter;
								}

								dd {
									margin-bottom: $five-gutter;
									margin-top: 0;

									&:last-child {
										margin-bottom: 0;
									}
								}
							}

							&.price {
								@extend %visuallyhidden; // hide product initial price
							}

							&.qty {
								text-align: right;
								flex: 1 1 50%;
								position: relative;
								top: 20px;
								text-transform: uppercase;

								.item {
									.title,
									.content {
										font-weight: $font-weight-bold;
									}
								}
							}

							&.subtotal {
								display: inline-block;
								text-align: left;

								.price {
									@include checkout-summary-price;
								}
							}

							&.name,
							&.qty {
								.product-item-name,
								.item,
								.title {
									@include type__13;
								}
							}
						}
					}
				}
			}
		}

		.table-wrapper {
			tbody {
				border-bottom: none;
			}
		}
	}

	// Order Totals Section
	// -------------------------------------
	.block-order-totals {
		.subtotal,
		.shipping,
		.tax,
		.grand_total,
		.discount {
			display: flex;
			justify-content: space-between;
			background-color: $wild-sand;
			padding: 0 $fifteen-gutter $twenty-gutter $fifteen-gutter;

			@include min-screen($screen__m) {
				padding: 0 $gutter $checkout-success-totals-inner-spacing $gutter;
			}

			&:first-child {
				padding-top: $twenty-gutter;

				@include min-screen($screen__m) {
					padding-top: $gutter;
				}
			}

			&:last-child {
				padding-bottom: 0;
			}

			.label,
			.detailed {
				@include type__20(
					$font-weight: $font-weight-bold,
					$color: $dusty-gray
				);
				order: 1;
			}

			.price {
				@include checkout-summary-price;
				text-transform: uppercase; // for when an item is free
				order: 2;
			}

			.payment {
				order: 2;
				flex: 0 1 100%;
				text-align: right;
			}
		}

		.grand_total {
			display: flex;
			flex-wrap: wrap;
			padding: 0;

			.label {
				padding-left: $fifteen-gutter;

				@include min-screen($screen__m) {
					padding-left: $gutter;
				}
			}

			.price {
				padding-right: $fifteen-gutter;

				@include min-screen($screen__m) {
					padding-right: $gutter;
				}
			}

			// " you paid using" block
			.payment {
				flex: 0 1 100%;
				text-align: left;
                padding-top: $twenty-gutter;
                padding-bottom: 0;
				margin-top: $twenty-gutter;
				@include type__13($font-weight: $font-weight-bold);
				background: $white;

				@include min-screen($screen__m) {
					padding-top: $checkout-success-page-gutter;
					margin-top: $checkout-success-page-gutter;
				}

				img {
					width: 49px;
					margin-left: $ten-gutter;
				}
			}
		}

		.tax {
			.details {
				@extend %visuallyhidden; // hides VAT
			}
		}

		.shipping {
			.label {
				display: flex;
				flex-direction: column; // delivery details on line underneath

				strong {
					@include type__13;
					padding-top: $fifteen-gutter;
					font-style: $checkout-success-totals-method-font-style;
				}
			}
		}
	}

	// Continue Shopping Section
	// -------------------------------------------------
	.block-products-list {
		display: none;

		@include min-screen($screen__m) {
			display: block;
			border-top: 1px solid $black;
			margin-bottom: $twenty-gutter;
		}

		.block-title {
			text-align: center;
			margin: $twenty-gutter * 3 auto $twenty-gutter * 2 auto;
		}

		.product-items {
			&.widget-product-grid {
				display: flex;
				margin-bottom: $twenty-gutter * 2;

				.product-item {
					flex: 0 1 50%;

					@include min-screen($screen__m) {
						flex: 0 1 percentage(1/3);
					}

					@include min-screen($screen__l) {
						flex: 0 1 percentage(1/6);
					}
				}
			}
		}
	}

	.card-icons-wrapper {
		display: none;
	}

	.cart-item-photo {
		position: relative;

		.cataloglabel {
			/* Display none as we want to remove labels on this page without disabling globally */
			display: none;
		}
	}
}
