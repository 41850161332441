/* -------------------------------------------------
 * -------------------------------------------------
 * Header styles for checkout
 *
 * TODO: Check this against Rokit code
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	.page-header {
		background-color: $checkout-header-background;
		top: 0;
		z-index: 1;
	}

	.checkout-panel-wrapper {
		background-color: $header-panel-background;
		padding: $ten-gutter;

		.countdown-container { // add this class on the PB row on .fish
			justify-content: center;
			flex-direction: row !important; // override inline pagebuilder style of column

			.countdown-clock {
				text-align: center;
				text-transform: uppercase;
				@include type__12(
					$color: $header-panel-color,
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__m) {
					@include type__15(
						$color: $header-panel-color,
						$font-weight: $font-weight-semibold
					);
				}

				.time-remaining {
					color: $radical-red;
				}
			}

			a {
				display: none;
				text-transform: none;
				margin-left: rem(7);
				@include type__13(
					$color: $white,
					$font-weight: $font-weight-semibold
				);

				@include min-screen($screen__m) {
					display: inline-block;
				}
			}
		}
	}

	.header.content {
		// Not nested in .page-header to make it easier to override in child theme
		// this should take its styles from _header.scss, class has been chained to match
		padding: $checkout-header-mobile-padding;
		height: auto;
		background-color: $checkout-header-background;

		@include min-screen($screen__m) {
			padding: $checkout-header-padding;
		}

		div[id="progress-bar"] {
			display: flex; // for vertical alignment
			justify-content: center;
			flex: 1 1 100%;
			order: $checkout-mobile-header-steps-flex-order;
			border-top: $checkout-header-border;
			padding-top: $ten-gutter;
			margin-top: $ten-gutter;

			@include min-screen($screen__m) {
				flex: $checkout-progress-bar-flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: 0;
				order: $checkout-header-steps-flex-order;
				border: 0;
				padding: 0;
			}

			.opc-progress-bar {
				display: flex;

				.opc-progress-bar-item {
					color: $checkout-steps-color;
					display: flex;
					align-items: center;
					text-transform: uppercase;

					&:before {
						content: '';
						display: inline-block;
						background-size: contain;
						margin-right: $ten-gutter;
						height: 31px;
						width: 31px;
							
						@include min-screen($screen__m) {
							height: $checkout-steps-height;
							width: $checkout-steps-width;
						}
					}

					span {
						@include type__14($color: $checkout-steps-color);
						
						@include min-screen($screen__m) {
							@include type__16($color: $checkout-steps-color);
						}
					}

					&:first-child {
						margin-right: $twenty-gutter;
						opacity: 1;

						&:before {
							@include svg-background('step1-checkout');
						}
					}

					&:last-child {
						opacity: $checkout-steps-inactive-color;

						&:before {
							@include svg-background('step2-checkout');
						}

						&._active {
							opacity: 1;
						}
					}

					&._complete {					
						&:before {
							@include svg-background('completed-step');
						}
					}
				}
			}
		}

		.checkout-header-security {
			@include type__12($color: $checkout-secure-color);
			order: $checkout-mobile-header-secure-flex-order;
			flex: $checkout-secure-flex;
			display: flex; // for vertical align
			align-items: center;
			text-transform: uppercase;
			justify-content: flex-end;

			@include min-screen($screen__m) {
				@include type__16($color: $checkout-secure-color);
				order: $checkout-header-secure-flex-order;
				flex: 0 1 28%;
			}

			@include min-screen($screen__l) {
				flex: $checkout-secure-flex;
			}

			svg {
				width: 13px;
				height: auto;
				margin-right: $checkout-secure-icon-margin-right;
				
				@include min-screen($screen__m) {
					width: 25px;
				}
			}
		}

		.logo {
			order: $checkout-mobile-header-logo-flex-order;
			flex: $checkout-logo-flex;
			justify-content: flex-start;

			@include min-screen($screen__m) {
				order: $checkout-header-logo-flex-order;
				justify-content: center;
				padding-left: $twenty-gutter;
			} // Center align the logo - nested because no class on output
			// we do not want this to affect any other links in the header

			@include min-screen($screen__ml) {
				padding-left: 0;
			}

			a {
				display: block;
				padding: 0;
				margin: 0;
				max-width: $logo-mobile-max-width;
				width: 59px;
				height: auto;

				@include min-screen($screen__m) {
					width: auto;
					max-width: $logo-max-width; // This won't resize at all. Needed to reduce hit area on logo
				}
			}
		}
	}

	.page-title-wrapper {
		display: none;
	}
}
