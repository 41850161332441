/* -------------------------------------------------
 * -------------------------------------------------
 * Products when in basket
 *
 * This should be reusable in chekout summary too.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.cart.items.table {
			.block-cart-item {
				border-bottom: $checkout-success-table-product-border;

				&.removing {
					position: relative;

					&:before {
						@extend %element-loader;
						content: '';
						display: block;
						position: absolute;
					}

					.cart-item-row {
						opacity: 0.3;
					}
				}

				.cart-item-row { // product detail rows
					display: flex;
					align-items: center;
					margin: $fifty-gutter 0;
					position: relative;

					@include min-screen($screen__m) {
						flex-wrap: nowrap;
						margin: $forty-gutter 0;
					}

					.col,
					.cart-item-col {
						&.photo {
							align-self: flex-start;
							margin: 0 $fifteen-gutter 0 0;
							flex: 0 0 auto;

							.product-image-photo {
								padding-right: $item-gutter;
								width: em(134);

								@include min-screen($screen__ml) {
									flex: auto;
								}
							}

							.product-image-wrapper {
								padding-bottom: 0 !important; // Important to override inline padding
							}
						}

						&.details {
							display: flex;
							flex-direction: column;
							padding: 0;
							flex: 1 1 auto;

							@include min-screen($basket-items-breakpoint) {
								align-items: center;
								flex-direction: row;
								flex-wrap: wrap;
								justify-content: space-between;
							}

							> a {
								padding-bottom: $basket-items-name-spacing;
								@include type__13;

								@include min-screen($basket-items-breakpoint) {
									width: 100%;
									padding-bottom: $ten-gutter;
								}
							}

							.cart-item-info {
								.item-options {
									.toggle {
										@include type__13($font-weight: $font-weight-bold);
										cursor: pointer;
										display: block;
										margin-bottom: $ten-gutter;

										@include min-screen($screen__m) {
											margin-bottom: $ten-gutter 0;
										}
									}

									.hide-options {
										display: none;
									}

									.content {
										margin-top: $item-gutter;

										dt,
										dd {
											@include type__13;
											text-transform: capitalize;
										}

										dt {
											font-weight: $font-weight-bold;
											margin-bottom: $five-gutter;
										}

										dd,
										.uploaded-image {
											margin-bottom: $ten-gutter;
										}
									}
								}

								.item-options.active {
									.show-options {
										display: none;
									}

									.hide-options {
										display: block;
									}
								}
							}
						}

						&.subtotal {
							@extend %visuallyhidden; // hide product initial price
						}

						&.cart-details {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-around;
							bottom: 0;
							padding: 0;

							@include min-screen($basket-items-breakpoint) {
								width: 100%;
							}

							.price-including-tax-label {
								@include type__13(
									$color: $radical-red,
									$font-weight: $font-weight-medium
								);
								margin-left: rem(3);
							}
						}

						&.qty {
							position: relative;
							order: $basket-items-qty-order;
							margin-right: $ten-gutter;
							display: flex;

							@include min-screen($basket-items-breakpoint) {
								margin-right: $basket-items-qty-spacing;
							}

							.cart-product-actions {
								display: none; // Shown with script
								position: absolute;
								left: $fifteen-gutter; // Width of QTY + small-gutter
								bottom: 0;
								top: em(47);

								@include min-screen($screen__ml) {
									left: $gutter;
								}

								.button {
									@include button__default(
										$padding: $five-gutter $ten-gutter
									);
								}
							}
						}

						&.price {
							margin-right: $basket-items-price-spacing;
							padding-left: 0;
							align-self: center;
							flex: auto;

							span.price {
								@include checkout-summary-price;
								text-align: right;
							}
						}

						&.name,
						&.qty {
							.product-item-name,
							.item,
							.title {
								@include type__16 (
									$color: $body-font-color,
									$font-weight: $font-weight-normal
								);
							}
						}

						.cart-item-action {
							order: $basket-items-actions-order;
							align-self: center;
							margin-left: auto;
							padding: $ten-gutter 0;
						}

						.action-edit {
							display: none; // Not usually needed but may be used in the future
						}

						.action-delete {
							display: flex;

							.label {
								@include icon__cross-white;

								&:after {
									margin: 0;
								}
							}
						}

						.cataloglabel {
							/* Display none as we want to remove labels on this page without disabling globally */
							display: none;
						}
					}
				}
			}
		}
	}

.cart-item-name-wrapper {
	display: grid;

	.product-item-sku {
		@include type__11($font-weight: $font-weight-normal);
	}

	@include min-screen($screen__m) {
		grid-template-columns: 3fr 1fr;
		gap: 0;
	}
}
