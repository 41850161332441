/* -------------------------------------------------
 * Layout and general styles for checkout
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	.main {
		padding: 0;
	}

	.opc-wrapper {
		border: none;
		padding: rem(24) rem(16) 0;
		width: 100%;

		@include min-screen($screen__l) {
			padding: rem(24) 0 rem(32) rem(108);
			width: $checkout-sidebar-width;
		}
	}

	.opc-sidebar {
		margin-top: rem(48);
		padding-left: 0;
		width: 100%;

		@include min-screen($screen__l) {
			margin-top: 0;
			padding-left: rem(80);
			width: $checkout-sidebar-width;
		}

		div[id="opc-sidebar"] {
			display: block;
			padding-top: 0;
		}
	}
}
