/* -------------------------------------------------
 * Object Variables
 * -------------------------------------------------
 * Add variables when required during theme build
 * Look at tpm default theme to get var names
 * dont make new ones
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Tooltip Variables
// -------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 2. Spinner Variables
// ------------------------------------------------

// tbc here for organisation purposes

// -------------------------------------------------
// 3. Button Variables
// please dont change these vars
// if your button doesnt match designs
// try @include button__journey;
// -------------------------------------------------

$button-defaul-padding: rem(13) $twenty-gutter rem(11);
$button-default-background: transparent;
$button-default-border: 2px solid $keppel;
$button-default-radius: 2px;
$button-default-color: $keppel;
$button-default-weight: $font-weight-semibold;
$button-default-background-hover: $keppel;
$button-default-border-hover: 2px solid $keppel;
$button-default-color-hover: $white;

/* please dont change these vars
 * if your button doesnt match designs
 * try @include button__journey;
 * Big primary button, used for the journey
 */
$button-journey-background: $keppel;
$button-journey-border: 2px solid $keppel;
$button-journey-border-focus: 2px solid $keppel-light;
$button-journey-padding: rem(13) $twenty-gutter rem(11);
$button-journey-radius: 2px;
$button-journey-color: $white;
$button-journey-weight: $font-weight-semibold;

// Pagination buttons on category pages
$button-pagination-background: transparent;
$button-pagination-arrow-background: transparent;
$button-pagination-current-background: $grey-light;
$button-pagination-current-color: $ebony-clay;
$button-pagination-padding: rem(8);
$button-pagination-radius: 0;
$button-pagination-width: rem(48);
$button-pagination-height: rem(48);
$button-pagination-color: $ebony-clay;


// -------------------------------------------------
// 4. Input Variables
// -------------------------------------------------
$field-focus-border: 1px solid $keppel;
$field-outline-shadow: 0 0 0 4px rgba($keppel, 0.3);
$field-error-outline-shadow: 0 0 0 4px rgba(192, 49, 49, 0.3);
$field-error-border: 1px solid $error-color; /* no point using a car as not used elsewhere */

// input-style-one
$field-color: $edward;
$field-placeholder-color: $edward;
$field-background-color: $white;
$field-border: 2px solid $mercury;
$field-border-hover: 2px solid $edward;
$field-border-focus: $field-focus-border;
$field-border-radius: $border-radius-s;
$field-required-input-color: $keppel;
$field-color-focus: $edward;
$field-padding: rem(7) rem(12); // 10px 16px / 16 for em value


// input-style-two (For search and some other inputs)
$field-two-color: $grey-medium;
$field-two-background-color: $white;
$field-two-border: 1px solid $gray;
$field-two-border-hover: 1px solid $gray;
$field-two-border-focus: 1px solid $edward;
$field-two-border-radius: $border-radius-s;
$field-two-required-input-color: $grey-medium;
$field-two-color-focus: $brand-accent;
$field-two-padding: rem(9) $twenty-gutter; // 10px 16px / 16 for em value
$field-two-placeholder-color: $grey-medium;
$field-two-placeholder-weight: $font-weight-normal;
$field-two-placeholder-font-style: normal;

// input-style-three (QTY)
$field-three-color: $white;
$field-three-background-color: $dusty-gray;
$field-three-border: 0;
$field-three-border-hover: 0;
$field-three-border-focus: 0;
$field-three-border-radius: 100px;
$field-three-placeholder-color: $white;
$field-three-width: em(52);
$field-three-padding: rem(9) rem(14); // 10px 16px / 16 for em value

// select-style-one
$select-color: $ebony-clay;
$select-weight: $font-weight-normal;
$select-background-color: $white;
$select-border: 1px solid $mercury;
$select-border-hover: 1px solid $edward;
$select-border-focus: $field-focus-border;
$select-border-radius: $border-radius-s;
$select-required-input-color: $ebony-clay;
$select-color-focus: $ebony-clay;
$select-padding: rem(7) rem(12);
$select-arrow-background-position: calc(100% - #{$twenty-gutter}) 50%;
$select-arrow-background-size: rem(10);

// select-style-two
$select-two-color: $ebony-clay;
$select-two-weight: $font-weight-semibold;
$select-two-background-color: transparent;
$select-two-border-top: 1px solid $gray;
$select-two-border-bottom: 1px solid $gray;
$select-two-border-left: 1px solid $gray;
$select-two-border-right: 1px solid $gray;
$select-two-border-hover: 1px solid $gray;
$select-two-border-focus: 1px solid $gray;
$select-two-border-radius: 1px solid $gray;
$select-two-required-input-color: $ebony-clay;
$select-two-color-focus: $ebony-clay;
$select-two-padding: rem(7) rem(12);
$select-two-arrow-background-position: calc(100% - #{$twenty-gutter}) 50%;
$select-two-arrow-background-size: rem(10);

// select-style-three
$select-three-color: $ebony-clay;
$select-three-weight: $font-weight-semibold;
$select-three-background-color: $gallery;
$select-three-border: 1px solid $gallery;
$select-three-border-color-hover: $silver;
$select-three-border-focus: 0;
$select-three-border-radius: 40px;
$select-three-padding: $ten-gutter $twenty-gutter;
$select-three-arrow-background-position: 90% 50%;
$select-three-arrow-background-size: 22px 11px;

// select-style-one
$select-color: $ebony-clay;
$select-weight: $font-weight-normal;
$select-background-color: $white;
$select-border: 1px solid $gray;
$select-border-hover: 1px solid $keppel;
$select-border-focus: 1px solid $keppel;
$select-border-radius: 4px;
$select-required-input-color: $keppel;
$select-color-focus: $ebony-clay;
$select-padding: rem(8) rem(14); // to match values of general input fields
$select-arrow-background-position: calc(100% - #{$small-gutter}) 50%;
$select-arrow-background-size: rem(10);

$select-two-border-top: 1px solid $grey-light;
$select-two-border-bottom: 1px solid $grey-light;
$select-two-border-left: 1px solid $grey-light;
$select-two-border-right: 1px solid $grey-light;
$select-two-background-color: $grey-light;
$select-two-color: $ebony-clay;
$select-two-border-radius: 4px;
$select-two-padding: rem(8) rem(36) $ten-gutter rem(8);
$select-two-weight: $font-weight-normal;
$select-two-arrow-background-size: rem(10);
$select-two-arrow-background-position: calc(100% - #{$small-gutter}) 50%;

// Misc
$checkbox-width: 24px;
$checkbox-height: 24px;
$radio-width: 24px;
$radio-height: 24px;
$radio-margin-right: 8px; // The spacing between a checkbox / radio button and its label.
$checkbox-margin-right: 8px; // The spacing between a checkbox / radio button and its label.

// -------------------------------------------------
// 5. Modal Variables
// -------------------------------------------------

$modal-gutter-mobile: $gutter $small-gutter; // Gutters for modals
$modal-gutter-desktop: $gutter; // Gutters for modals
$modal-max-width: rem(600);

// -------------------------------------------------
// 6. Price Variables
// -------------------------------------------------
