/* -------------------------------------------------
 * Checkout Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Category Title Spacing
 * 2. - product listing
 *	2a. - product listing Item Spacing
 * 3. Category Seo Footer Content Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Category Title
// -------------------------------------------------

$category-title-spacing-mobile: $medium-gutter 0 rem(28) 0; // padding for category titke and search results
$category-title-spacing: $heading-gutter $twenty-gutter;
$category-subtitle-spacing-mobile: $twenty-gutter;
$category-subtitle-spacing: $small-gutter;
$category-description-spacing-mobile: $twenty-gutter;
$category-description-spacing: $small-gutter;


// -------------------------------------------------
// 2. Product Listing Variables
// -------------------------------------------------

// 2a. Product Listing Item Spacing
$category-page-padding-mobile: 0;
$category-product-listing-spacing: 0;
$category-sub-category-padding-mobile: rem(8) rem(19) rem(8) $small-gutter;
$category-sub-category-padding-desktop: 0;

$category-product-listing-spacing-mobile: $small-gutter;
$category-product-listing-spacing: 0;
$category-product-listing-button-display: none;
$category-product-listing-wishlist-display: none; // Set either none or inline-flex
$category-product-listing-compare-display: none; // Set either none or inline-flex
$category-product-listing-preorder-display: none; // Set either none or inline-flex
$category-compare-wishlist-spacing: 0 $small-gutter / 3; // creates spacing between icon and text
$category-swatches-flex: 0 1 25%;

