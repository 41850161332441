/* -------------------------------------------------
 * -------------------------------------------------
 * Report damages page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.cms-report-a-problem-or-damages {
	.wufoo-form-container {
		padding: 0 1rem;

		@include min-screen($screen__m) {
			padding: 0;
		}
	}
}
