/* -------------------------------------------------
 * Layout Variables
 * -------------------------------------------------
 * Most of these vars are inherited from tpm theme
 * We shouldnt need to add more just change values
 *
 * Table of contents
 * 1. - Minicart Variables
 * 2. - Layered Nav Variables
 * 3. - Grid Variables
 * 4. - Added To Basket Popup Variables
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Minicart Variables
// -------------------------------------------------

$minicart-count-color: $white;
$minicart-count-bubble-inline-margin-left: 0;
$minicart-count-bubble-top: rem(11); // Top position of counter when in bubble
$minicart-count-bubble-top-desktop: rem(17);
$minicart-count-bubble-right: rem(-6);
$minicart-count-bubble-right: rem(-4);
$minicart-count-bubble-width: 12px; // Background colour of the QTY count for basket (when set to true)
$minicart-count-bubble-height: 12px; // Background colour of the QTY count for basket (when set to true)
$minicart-count-bubble-width-tablet: 12px;
$minicart-count-bubble-height-tablet: 12px;
$minicart-count-bubble-width-desktop: 12px;
$minicart-count-bubble-height-desktop: 12px;
$minicart-count-padding-top: 2px;


// -------------------------------------------------
// 2. Layered Nav Variables
// -------------------------------------------------

// tbc - here for organisation purposes

// -------------------------------------------------
// 3. Grid Variables
// -------------------------------------------------

// tbc - here for organisation purposes

// -------------------------------------------------
// 4. Header Styles
// -------------------------------------------------

$header-desktop-content-height: 84px; // This only includes header.content

$header-background: $white;
$header-mobile-background: $white;
$header-desktop-background: $white;
$header-icons-color: $grey-dark; // Icon fill colour for currency, basket and account icons
$header-icons-color-active: $grey-dark;// active Icon fill colour for currency, basket and account icons
$header-icons-width: 20px; // SVG icon width
$header-icons-height: rem(20); // SVG icon height

$header-links-label-color: $grey-dark; // Colour of links labels, usually same as the icon colour
$header-links-label-color-active: $grey-dark; // Colour of links labels when active
$header-links-flex-direction: column; // Controls whether links label sits right or below. Set either 'row' or 'column'

$header-mobile-justify-content: space-between;
$header-mobile-content-height: auto;
$header-mobile-height: $header-mobile-content-height; // This is the total height of any content that needs to be fixed so that when the fixed option is selected the correct margin can be applied to the body

$desktop-header-nav-height: 46px; // total height of main nav

$header-search-dropdown-top: rem(54);
// Logo on desktop won't resize but the flex container and <a> around it does.
// so we need to keep a fixed width based on the logo size to reduce the actual hit area of the <a>
$logo-max-width: none;
$logo-tablet-max-width: none;
$logo-mobile-max-width: none;

$dropdown-top-position-mobile: rem(58);
$dropdown-top-position-desktop: $header-desktop-content-height; // This controls where the account menu / mini basket and any other header icons that would have an additional menu open from
$dropdown-width: em(344); // Width of dropdown boxes (Basket, account, etc)
$dropdown-shadow: 0 4px 45px rgba(0, 0, 0, 0.2);
$dropdown-background-color: $white;
$dropdown-arrow-colour: $white;
$dropdown-enable-triangle: false; // Set true to show a triangle pointer for the dropdown and false for a background colour instead

// The order of these elements change on a per site basis.
// This sets the Flex order so that we can maintain markup
// quality and correct order for accessibility
$header-mobile-burger-flex-order: 1;
$header-mobile-logo-flex-order: 2;
$header-mobile-search-flex-order: 4;
$header-mobile-minicart-flex-order: 3;
$header-mobile-links-flex-order: 3;

$header-burger-flex-order: 1; // hidden by default
$header-logo-flex-order: 2;
$header-search-flex-order: 3;
$header-minicart-flex-order: 4;
$header-links-flex-order: 0;

// -------------------------------------------------
// 5. Menu Styles
// -------------------------------------------------

$desktop-nav-items-spacing: 0 rem(7); // Spacing (padding) between each menu item

$mega-menu-background-color: $white;

// -------------------------------------------------
// 6. Added to basket popup styles
// -------------------------------------------------

$popup-background-color: $white;
$basket-popup-inner-model-max-width: rem(520); // usually slightly smaller than other popup models

// -------------------------------------------------
// 7. "Great For" Banners - Styles in _banners.scss
// -------------------------------------------------

// tbc - here for organisation purposes

// -------------------------------------------------
// 8. Brands Banners - Styles in _banners.scss
// -------------------------------------------------

// tbc - here for organisation purposes

// -------------------------------------------------
// 9. Out Story - Styles in _banners.scss
// -------------------------------------------------

// tbc - here for organisation purposes

// -------------------------------------------------
// 9. Footer - Styles in _footer.scss
// -------------------------------------------------

$footer-row-one-padding-mobile: $gutter $small-gutter $medium-gutter $small-gutter;
$footer-row-one-padding-desktop: $forty-gutter;
$footer-row-two-padding-mobile: $forty-gutter $small-gutter rem(56) $small-gutter;
$footer-row-two-padding-desktop: $forty-gutter $gutter;
$footer-row-two-padding-border: 1px solid $charcoal-grey;
