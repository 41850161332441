/* -------------------------------------------------
 * -------------------------------------------------
 * Account Menu and collapsible
 *
 * -------------------------------------------------
 * @author

 */

/* Mobile
 * -------------------------------------------------
 */
.customer-nav-mobile {
	border-bottom: 1px solid $mercury;

	.account-nav-title {
		min-height: rem(60);
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-left: 4px solid $keppel;
		cursor: pointer;
		padding: 0 rem(22) 0 rem(13);

		&.active {
			&:after {
				transform: rotate(180deg);
			}
		}

		&:after {
			@include svg-background(
				$name: arrow-down,
				$size: contain
			);
			content: '';
			height: rem(7);
			width: rem(12);
			display: block;
		}

		strong {
			@include type__16(
				$font-weight: $font-weight-semibold,
				$line-height: rem(28)
			)
		}
	}
}

/* Desktop
 * -------------------------------------------------
 */

.sidebar-main {
	display: none; /* hidden on mobile as its content is moved with js */

	@include min-screen($screen__m) {
		display: block;
		float: none;
	}

	.account-sidebar-title {
		@include type__18(
			$font-weight: $font-weight-semibold
		);
		padding: $medium-gutter $medium-gutter $small-gutter $medium-gutter;
		background-color: $silver;
	}
}

/* Base
 * -------------------------------------------------
 */

.account-nav {
	@include min-screen($screen__m) {
		background-color: $silver;
		padding-bottom: rem(11);
	}

	.items {
		.item {
			@include type__14;

			@include min-screen($screen__m) {
				padding: 0 $medium-gutter;
			}

			a,
			&.current strong {
				border-bottom: 1px solid $mercury;
				padding: rem(20) $small-gutter;
				display: block;
				font-weight: $font-weight-normal;
				text-transform: lowercase;

				@include min-screen($screen__m) {
					border-bottom: 0;
					padding: rem(10) 0;
				}

				&::first-letter {
					text-transform: uppercase;
				}
			}

			&:not(.current) + .item .delimiter {
				@include min-screen($screen__m) {
					display: block;
					border-top: 1px solid $mercury;
				}
			}

			&:first-child {
				a,
				strong {
					border-top: 1px solid $mercury;
				}
			}

			&:last-child {
				a {
					gap: $five-gutter;

					&:before {
						@include svg-background(
							$name: sign-out,
							$size: contain
						);
						content: '';
						height: rem(18);
						width: rem(20);
						display: block;
					}

					@include min-screen($screen__m) {
						min-height: rem(49);
					}
				}
			}
		}

		.item.current {
			@include min-screen($screen__m) {
				border-left: 4px solid $keppel;
				padding-left: $twenty-gutter;
			}

			strong {
				@include min-screen($screen__m) {
					border-bottom: 1px solid $mercury;
					min-height: rem(46);
				}
			}
		}
	}
}
