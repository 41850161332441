.checkout-onepage-success {
	.checkout-success-title-wrapper {
		.page-title-wrapper {
			.page-title {
				text-align: center;
			}
		}
	}

	.cart-item-cell.cart-item-photo .cataloglabel {
		/* Display none as we want to remove labels on this page without disabling globally */
		display: none;
	}
}
