/* -------------------------------------------------
 * -------------------------------------------------
 * Base Styles
 * Mostly inherited from trophies. Only small changes here
 *
 * -------------------------------------------------
 * @author Fisheye
 */

html {
	font-size: 16px;
}

strong {
	font-weight: $font-weight-semibold;
}

.page-wrapper {
	min-height: 100vh;
}
// default focus styles
:focus {
	._keyfocus & {
		outline: solid 1px $focus-color-default;
		box-shadow: 1px 1px 5px $focus-color-default;
	}
}
