/* -------------------------------------------------
 * -------------------------------------------------
 * Slick Slider Styles
 * Basic Slick Styles, including arrow & dot styles
 * --------------------------------------------------
 * @author Fisheye
 */

 // Basic Slick Arrow Styles

.slick-slide {
	> div {
		line-height: 0; // Some weird line height issue in an empty element, set to 0 to prevent whitespace
	}
}

.slick-arrow {
	display: none !important; // Always hidden on mobile override inline styles

	&.slick-prev,
	&.slick-next {
		@include min-screen($screen__m) {
			@include svg-background(
				$name: button-slider-arrow,
				$size: rem(48)
			);
			width: rem(48);
			height: 100%;
			right: 0;
			display: block !important; // Always hidden on mobile override inline styles

			background-color: transparent;
		}
	}

	&.slick-prev {
		@include min-screen($screen__m) {
			transform: rotate(180deg);
			left: 0;
			right: auto;
		}
	}

	&.slick-disabled {
		display: none !important; // Hidden when disabled
	}
}

.slick-dots {
	margin-bottom: $five-gutter;

	li {
		padding: 0 rem(4);

		button {
			width: rem(8);
			height: rem(8);
			background-color: $gray;
		}

		&.slick-active {
			button {
				background-color: $keppel;
			}
		}
	}
}
