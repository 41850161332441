/* -------------------------------------------------
 * -------------------------------------------------
 * Summary totals
 *
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 3. Cart summary, prices, etc
// -------------------------------------------------

.cart-summary {
	background: $basket-summary-background;
	padding: 0;

	.summary.title {
		@extend %visuallyhidden; // Hide the summary title
	}

	.totals.sub {
		display: flex;
		justify-content: space-between;

		.price {
			float: right;
		}
	}

	.totals-tax-summary {
		.mark,
		.amount {
			padding-bottom: $twenty-gutter;
		}
	}

	.totals-tax-details {
		display: none; // remove VAT tax details
	}

	.cart-totals {
		position: relative;
	}

	form[id="co-shipping-method-form"],
	.cart-totals {
		.loading-mask {
			width: 100%;
			background: rgba(255, 255, 255, 0.8);
		}

		.loader {
			position: absolute;
		}
	}

	.cart-totals {
		th,
		td {
			padding: 0;
		}

		th {
			text-align: left;
		}

		td {
			text-align: right;
		}
	}

	.totals.sub,
	.shipping strong,
	.mark span,
	.mark strong {
		@include type__16(
			$color: $dusty-gray,
			$font-weight: $basket-summary-total-names-weight
		);

		@include min-screen($screen__m) {
			@include type__20(
				$color: $dusty-gray,
				$font-weight: $basket-summary-total-names-weight
			);
		}
	}

	.amount,
	.price {
		@include checkout-summary-price;
		padding-left: $ten-gutter;
	}

	.grand.totals {
		th,
		td {
			padding: 0;
		}
	}

	.totals.sub,
	.block.shipping,
	.cart-totals {
		padding: $basket-summary-totals-vertical-spacing;

		@include min-screen($screen__m) {
			padding: $gutter $gutter $basket-summary-totals-vertical-spacing $gutter;
		}
	}

	.totals {
		th,
		td {
			padding-bottom: $basket-summary-totals-vertical-spacing;
		}
	}

	.block.shipping {
		cursor: pointer;
		position: relative;
		padding-top: 0;
		padding-bottom: 0;

		.title {
			&:after {
				content: '';
				position: absolute;
				width: $basket-promo-icon-size;
				height: $basket-promo-icon-size;
				right: rem(30);

				@include svg-background(
					$name: arrow-down,
					$size: contain
				);
			}
		}

		.field {
			margin-bottom: $twenty-gutter;

			.label {
				> span {
					@include type__14;
				}
			}

			.select{
				@include input-style-one;
			}

			.label,
			.control {
				flex: 1 1 100%;
			}
		}

		&.active {
			.title {
				&:after {
					@include svg-background('arrow-up');
				}
			}
		}

		.item-options {
			.label {
				display: flex;
				align-items: center;
				@include type__13($font-weight: $font-weight-bold);

				.price {
					margin-left: $ten-gutter;
					@include type__13($font-weight: $font-weight-bold);
					flex: 1;
					text-align: right;
				}
			}
		}
	}

	.cart-totals {
		padding-top: $basket-summary-totals-vertical-spacing;
	}

	// -------------------------------------------------
	// Delivery estimates area
	// -------------------------------------------------

	.fieldset.estimate {
		.legend span {
			@extend %visuallyhidden; // Hide legend from view
		}

		br {
			display: none; // Remove stray br causing space
		}

		.field.note {
			@include type__13;
			margin: $twenty-gutter 0;
		}

		input,
		select {
			width: 100%;
		}
	}

	form[id="co-shipping-method-form"] {
		.note {
			@include type__14;
		}
	}

	.fieldset.rate {
		.item-title span {
			display: none;
		}

		label {
			margin: 0;
		}
	}

	.payment__cards {
		display: block;
		margin: $basket-summary-card-icons-vertical-spacing auto 0;
		width: 100%;
	}
}

// -------------------------------------------------
// Journey buttons
// -------------------------------------------------

.checkout-methods-items {
	.item {
		&:first-child {
			display: flex;
		}
	}

	button {
		margin: $basket-summary-button-vertical-spacing 0;
	}

	.primary {
		@include button__journey(
			$padding: rem(11) $twenty-gutter rem(9)
		);
		width: 100%;
		margin: 0 $twenty-gutter $twenty-gutter $twenty-gutter;

		// Custom breakpoints to match that of the paypal button
		@include min-screen(386px) {
			padding: rem(16) $twenty-gutter rem(14);
		}

		// Custom breakpoints to match that of the paypal button
		@include min-screen(605px) {
			padding: rem(21) $twenty-gutter rem(19);
		}

		@include min-screen($screen__m) {
			margin: 0 $basket-summary-button-vertical-spacing $basket-summary-button-vertical-spacing;
			padding: rem(9) $five-gutter rem(7);
		}

		// Custom breakpoints to match that of the paypal button
		@include min-screen(836px) {
			padding: rem(15) $twenty-gutter rem(13);
		}

		// Custom breakpoints to match that of the paypal button
		@include min-screen(1245px) {
			padding: rem(19) $twenty-gutter rem(17);
		}

		span {
			font-weight: $font-weight-extrabold;
		}

		&[disabled="disabled"] {
			&:after {
				content: '';
				background-color: rgba(255, 255, 255, 0.8);
				@extend %element-loader;
				width: auto;
				height: auto;
				background-size: 60px; // match size on checkout
				display: block;
				z-index: 1;
			}
		}
	}

	.express-checkout {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		border-top: 1px solid $body-background-color;
		padding: 0 $twenty-gutter rem(24) $twenty-gutter;

		.express-checkout-title {
			flex: 1 1 100%;
			text-transform: capitalize;
			margin: rem(16) 0;

			@include min-screen($screen__m) {
				margin: rem(24) 0;
			}

			@include type__13(
				$font-weight: $font-weight-bold
			);
		}

		.express-checkout-options {
			width: 100%;
		}

		.paypal-button {
			text-align: center;

			.zoid-outlet {
				width: 100%;
			}

			.paypal-buttons-context-iframe {
				z-index: 0;
			}
		}
	}

	.paypal {
		width: 100%;
		cursor: pointer;

		button {
			-webkit-appearance: none;
			border: none;
			width: 100%;
			background: $basket-summary-paypal-button-background;

			img {
				max-height: $basket-summary-paypal-button-max-height;
			}
		}
	}
}
