/* -------------------------------------------------
 * -------------------------------------------------
 * Customer dashboard
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.customer-account-index {
	.customer-account-dashboard {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: $small-gutter;

		@include min-screen($screen__ml) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: rem(18);
		}

		@include min-screen($screen__ll) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		.subtitle {
			grid-column: span 1 / span 1;

			@include min-screen($screen__ml) {
				grid-column: span 2 / span 2;
			}

			@include min-screen($screen__ll) {
				grid-column: span 3 / span 3;
			}

			h2,
			h3 {
				@include type__20(
					$line-height: rem(34)
				);

				@include min-screen($screen__m) {
					@include type__24(
						$line-height: rem(34)
					);
				}
			}
		}

		.block {
			border: 1px solid $mercury;
			border-radius: $border-radius-m;
			background-color: $wild-sand;
			padding: $small-gutter;

			h3 {
				@include type__16(
					$font-weight: $font-weight-semibold,
					$line-height: rem(28)
				);
			}

			p,
			address {
				@include type__14;
			}
		}

		.block-content {
			height: 100%;
		}

		.box {
			display: flex;
			flex-direction: column;
			gap: $ten-gutter;
			height: 100%;
		}

		.box-actions {
			text-align: right;
			margin-top: auto;

			a {
				@include type__14(
					$font-weight: $font-weight-semibold,
					$color: $keppel
				);
				text-align: right;
			}
		}

		.block-dashboard-info {
			order: 2;

			@include min-screen($screen__ml) {
				grid-column: span 2 / span 2;
			}

			@include min-screen($screen__ll) {
				grid-column: span 1 / span 1;
			}

			.box-actions {
				a:not(:last-child) {
					padding-right: rem(16);
				}
			}
		}
	}
}
