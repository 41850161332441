/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Statis Page specific Styling
 * Usually populated through bluefoot
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.cms-page-view {
	.page-title-wrapper {
		width: 100%;
		padding: rem(33) $small-gutter rem(9) $small-gutter;

		@include min-screen($screen__m) {
			padding: 0 0 rem(28) 0;
		}

		.base {
			line-height: rem(56);
		}
	}

	.page-main {
		padding-bottom: $ten-gutter;
		overflow: hidden;

		@include min-screen($screen__m) {
			padding: $forty-gutter $gutter;
		}

		.columns {
			padding: 0;

			@include min-screen($screen__m) {
				gap: $forty-gutter;
			}
		}

		.main {
			> [data-content-type='row']:not([data-appearance='full-bleed']) {
				padding-left: $small-gutter;
				padding-right: $small-gutter;

				@include min-screen($screen__m) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		[data-content-type="text"] {
			ul {
				li {
					align-items: center;
					display: flex;
					gap: $five-gutter;

					&:before {
						@include svg-background(
							$name: green-tick,
							$size: contain
						);
						content: '';
						width: rem(17);
						height: rem(12);
						min-width: rem(17);
						display: block;
					}
				}
			}
		}
	}
}

/* CMS Menu Sidebar
 * -------------------------------------------------
 */

.cms-menu-nav {
	margin: 0;

	&.cms-menu-mobile {
		margin: 0;
		padding: 0;
		border: 0;

		.title {
			border-bottom: 1px solid $mercury;
			border-left: 4px solid $keppel;
			display: flex;
			align-items: center;
			min-height: rem(60);
			padding: $ten-gutter rem(22) $ten-gutter rem(12);
			cursor: pointer;

			strong {
				@include type__18(
					$font-weight: $font-weight-semibold
				);
				text-transform: none;
			}

			&:after {
				@include svg-background(
					$name: arrow-down,
					$size: contain
				);
			}
		}

		.active {
			.title:after {
				@include svg-background(
					$name: arrow-down,
					$size: contain
				);
				transform: rotate(180deg);
			}
		}
	}

	[data-content-type='heading'] {
		@extend %visuallyhidden; /* Not required on mobile */
		font-weight: $font-weight-semibold;
		color: $ebony-clay;

		@include min-screen($screen__m) {
			position: static;
			width: auto;
			height: auto;
			margin: 0;
			padding: 0 0 rem(13) 0;
			clip: initial;
			border-bottom: 1px solid $mercury !important; /* Override inline styles */
		}
	}

	.pagebuilder-column {
		@include min-screen($screen__m) {
			background-color: $sidebar-background-color;
			margin-bottom: $medium-gutter;
			padding: $gutter $medium-gutter $medium-gutter $medium-gutter;
		}
	}

	.pagebuilder-buttons {
		li {
			margin: 0;

			a:not(:empty) {
				@include type__16;
				text-decoration: none;
				min-height: rem(60);
				display: flex;
				align-items: center;
				border-bottom: 1px solid $mercury;
				padding: $ten-gutter rem(22) $ten-gutter;

				@include min-screen($screen__m) {
					@include type__14;
					border: 0;
					min-height: rem(38);
					padding: $ten-gutter 0;
				}
			}
		}
	}
}


/* Main Page Content
 * -------------------------------------------------
 */

.cms-page-view,
.cms-no-route {
	.column {
		[data-content-type='row'] {
			padding-bottom: rem(41);
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			text-transform: initial;
		}

		p,
		li,
		dt,
		dd {
			@include type__16(
				$line-height: rem(28)
			);
		}

		h2 {
			@include type__36(
				$font-weight: $font-weight-semibold
			);
		}

		li {
			font-weight: $font-weight-normal;
			margin-bottom: $ten-gutter;
		}

		a {
			// cancel original underline as duplicate styling on the actual link
			border-bottom: none;

			&:hover,
			&:focus,
			&:active {
				border-bottom: none;
			}
		}

		[data-content-type='image'] {
			text-align: center;
			padding-top: rem(3);

			@include min-screen($screen__m) {
				text-align: right;
			}
		}

		img {
			margin-bottom: 0;
		}

		[data-element='caption'] {
			@include type__12(
				$color: $edward,
				$line-height: rem(18)
			);
			padding-top: rem(3);
		}
	}
}
