/* -------------------------------------------------
 * -------------------------------------------------
 * CMS Accordion
 * --------------------------------------------------
 * @author Fisheye
 */

[data-content-type='accordion'] {
	border-radius: $border-radius-m;
	border-top: 1px solid $mercury;
	border-right: 1px solid $mercury;
	border-left: 1px solid $mercury;
	overflow: hidden;

	[data-content-type='accordion-item'] {
		background-color: $white;
		border-bottom: 1px solid $mercury;
		margin: 0;

		&:nth-child(odd) {
			background-color: $silver;
		}

		&.active {
			.icon-wrapper {
				transform: rotate(180deg);
			}
		}
	}

	.accordion-item-title {
		padding: $small-gutter;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $five-gutter;

		h3 {
			@include type__16(
				$font-weight: $font-weight-semibold
			);
			order: 1;
		}

		.icon-wrapper {
			@include svg-background(
				$name: arrow-down,
				$size: contain
			);
			width: rem(12);
			height: rem(7);
			order: 2;
		}
	}

	.accordion-item-content {
		padding: $small-gutter;

		p {
			@include type__16;
		}
	}
}

.cms-page-view {
	[data-content-type='accordion'] {
		.accordion-item-title {
			h3 {
				@include type__16(
					$font-weight: $font-weight-semibold
				);
				margin: 0;
			}
		}

		[data-content-type="accordion-item"] {
			margin: 0;
		}
	}
}

