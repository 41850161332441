/* -------------------------------------------------
 * -------------------------------------------------
 * Account Login Area
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.customer-account-login,
.customer-account-create {
	.columns {
		display: block;
		padding: $account-create-account-form-spacing-mobile;

		@include min-screen($screen__m) {
			display: block;
			padding: $account-create-account-form-spacing;
		}

		.main {
			width: 100%;
			max-width: $cms-container-width;
			margin: 0 auto;
		}
	}

	.login-container {
		display: grid;
		grid-template-columns: repeat(1, minmax(0,  1fr));
		gap: rem(64);

		@include min-screen($screen__ml) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: $gutter;
		}

		@include min-screen($screen__ll) {
			gap: rem(80);
		}

		.block-title {
			margin-bottom: rem(17);

			@include min-screen($screen__ml) {
				margin-bottom: rem(29);
			}

			strong {
				@include type__24(
					$font-weight: $font-weight-semibold,
					$line-height: rem(36)
				);
				text-transform: none;

				@include min-screen($screen__ml) {
					@include type__36(
						$line-height: rem(52),
						$font-weight: $font-weight-semibold
					);
				}
			}
		}

		.block {
			margin: 0;
			background-color: transparent;

			@include min-screen($screen__ml) {
				display: flex;
				flex-direction: column;
			}

			.block-content {
				@include min-screen($screen__ml) {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex: 1 1 auto;
				}
			}
		}

		.block-customer-login {
			.form-additional {
				display: flex;

				@include min-screen($screen__ml) {
					padding-top: rem(7);
					padding-bottom: $ten-gutter;
				}
			}

			.secondary {
				text-align: right;
				white-space: nowrap;
			}

			.field.persistent {
				flex-direction: row;
				margin: 0;

				label.remember-label {
					align-items: center;
					margin: 0;
					cursor: auto; //unset pointer that span empty space

					span.title {
						@include type__16(
							$font-weight: $font-weight-normal,
							$line-height: rem(28)
						);
						width: auto;
					}

					.field-tooltip,
					.field-tooltip-action {
						position: relative;
						right: 0;
						top: 0;
					}

					.field-tooltip-action {
						margin-left: rem(4);

						&::before {
							display: none; //hide default tootip i icon
						}
					}

					.field-tooltip-content {
						background-color: $ebony-clay;
						right: auto;
						left: 0;
						white-space: normal;

						&::before {
							border-bottom-color: $ebony-clay;
							right: auto;
							left: em(5);
						}

						@include min-screen($screen__l) {
							white-space: nowrap;
						} // carets
					}
				}
			}

			.action.remind  {
				@include type__16(
					$font-weight: $font-weight-semibold,
					$color: $keppel,
					$line-height: rem(28)
				);
				padding-top: rem(3);
			}

			.actions-toolbar {
				.login {
					@include button__journey;
					width: 100%;
				}
			}
		}

		.block-new-customer {
			p,
			li {
				line-height: rem(28);
			}

			p {
				padding-bottom: rem(3);
			}

			li {
				display: flex;
				align-items: center;
				gap: rem(4);
				padding-bottom: $five-gutter;

				@include min-screen($screen__ml) {
					padding-bottom: rem(2);
				}

				&:before {
					@include svg-background(
						$name: green-tick,
					);
					content: '';
					display: block;
					height: rem(14);
					width: rem(17);
				}

				&:last-of-type {
					padding: 0;
				}
			}

			.button {
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: rem(49);
			}
		}

		.block-our-services {
			[data-element="inner"] > [data-content-type="text"] > p {
				display: flex;
				padding-left: 0;

				strong {
					padding-left: rem(12);
				}
			}

			@include min-screen($screen__ml) {
				grid-column: span 2;
			}

			[id="category-slider"] {
				padding: 0;
			}

			.pagebuilder-column-group {
				.slick-list {
					margin-left: - rem(8); //compensating the padding on first and last slide
					margin-right: - rem(8); //compensating the padding on first and last slide
					padding-left: 0;

					@include min-screen($screen__ml) {
						margin-left: - rem(32); //compensating the padding on first and last slide
						margin-right: - rem(32); //compensating the padding on first and last slide
					}

					.slick-track {
						.slick-slide {
							.pagebuilder-column {
								padding: 0 rem(8);

								@include min-screen($screen__ml) {
									padding: 0 rem(32);
								}

								.pagebuilder-image-desktop {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}
