/* -------------------------------------------------
 * -------------------------------------------------
 * General Styles specific to account area
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.proofs-customer-view,
.proofs-guest-view {
	.order-proofs-container {
		margin: auto;
		border: 1px solid $gallery;
		padding: $twenty-gutter;

		@include min-screen($screen__m) {
			max-width: em(550);
			padding: $gutter;
		}

		.proof-image {
			max-width: em(350);
			margin: auto;

			li {
				margin-bottom: $gutter;

				&:not(:first-child) {
					padding-top: $gutter;
					border-top: 1px solid $gallery;
				}
			}

			a {
				display: block;

				img {
					display: block;
					margin: 0 auto;
				}
			}
		}

		p {
			@include type__13;
			margin-bottom: $ten-gutter;
		}

		.required-entry {
			margin-bottom: $ten-gutter;
		}

		.proof-buttons {
			display: flex;

			.accept,
			.reject {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1 1 50%;
			}

			.accept {
				display: flex;
				flex-wrap: wrap;
				background-color: $gallery;
				padding: $twenty-gutter;

				&:before {
					content: '';
					margin: auto;
					height: rem(55);
					width: rem(55);
					margin-bottom: $fifteen-gutter;
					@include svg-background(
						$name: 'tick-grey',
						$size: contain
					);
				}
			}

			.reject {
				border-left: 1px solid $gallery;
				margin-left: $twenty-gutter;
				padding-left: $twenty-gutter;

				@include min-screen($screen__m) {
					margin-left: $gutter;
					padding-left: $gutter;
				}

				.input-text {
					min-height: 90px;
				}
			}

			.accept-button,
			.reject-button {
				@include button__journey;
				@include type__14($color: $white);
				text-transform: uppercase;
				width: 100%;
			}
		}
	}
}

.engraving-options {
	// Start a counter for engraving texts
	counter-reset: engraving;

	li {
		@include type__13;	
		margin-bottom: $ten-gutter;

		&:before {
			// Displays the count for engraving texts 
			counter-increment: engraving;
			content: "Engraving " counter(engraving) ": ";
			display: block;
			font-weight: $font-weight-bold;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
