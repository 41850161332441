/* -------------------------------------------------
 * -------------------------------------------------
 * Customer company profile
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.company-profile-index {
	.page-main {
		.columns {
			.column.main {
				.block {
					.block-content {
						.company-profile-shipping-methods {
							display: flex;
							flex-wrap: wrap;

							@include min-screen($screen__xs) {
								flex-wrap: nowrap;
							}

							.shipping-methods-label {
								@include type__14();
								flex: 0 1 100%;
								white-space: nowrap; /* don't break on to a new line */
								padding-bottom: $twenty-gutter;

								@include min-screen($screen__xs) {
									flex: 0 1 auto;
									padding-bottom: 0;
								}
							}

							.shipping-methods-list {
								flex: 0 1 100%;

								@include min-screen($screen__xs) {
									padding-left: $row-gutter;
								}

								li {
									@include type__13(
										$color: $body-font-color
									)
								}
							}
						}
					}
				}
			}
		}
	}
}
