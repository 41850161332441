/* -------------------------------------------------
 * -------------------------------------------------
 * Creating customer account page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.form-create-account,
.sales-guest-form { // to not have to duplicate code as sales guest form shares the same styling as create an account
	//  Uso that two blocks can site next to each other
	width: $container-width-mobile;
	max-width: $container-width;
	background-color: $account-create-account-form-background-color;

	@include min-screen($screen__m) {
		width: $container-width;
		margin-top: rem(2);
	}

	.block-title {
		strong {
			@include type__29;
			text-transform: uppercase;
		}
	}

	.fieldset { // form field containers
		max-width: $account-form-max-width;
		margin: 0 auto;

		&.info {
			br {
				display: none;
			}
		}

		&.create.info {
			.block-title {
				@include min-screen($screen__s) {
					margin-bottom: rem(17);
				}
			}
		}
	}

	.fieldset {
		.newsletter {
			.label {
				min-width: 100%;
			}
		}
	}

	.info {
		.block-title {
			@include type__29;
		}
	}

	.account {
		.block-title {
			@extend %visuallyhidden;
		}

		br {
			display: none;
		}
	}

	.legend {
		@extend %visuallyhidden;
	}

	.actions-toolbar {
		max-width: $account-form-max-width;
		margin: auto;

		.primary {
			display: block;

			.action {
				width: 100%;
				display: block;
			}
		}

		.secondary { // back button usually hidden in designs
			@extend %visuallyhidden;
		}
	}
}

.customer-account-create {
	.form-create-account {
		.block-title {
			strong {
				@include type__29;
			}
		}

		.fieldset {
			.control {
				flex: $form-input-flex;

				@include min-screen($screen__ll) {
					flex: $form-input-flex-desktop;
				}
			}
		}
	}
}

.sales-guest-form {
	.fieldset {
		.legend {
			@extend %cancel-visuallyhidden;
			margin-left: 0;
			width: 100%;
			overflow: hidden;

			> span {
				@include type__29;
				text-transform: uppercase;
			}
		}
	}
}
