/* -------------------------------------------------
 * -------------------------------------------------
 * Header styles for checkout
 *
 * TODO: Check this against Rokit code
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-checkout {
	.header.content	{
		.logo {
			justify-content: center;

			a {
				width: auto;
			}
		}
	}
	.page-header {
		background-color: $keppel;

		.header.content {
			background-color: $keppel;
			display: flex;
		}
	}
}
