/* -------------------------------------------------
 * -------------------------------------------------
 * Product Gallery
 * -------------------------------------------------
 * @author Fisheye
 */

.product.media {
	@include min-screen($screen__cms-width) {
		margin-left: -$medium-gutter; /* Allows arrow buttons to break out of container */
	}

	.action-skip-wrapper {
		@extend %visuallyhidden; /* Hide accessibility links */
	}

	.gallery-placeholder {
		@include min-screen($screen__cms-width) {
			max-width: rem(700);
		}
	}

	/* Main slider
	 * -------------------------------------------------
	 */
	.fotorama-item {
		.fotorama__stage {
			border: 1px solid $mercury;
			border-radius: $border-radius-m;
			overflow: visible;
			padding: 0 rem(8);

			@include min-screen($screen__m) {
				border: 0;
				border-radius: 0;
				padding: 0 $medium-gutter;

				&:before { /* Creates frame but allows arrows to break out */
					border: 1px solid $mercury;
					border-radius: $border-radius-m;
					content: '';
					display: block;
					position: absolute;
					inset: 0 $medium-gutter;
				}
			}

			/* When there is only one image */
			.fotorama__arr--disabled ~ .fotorama__stage__shaft {
				margin: 0 auto !important; /* Override inline styles */
			}

			.fotorama__stage__shaft {
				border-radius: $border-radius-m;
				width: 100% !important; /* Override inline styles */
			}

			/* Zoom icon
			 * -------------------------------------------------
			 */
			.fotorama__fullscreen-icon {
				@include svg-background(
					$name: zoom-in,
					$size: rem(20)
				);
				@extend %icon-button;
				bottom: $small-gutter;
				top: auto;
				right: $forty-gutter !important; /* Overwrite inline styles */

				@include min-screen($screen__m) {
					display: block !important; /* Overwrite inline styles */
				}
			}
		}
	}

	/* Nav Arrows
	 * -------------------------------------------------
	 */
	.fotorama__arr:not(.fotorama__arr--disabled) {
		background-color: transparent;
		opacity: 1;
		padding: rem(8);
		width: rem(64);
		display: block !important; /* Override inline styles */

		@include min-screen($screen__cms-width) {
			padding: 0;
			width: rem(48);

			background: linear-gradient(
					to left,
					$white 0%,
					$white 50%,
					transparent 50%,
					transparent 100%
			);
		}

		.fotorama__arr__arr {
			@include svg-background(
				$name: arrow-right,
				$size: rem(8) rem(14)
			);
			@extend %icon-button;
		}

		&.fotorama__arr--prev {
			.fotorama__arr__arr {
				@include svg-background('arrow-left');
			}

			@include min-screen($screen__cms-width) {
				padding: 0;
				width: rem(48);

				background: linear-gradient(
						to right,
						$white 0%,
						$white 50%,
						transparent 50%,
						transparent 100%
				);
			}
		}
	}

	/* Nav Dots
	 * -------------------------------------------------
	 */
	.fotorama__nav--dots {
		.fotorama__nav__shaft {
			display: flex;
			gap: rem(8);
			justify-content: center;
			padding: $small-gutter;
		}

		.fotorama__nav__frame {
			height: rem(8);
			margin: 0;
			width: auto;
		}

		.fotorama__dot {
			background-color: $slider-dots;
			border: 0;
			cursor: pointer;
			height: rem(8);
			position: static;
			width: rem(8);
		}

		.fotorama__active {
			.fotorama__dot {
				background-color: $slider-dots-active;
			}
		}
	}

	/* Nav Thumbs
	 * -------------------------------------------------
	 */
	.fotorama__nav-wrap {
		padding-left: $medium-gutter;
	}

	.fotorama__nav--thumbs {
		.fotorama__thumb-border {
			background-image: none;
			border: $thumbnail-active;
			border-radius: $border-radius-s;
		}

		.fotorama__thumb__arr {
			opacity: 1;
			display: block !important; /* Overwite inline styles */

			&.fotorama__thumb__arr--left {
				.fotorama__thumb--icon {
					@include svg-background('arrow-left');
				}
			}
		}

		.fotorama__thumb--icon {
			@include svg-background(
				$name: arrow-right,
				$size: rem(8)
			);
			background-color: $white;
			border: 1px solid $mercury;
			border-radius: $border-radius-s;
		}

		.fotorama__nav__frame {
			border-radius: $border-radius-s;

			.fotorama__thumb {
				background-color: $white;
				border: 1px solid $mercury;
				border-radius: $border-radius-s;
				overflow: hidden;

				img {
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
			}
		}
	}
}

/* Fullscreen
 * -------------------------------------------------
 */
.fotorama--fullscreen {
	padding: $gutter;

	.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
		.fotorama__arr,
		.fotorama__fullscreen-icon {
			opacity: 1;
		}
	}

	.fotorama__stage {
		.fotorama__arr {
			width: rem(48);
		}

		.fotorama__zoom-in,
		.fotorama__zoom-out,
		.fotorama__fullscreen-icon,
		.fotorama__arr__arr {
			@extend %icon-button;
			background-position: center;
			background-size: rem(16);
			opacity: 1;
		}

		.fotorama__zoom-in {
			@include svg-background('zoom-in');
			background-position: center !important; /* Override important in vendor */
		}

		.fotorama__zoom-out {
			@include svg-background('zoom-out');
			background-position: center !important; /* Override important in vendor */
		}

		.fotorama__fullscreen-icon {
			@include svg-background('close-icon');
		}

		.fotorama__arr--prev {
			.fotorama__arr__arr {
				@include svg-background(
						$name: arrow-left,
						$size: rem(8) rem(14)
				);
			}
		}

		.fotorama__arr--next {
			.fotorama__arr__arr {
				@include svg-background(
					$name: arrow-right,
					$size: rem(8) rem(14)
				);
			}
		}
	}
}
