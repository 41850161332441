/* -------------------------------------------------
 * -------------------------------------------------
 * Search results auto complete
 *
 * --------------------------------------------------
 * @author Fisheye
 */

.search-autocomplete {
	max-height: none;

	.smile-elasticsuite-autocomplete-result {
		cursor: default; // override pointer cursor on entire suggest box
		position: relative;
		padding: $fifteen-gutter;
		display: flex;
		flex-direction: column-reverse; // reversed to show category first

		&:empty {
			display: none; // Hide the results element when there are no results
		}

		.autocomplete-list {
			.autocomplete-list-title {
				@extend %trailing-full-stop;
				@include type__18(
					$font-weight: $font-weight-semibold
				);
				display: inline-block;
				border: 0;
			}

			dd {
				a {
					flex-wrap: wrap;
				}
			}
		}

		.price {
			@include type__14(
				$font-weight: $font-weight-semibold,
				$color: $keppel
			)
		}

		.price-excluding-tax-label {
			@include type__14(
				$color: $edward,
			);
		}
	}
}
