/* -------------------------------------------------
 * -------------------------------------------------
 * Product info tabs
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.product.info.detailed {
	.attribute.overview {
		margin: 0;
	}

	.product.data.items {
		.tab-container {
			border-top: 1px solid $mercury;
			padding: $product-tabs-padding-mobile;

			@include min-screen($screen__ml) {
				display: grid;
				gap: $gutter;
				grid-template-columns: 1fr 2fr;
				padding: $product-tabs-padding-desktop;
			}

			&:first-child {
				@include min-screen($screen__m) {
					border: 0;
				}
			}
		}

		.title {
			h4 {
				@include type__20(
					$line-height: rem(34)
				);
				@extend %trailing-full-stop;
				padding-bottom: $medium-gutter;

				@include min-screen($screen__m) {
					@include type__24;
				}
			}
		}

		.table-wrapper {
			border: 1px solid $mercury;
			border-radius: $border-radius-m;
			overflow: hidden;
		}

		table {
			text-align: left;

			tr {
				border-bottom: 0;

				&:nth-child(odd) {
					background-color: $silver;
				}

				&:last-of-type {
					th,
					td {
						border-bottom: 0;
					}
				}
			}

			th,
			td {
				@include type__16(
					$line-height: rem(28)
				);
				border-bottom: 1px solid $mercury;
				padding: rem(8) $small-gutter;
			}
		}

		[data-element='inner'] {
			gap: $medium-gutter;
		}

		.shipping-table {
			text-align: left !important; /* Override inline styles */
			overflow: visible;

			p {
				@include type__16(
					$line-height: rem(28)
				);
				padding-bottom: $small-gutter;

				&:last-child {
					align-items: center;
					background-color: $warning-color;
					border-radius: $border-radius-m;
					display: flex;
					gap: $small-gutter;
					justify-content: space-between;
					padding: $small-gutter;

					&:after {
						@include svg-background(
							$name: warning-icon,
							$size: contain
						);
						content: '';
						display: block;
						min-width: rem(16);
						height: rem(16);
					}
				}
			}

			strong {
				font-weight: $font-weight-semibold;
			}

			a {
				color: $keppel;
				text-decoration: underline;
			}
		}
	}
}
