/* -------------------------------------------------
 * Components Variables
 * -------------------------------------------------
 * Table of contents
 * 1. - Form Variables
 * 2. - Promo messages Variables
 * 3. - Toolbar Variables
 * 	3a - Toolbar flex orders
 *  3b. Toolbar Content Display Settings
 * 4. - Product Upsells
 * 5. - Cookie Consent
 * -------------------------------------------------
 * @author Fisheye
 */

// -------------------------------------------------
// 1. Form Variables
// -------------------------------------------------
$form-label-spacing: rem(6);

// -------------------------------------------------
// 4. Breadcrumb Variables
// -------------------------------------------------

$breadcrumbs-background-color: $white;
$breadcrumbs-padding: $ten-gutter $container-gutter;
$breadcrumbs-padding-tablet: $ten-gutter $container-gutter * 2 0 $container-gutter * 2;
$breadcrumbs-padding-desktop: $ten-gutter 0;

// -------------------------------------------------
// 3. Toolbar Variables
// -------------------------------------------------

$toolbar-container-spacing-mobile: $forty-gutter 0 $gutter 0;
$toolbar-container-spacing: $forty-gutter 0 $gutter 0;
$toolbar-container-border: 1px solid $black;
$toolbar-sorter-label-position-top: rem(12); // this is equal to field-padding as the label is set to position absolute to appear as if it is inside a select box
$toolbar-sorter-label-position-left: rem(14); // in some cases there may be a slight difference in size between field-padding and these vars as labels render differently to selects
$toolbar-sorter-option-left-spacing: em(65); // this makes enough space for the label to fit in the select
$toolbar-select-width: 222px; // this defines the flex basis of the selects
$toolbar-item-spacing: 0 $twenty-gutter; // applied to items in the middle of the tool bar as items on either side need to be flush with margins
$toolbar-sorter-spacing: $twenty-gutter;// applied to the sort by select when on mobile to provide spacing
$toolbar-pagination-spacing: $twenty-gutter;
$toolbar-sorter-width: em(255);
$toolbar-sorter-action-width: em(30);
$toolbar-sorter-action-height: em(30);

// -------------------------------------------------
// 4. Product Upsells
// -------------------------------------------------

$upsells-container-padding: $forty-gutter 0; // Padding of upsells container
$upsells-container-padding-desktop: rem(68) 0; // Padding of upsells container
$upsells-title-alignment: left; // Text alignment of product upsells title
