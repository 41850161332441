/* -------------------------------------------------
 * -------------------------------------------------
 * Bundles Product Styles
 *
 * Includes the customise options styles
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.page-layout-bundle-hidden-children {
	.product-options-wrapper {
		h4:after {
			@include svg-background(
				$name: 'arrow-down-brand-accent',
				$size: contain
			);
		}

		[data-role="content"] {
			@apply hidden; /* reduce CLS by hiding block before js loads to set it to display none */
		}
	}

	.box-tocart {
		.actions {
			flex-direction: row;
		}
	}
}
