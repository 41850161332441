/* -------------------------------------------------
 * Privacy layout
 *
 * Changes made in cms-general seems to have overwritten styling for this specific page
 * -------------------------------------------------
 * @author Fisheye
 */

.cms-privacy-policy-cookie-restriction-mode.cms-page-view  {
	.page-main {
		.columns {
			padding: 0 $container-gutter $gutter $container-gutter;
		}
	}
}
