/* ------------------------------------------------
 * ------------------------------------------------
 * Checkout Payment Methods
 * ------------------------------------------------
 */

.checkout-container .opc-wrapper {
	hr {
		border: 0;
		margin: 0;
	}

	.checkout-payment-method {
		.form.payments {
			> .fieldset {
				> br {
					display: none;
				}
			}
		}

		legend {
			@extend %visuallyhidden;
		}

		.step-title {
			&:after {
				content: '*';
				display: inline-block;
				vertical-align: top;
				padding: em(3) 0;
				@include type__18(
					$color: $radical-red,
					$font-weight: $font-weight-semibold
				)
			}

			@include min-screen($screen__m) {
				padding-top: 0;
			}
		}

		.payment-methods {
			// less specific than actual payment methods below as we want this on all payment methods
			&.items {
				.payment-group {
					position: relative;

					.required {
						&:after {
							content: "*";
							color: $field-required-input-color;
						}
					}

					.payment-method {
						.payment-method-content {
							display: none;
							margin-bottom: $twenty-gutter;

							@include min-screen($screen__m) {
								margin-bottom: $checkout-methods-outer-spacing;
							}

							.zoid-outlet {
								z-index: 1 !important;
							}

							.paypal-button {
								width: 270px !important;
								margin: auto;
								height: auto !important;
							}

							.billing-address-same-as-shipping-block {
								padding: 0;

								label {
									margin: 0;
									padding: $twenty-gutter 0 rem(8);
									width: 100%;

									@include min-screen($screen__m) {
										padding: $gutter 0 rem(8);
									}

									span {
										text-transform: capitalize;
									}
								}

								label[for='billing-address-same-as-shipping-companycredit'] { // no identifying class
									@include min-screen($screen__m) {
										padding: 0;
									}
								}
							}

							.field-select-billing {
								margin: $ten-gutter 0 $twenty-gutter;

								.select {
									padding-right: $fifty-gutter;
								}
							}

							.field.type {
								display: none; // Shows card type, not usually on design but could be used in the future
							}

							.required {
								&:after {
									display: none; // Isn't usually on designs but it could be used in the future
								}
							}

							.billing-address-details {
								padding-left: $checkout-methods-address-indent;
								@include type__13;

								.action-edit-address {
									@include button__default;
									margin-top: $checkout-field-spacing;
								}
							}
						}

						.saved-card-info {
							display: flex;
							order: 2;

							.card-details {
								order: 2;
								margin-left: $twenty-gutter;

								.info-value {
									font-weight: $font-weight-normal;
								}
							}

							.payment-icon {
								order: 0;
							}
						}

						&._active {
							.zoid-outlet {
								margin-top: $twenty-gutter;

								@include min-screen($screen__m) {
									margin-top: $gutter;
								}
							}

							.saved-card-details {
								span {
									color: $white;
								}
							}

							.payment-method-content {
								display: block;

								&:last-child {
									.field.choice {
										padding: $twenty-gutter 0 0;

										@include min-screen($screen__m) {
											padding: $gutter 0 0;
										}
									}
								}
							}
						}

						.payment-icon {
							height: auto;
							width: em(49);
							min-height: em(30);
							margin: auto 0;
						}
					}

					.field.choice {
						position: relative;
						margin-bottom: 0;
						padding: rem(37) rem(22);

						@include min-screen($screen__m) {
							padding: rem(33) rem(21);
						}

						.label {
							&:before {
								margin-right: rem(21);
							}

							span {
								display: inline;
								text-transform: capitalize;
							}
						}

						.field-tooltip.toggle {
							.field-tooltip-action {
								display: none; // remove duplicated tooltip icon
							}
						}
					}
				}
			}

			.form .fieldset .field.choice .label {
				flex: 1;
			}

			.select-payment-method {
				display: none;
			}
		}

		.credit-card-types {
			width: 100%;
			margin-top: $item-gutter;

			@include min-screen($screen__s) {
				display: inline;
				margin-top: 0;
			}

			.item {
				width: em(49);
				height: auto;
				display: inline-block;

				&:first-child {
					order: 2;
				}

				&:last-child {
					order: 3;
				}
			}
		}

		.payment-group {
			// contains all of the payment options
			.payment-method {
				// class applied to each payment method
				border-bottom: 1px solid $white;

				&:last-of-type {
					margin-top: 0;
					margin-bottom: $twenty-gutter;
					border-bottom: 0;

					@include min-screen($screen__m) {
						margin-bottom: $checkout-methods-outer-spacing;
					}
				}

				.payment-method-title {
					margin: 0;
					padding-bottom: $checkout-methods-outer-spacing;
					background-color: $mercury;
				}

				.available-credit {
					order: 1;
					align-self: center;
					margin-left: rem(53);

					@include min-screen($screen__ml) {
						margin-left: 0;
					}

					strong,
					span {
						@include type__13;
					}
				}

				&._active {
					.payment-method-title {
						background-color: $charcoal-grey;
						margin-bottom: $twenty-gutter;

						@include min-screen($screen__m) {
							margin-bottom: $checkout-methods-outer-spacing;
						}

						.label {
							span {
								color: $white;
							}
						}

						.available-credit {
							strong,
							span {
								color: $white;
							}
						}
					}
				}

				.payment-method-title,
				.payment-method-content {
					.cvv {
						._with-tooltip {
							@include min-screen($screen__m) {
								min-width: 60%;
							}
						}
					}
				}

				// card verification field - has tooltip
				.ccard>.field.cvv>.control {
					padding-right: 0;

					.field-tooltip {
						top: em(7);
						left: auto;
						right: em(5);
					}
				}
			}

			.payment-method-braintree {
				// braintree specific styles (in case you couldn't tell), this class is applied to the same div as .payment-method
				.payment-method-content {
					.fieldset {
						display: block;
						width: 100%;

						.field-select-billing {
							.control {
								margin: 0;
							}
						}

						.choice.field {
							padding: 0;
						}
					}

					form[id="co-transparent-form-braintree"] { // needs to be specific to braintree form
						.field {
							&.number:nth-of-type(2),
							&.cvv {
								@include min-screen($screen__ml) {
									float: left;
									width: calc(50% - 10px);
								}

								.control {
									@include min-screen($screen__ml) {
										margin-bottom: 0;
									}
								}
							}

							&.cvv {
								@include min-screen($screen__ml) {
									margin-left: 20px; // should not be scaled
								}

								.control {
									margin-bottom: 0;
								}
							}

							&.choice {
								display: inline-block;
								margin-top: $twenty-gutter;
								padding: 0;
								clear: both;
							}
						}
					}

					.actions-toolbar {
						margin-top: $twenty-gutter;

						@include min-screen($screen__ml) {
							margin-top: $gutter;
						}
					}
					// This are all styling form elements outputted by Braintree. They are nested inside .field>.control but the nesting would be insane!
					.hosted-control {
						@include input-style-one;
						width: 100%;
						padding: $field-padding;
						height: $checkout-methods-hosted-field-height;

						&.hosted-date {
							width: 45%;

							&:first-of-type {
								margin-right: 25px; // this is the gap between the two boxes on expiration from Braintree
								&:after {
									right: -15px;
								}
							}
						}

						&.hosted-cid {
							// needs repeating to make it mor specific
							@include input-style-one;
							width: 100%;
							padding: $field-padding;
						}

						&.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
							& ~ .hosted-error {
								height: auto;
								opacity: 1;
							}
						}
					}
				}

				.hosted-error {
					clear: both;
					height: 0;
					opacity: 0;
					margin: 0;
				}
			}
		}

		.payment-methods {
			&.items {
				.payment-group {
					position: relative;

					div[id="payment-method-braintree-paypal-vault"] { // specific to saved PayPal payment method
						.field.choice {
							.label {
								&:before {
									flex: 0 0 auto;
								}

								> span {
									word-break: break-all;
									flex: 0 1 auto;
								}
							}
						}
					}

					[id="payment-method-braintree-paypal"] { // no class
						&._active {
							.payment-method-content {
								display: flex;
								flex-direction: column;

								.messages {
									margin-top: $ten-gutter;
									order: 2;
								}
							}
						}
					}

					&.vault {
						.payment-method {
							&:last-child {
								margin-bottom: 0;

								@include min-screen($screen__m) {
									margin-bottom: rem(21);
								}
							}

							&._active {
								.payment-method-content {
									margin: $twenty-gutter 0;

									@include min-screen($screen__m) {
										margin: $gutter 0;
									}
								}
							}
						}
					}

					.required {
						position: relative;
					}
				}
			}
		}

		.form {
			.fieldset {
				.subscribe-wrapper {
					display: none !important; // remove 'Subscribe to Newsletter' - inline style of dislay: block;
				}
			}
		}

		.payment-option-content {
			.form-discount {
				display: flex;

				.payment-option-inner {
					flex: 1;

					.control {
						margin-bottom: 0;
					}
				}

				.primary {
					width: rem(20);
				}
			}
		}
	}

	.checkout-payment-method {
		// needs to be specific in order to overwrite snow dog
		.payment-option {
			.payment-option-title {
				.action-toggle {
					&:after {
						content: " ";
						@include svg-background("arrow-down");
						width: em(20);
						height: em(20);
						display: inline-block;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}

			&._active {
				.payment-option-title {
					.action-toggle {
						&:after {
							content: " ";
							@include svg-background("arrow-up");
							width: em(20);
							height: em(20);
							display: inline-block;
						}
					}
				}
			}
		}

		.payment-method {
			.payment-method-title.field.choice {
				padding: $twenty-gutter;
				display: flex;
				flex-direction: row;

				.saved-card-label {
					flex: 0 1 em(150);

					@include min-screen($screen__s) {
						flex: 0 1 em(172);
					}

					@include min-screen($screen__m) {
						flex: 0 1 em(120);
					}

					@include min-screen($screen__l) {
						flex: 0 1 em(160);
					}
				}

				.saved-card-details {
					display: flex;
					flex-direction: column;

					&.label {
						margin-left: $twenty-gutter;
					}

					span {
						@include type__13($font-weight: $font-weight-semibold);
						padding: 0;
						flex: 0 auto;
						margin-right: $five-gutter;

						&:nth-child(odd) {
							font-weight: $font-weight-normal;
						}
					}
				}

				label {
					display: flex;
					align-items: center;
					margin-bottom: 0;

					img {
						order: 3;
					}

					> span {
						@include type__14;
						order: 1;
						padding-right: $ten-gutter;
						flex: 0 1 em(110);

						@include min-screen($screen__s) {
							flex: 0 1 em(135);
						}

						@include min-screen($screen__m) {
							flex: 0 1 em(80);
						}

						@include min-screen($screen__l) {
							flex: 0 1 em(123);
						}
					}

					ul {
						order: 2;
						width: auto;
					}

					.credit-card-types {
						display: flex;
						margin-top: 0;

						.item {
							margin-right: $ten-gutter;

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

				label[for='companycredit'] { // no class available to target pay with credit option
					flex: 0 1 auto;

					@include min-screen($screen__ml) {
						flex: 0 1 rem(250);
						max-width: rem(250);
					}

					> span {
						flex: 0 1 auto;

						@include min-screen($screen__s) {
							flex: 0 1 auto;
						}

						@include min-screen($screen__m) {
							flex: 0 1 auto;
						}

						@include min-screen($screen__l) {
							flex: 0 1 auto;
						}
					}
				}
			}
		}

		.billing-address-form {
			._required {
				._with-tooltip {
					input {
						width: 100%;
					}
				}
			}
		}
	}

	// Checkout success
	// -------------------------------------------------
	.checkout-success-title-wrapper {
		text-align: center;
		background-color: $checkout-success-banner-background;
		margin-top: $twenty-gutter;

		.checkout-success-summary {
		}
	}

	.checkout-success-order-account-info-wrapper {
		div[id="registration"] {
			// "make ordering even faster in future" 3rd col
			border: solid 1px $border-color;
		}

		.block-order-totals {
			background-color: $body-background-color;
			padding: $twenty-gutter;

			.grand_total {
				background-color: $body-background-color;
				color: $grand-total-success-text-color;
			}
		}

		.block-order-addresses {
			padding: $twenty-gutter;
			display: flex;
			border: solid 1px $border-color;

			@include min-screen($screen__m) {
				.box-order-billing-address {
					border-left: solid 1px $border-color;
					padding-left: $twenty-gutter;
				}

				.box-order-shipping-address {
					padding-right: $twenty-gutter;
				}
			}
		}

		.block-order-totals {
			padding: $twenty-gutter;
		}

		div[id="registration"] {
			padding: $twenty-gutter; // input[type="submit"]
			input[type="submit"] {
				@extends %input-style-four;
			}
		}
	}

	.giftcardaccount {
		display: none; // remove 'Apply Gift Card' block
	}
}

[id="braintree_paypal_placeholder"] {
	text-align: center;

	.paypal-buttons {
		margin: $twenty-gutter auto 0;
	}
}
