/* -------------------------------------------------
 * -------------------------------------------------
 * JS Tree Styles
 *
 * JS Tree is found in the B2B extension for account
 * area pages (Such as compant structure page)
 *
 * Styles here are ported from the Luma theme with
 * changes made on top
 * -------------------------------------------------
 * @author Fisheye
 */

.block-dashboard-company {
	.jstree {
		.jstree-clicked {
			background-color: $keppel;
			border-radius: $border-radius-s;
		}
	}
}
