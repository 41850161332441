/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Articles
 *
 * Most styles should be in relevant stylesheet i.e.
 * buttons.scss. This stylesheet is slightly more
 * speciic than the default theme to override CSS.
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.blog-post-view {
	.page-title-wrapper {
		padding-top: $forty-gutter;
		padding-bottom: 0;

		.base {
			@extend %trailing-full-stop;
			display: inline;
		}
	}

	.page-title {
		text-align: center;
	}

	img.pagebuilder-image-desktop {
		@include min-screen($screen__m) {
			max-height: rem(800);
			width: auto;
		}
	}
}
