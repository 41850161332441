// Payment page discount code field
//--------------------------------

.checkout-container .opc-wrapper {
	.discount-code-title {
		@include type__18(
			$color: $ebony-clay,
			$font-weight: $font-weight-semibold,
		);

		display: block;
		padding-bottom: rem(16);
	}

	.discount-code {
		text-transform: none;
	}
}
