/* -------------------------------------------------
 * -------------------------------------------------
 * Delivery address boxes when logged in
 *
 * TODO: Check this against Rokit code
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.opc-wrapper {
	.checkout-shipping-address {
		border-bottom: $checkout-methods-border;
		padding-bottom: $twenty-gutter;

		@include min-screen($screen__m) {
			padding-bottom: $checkout-methods-inner-spacing;
		}

		.shipping-address-items {
			@extend %flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}

		.shipping-address-item {
			flex: 0 1 100%;
			margin-bottom: $twenty-gutter;
			position: relative;
			border: solid 1px $checkout-delivery-address-deselected;
			@include type__13;
			padding: $twenty-gutter $twenty-gutter em(75) $twenty-gutter;

			&:last-child {
				margin-bottom: 0;
			}

			@include min-screen($screen__ll) {
				// Not using shorthand flex here because a calc is used in flex basis
				// which is not supported in IE if using shorthand
				flex-grow: 0;
				flex-shrink: 1;
				flex-basis: calc(50% - #{$small-gutter});
				margin-bottom: 0;

				&:nth-child(n+3) {
					margin-top: $gutter;
				}
			}

			.shipping-address-item-wrapper {
				padding: 0;
				@include type__13;
			}

			button {
				@include button__address;
				width: 100%;
				border: 1px solid $checkout-delivery-address-deselected;
				position: absolute;
				left: 0;
				bottom: 0;
				margin-top: $twenty-gutter;
			}

			.edit-address-link {
				display: none; // remove 'edit' button from delivery address blocks
			}

			&.selected-item {
				border-color: $checkout-delivery-address-selected;

				&:after {
					display: none;
				}

				button {
					background-color: $checkout-delivery-address-selected;
					border: 1px solid $checkout-delivery-address-selected;
					bottom: 0;

					span {
						color: $white;
						display: flex;
						align-items: center;
						justify-content: center;

						&:before {
							content: '';
							display: inline-block;
							height: em(16);
							width: em(16);
							@include svg-background('tick-white');
							background-size: contain;
							margin-right: $ten-gutter;
						}
					}
				}
			}
		}

		.action-show-popup {
			@include button__address;
			border-bottom: 0;
			margin-top: $twenty-gutter;
			border: 0;

			@include min-screen($screen__m) {
				margin-top: $gutter;
			}
		}
	}

	.checkout-shipping-method {
		display: list-item;
		position: relative;

		.no-quotes-block {
			> span {
				@include type__14;
			}
		}

		.loading-mask {
			width: 100%;
			display: flex;
			background: rgba(255, 255, 255, 0.8);

			.loader {
				position: relative;
			}
		}

		.select-method {
			@include min-screen($screen__l) {
				position: absolute;
				top: em(20);
			}
		}

		.table-checkout-shipping-method {
			min-width: auto;
			margin-bottom: 0;

			tbody {
				border-bottom: none;

				td {
					padding: 0;

					&:last-child {
						flex: 1;
					}
				}
			}

			.row {
				display: flex;
				align-items: center;
				padding: rem(37) rem(22);
				background-color: $gainsboro;
				border-bottom: 1px solid $white;
				cursor: pointer;

				&:last-child {
					border-bottom: 0;
				}

				@include min-screen($screen__m) {
					padding: rem(29) rem(21);
				}

				&.selected {
					background-color: $charcoal-grey;

					.price,
					.col-method {
						color: $white;
					}
				}
			}

			.col-price {
				order: 3;
				flex: 1;
				text-align: right;

				.price {
					@include type__14($color: $ebony-clay)
				}
			}

			.col-method {
				@include type__13(
					$color: $ebony-clay,
					$font-weight: $font-weight-bold
				);

				label { // no class to use
					&:before {
						margin-right: rem(21);
					}
				}
			}

			.col-carrier {
				// "select shipping method" is repeated here so removed
				display: none;
			}
		}

	}

	.plain-packaging-wrapper {
		padding-top: rem(24);

		label {
			align-items: center;
			display: flex;

			.text-wrapper {
				align-self: center;
				width: 100%;
			}

			.text-wrapper > span,
			> span,
			b {
				@include type__14(
					$font-weight: $font-weight-normal,
				);
				width: auto;

				b {
					font-weight: $font-weight-semibold;
					vertical-align: bottom;
				}
			}
		}
	}
}

.page-layout-checkout {
	.opc-sidebar {
		.table-totals {
			.value {
				color: $delivery-time-text-color;
			}

			.grand {
				.mark {
					color: $grand-total-text-color;
				}
			}
		}
	}

	// Delivery Address Modal Popup
	// Specific to the checkout as global modal styles need to be overidden
	.modal-popup.modal-slide {
		&._inner-scroll {
			.modal-inner-wrap {
				background-color: $white;
				min-height: auto;

				.telephone {
					border: 0;
					padding-top: 0;
					margin-top: 0;
					margin-bottom: $twenty-gutter;
				}

				div[name="shippingAddress.postcode"] {
					input {
						flex: 1 1 48%;

						@include min-screen($screen__m) {
							flex: 1 1 55%;
						}
					}

					.find-address {
						margin-left: $ten-gutter;
					}

					[id="getaddress_error_message"] { // no class
						flex: 1 1 100%;
					}
				}

				.modal-footer {
					margin-top: 0;
				}
			}
		}
	}
}
