/* -------------------------------------------------
 * -------------------------------------------------
 * Specific Styling to Blog Articles
 *
 * Most styles should be in relevant stylesheet i.e. buttons.scss
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.blog-post-view {
	.columns {
		display: flex;
	}

	.main {
		min-width: 100%;
		width: 100%;
	}

	.blog-post {
		@include type__13;
		display: flex;
		flex-direction: column;
		padding-top: rem(2);
		
		@include min-screen($screen__m) {
			max-width: em(860);
			margin: auto;
			padding: $twenty-gutter 0;
		}
	
		.post-featured-image {
			margin-bottom: em(17);
	
			@include min-screen($screen__m) {
				margin-bottom: em(45);
			}
		}

		[data-content-type="heading"] {
			margin-bottom: $fifteen-gutter;
		}

		[data-content-type="text"] {
			p,
			span {
				@include type-setting(13);
				line-height: 1.54; /* to match live site */
				margin-bottom: $fifteen-gutter;
				font-weight: $font-weight-normal;
			}

			/* The data provided here doesn't use heading content-type's,
			 * so needing to be specific in order to style this correctly.
			 */
			h2,
			h2 *,
			strong,
			strong * {
				font-weight: $font-weight-bold;
			}
		}
	}
}
