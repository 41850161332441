/* -------------------------------------------------
 * -------------------------------------------------
 * Rewards points page
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.balance-information-containers {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: $gutter;

	@include min-screen($screen__l) {
		flex-wrap: nowrap;
	}

	.current-balance,
	.reward-tier,
	.curency-value,
	.earning-points {
		flex: 0 1 100%;
		background-color: $concrete;
		border: solid 1px $border-color;
		padding: $small-gutter;
		text-align: center;
		display: flex;
		flex-direction: column;
		margin-bottom: $gutter;

		@include min-screen($screen__l) {
			flex: 0 1 calc(33% - #{$ten-gutter});
			margin-bottom: 0;
		}

		.balance-information-title {
			@include type__24;
			flex: 0 1 auto;
			padding-bottom: $small-gutter;

			p {
				@include type__24;
			}
		}

		.balance-information-value {
			@include type__24(
			$color: $brand-accent,
			);
			background-color: $white;
			padding: $gutter;
			align-self: stretch;
			flex: 1 1 auto;

			p {
				@include type__24(
				$color: $brand-accent,
				);
			}
		}

		.price {
			@include type__24(
			$color: $brand-accent,
			);
		}
	}
}

.balance-information-redemption-block {
	padding: $gutter 0;

	.price {
		@include type__16(
		$color: $brand-accent,
		)
	}
}

.magento_reward-customer-info {
	.balance-information-redemption-rates-table {
		table {
			margin-bottom: 0;
			display: table;

			thead {
				display: table-header-group;
				background-color: $concrete;

				@include min-screen($screen__ml) {
					border-bottom: 0;
				}
			}

			tbody {
				display: table-row-group;
			}

			thead,
			tbody {
				tr {
					border-bottom: 0;
					display: table-row;
				}

				th,
				td {
					display: table-cell;
					text-align: center;
					padding: $account-table-inner-spacing 0;
				}
			}

			tbody {
				border-bottom: solid 1px $border-color;
			}
		}
	}
}

.block-reward-history {
	padding: $gutter 0;

	.block-title {
		padding-bottom: $gutter;
	}

	.message {
		@include type__16;
		background-color: $concrete;
		padding: $item-gutter;
		text-align: center;
	}
}

form.reward-settings {
	display: flex;
	flex-wrap: wrap;

	.fieldset,
	.actions-toolbar {
		flex: 0 1 100%;
		display: flex;

		@include min-screen($screen__m) {
			flex: 0 1 50%;
		}

		.primary {
			width: 100%;

			@include min-screen($screen__m) {
				margin-left: auto;
				width: auto;
			}
		}
	}

	.fieldset {
		.field.choice {
			label {
				flex: 0 1 100%;
			}
		}
	}
}
