/* -------------------------------------------------
 * Next Day Delivery Label
 *
 * Found in item renderers such as the basket and checkout pages
 * -------------------------------------------------
 * @author Fisheye
 */

.next-day-delivery-label {
	border-radius: $border-radius-s;

	span {
		@include type__12(
			$color: $white,
			$font-weight: $font-weight-semibold,
			$line-height: rem(18)
		);
	}

	&.standard {
		span {
			color: $ebony-clay;
		}
	}

	.checkout-index-index & {
		&.standard {
			background-color: $ebony-clay;

			span {
				color: $white;
			}
		}
	}
}

.checkout-index-index {
	.product-item-name-block {
		display: flex;
		flex-direction: column;

		.next-day-delivery-label {
			padding: rem(5);
			width: fit-content;
		}
	}
}

.next-day-delivery-notice {
	span {
		white-space: break-spaces;
	}
}
