/* -------------------------------------------------
 * -------------------------------------------------
 * Checkout cart layout grid
 *
 * -------------------------------------------------
 * @author Fisheye
 */

.checkout-cart-index {
	.page-title-wrapper {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-bottom: $ten-gutter;

		@include min-screen($screen__m) {
			padding-bottom: $gutter;
			padding-left: $gutter;
			padding-right: $gutter;
		}

		@include min-screen($screen__xl) {
			padding-left: 0;
			padding-right: 0;
		}

		.page-title {
			padding: 0;

			span {
				@extend %trailing-full-stop;
			}
		}

		.totals {
			@include min-screen($screen__m) {
				display: none; /* Hidden on desktop. Required on mobile */
			}
		}

		.total-title {
			@extend %visuallyhidden; /* not visible in design but useful for accessibility */
		}

		.price {
			@include type__24;
		}

		a {
			@include link__underline;
			@include type__14;
		}
	}

	.columns {
		@include min-screen($screen__m) {
			padding: 0 $gutter;
		}

		.column.main {
			margin: 0 auto;
			max-width: $cms-container-width;
			padding: 0;
			width: 100%;
		}
	}

	.cart-container {
		.cart-checkout-top {
			.checkout-methods-items {
				padding: $medium-gutter $small-gutter;
				position: fixed;
				bottom: 0;
				z-index: 1;
				width: 100%;
				background-color: $white;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				box-shadow: 0 1px 16px rgba(23, 27, 63, 0.24);

				.primary {
					@include button__journey;
					margin: 0;
				}

				.braintree-apple-pay-button {
					width: 100%;
					margin-top: $ten-gutter;
				}
			}
		}

		.checkout-cart-content {
			@include min-screen($screen__m) {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				column-gap: $gutter;
			}

			@include min-screen($screen__ml) {
				grid-template-columns: auto rem(392);
			}

			.cart-column-one,
			.cart-column-two {
				@include min-screen($screen__m) {
					padding: 0;
					width: 100%;
				}
			}

			.cart-column-one {
				@include min-screen($screen__m) {
					border: 0;
				}
			}

			.cart-column-two {
				border-top: $checkout-success-table-product-border;
				border-bottom: $checkout-success-table-product-border;
				padding: $small-gutter;
				margin-bottom: $forty-gutter;

				@include min-screen($screen__m) {
					background-color: $basket-summary-background;
					border: $checkout-success-table-product-border;
					border-radius: $border-radius-m;
					padding: $medium-gutter;
					margin: 0;
				}
			}

			/* FAQs Accordion */
			> [data-content-type='row'] {
				border-top: $checkout-success-table-product-border;
				border-bottom: $checkout-success-table-product-border;
				padding: $forty-gutter $small-gutter;

				@include min-screen($screen__m) {
					border: 0;
					padding: 0;
				}

				p {
					@include type__20(
						$font-weight: $font-weight-semibold
					);
					padding-bottom: $small-gutter;

					@include min-screen($screen__m) {
						@include type__24(
							$font-weight: $font-weight-semibold
						);
						padding-bottom: rem(24);
					}
				}

				[data-content-type="accordion"] {
					p {
						@include type__16;
					}
				}
			}
		}
	}
}
